import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import ReactGiphySearchbox from 'react-giphy-searchbox'
import $ from 'jquery'
import VagaroDrive from '../VagaroDrive';
import { useChatContext } from '../Store/ChatProvider';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import SuccessAlert from '../../component/Common/SuccessAlert';
import ReactDOM, { unmountComponentAtNode } from 'react-dom';
import useGenerateToken from '../../component/Hooks/useGenerateToken';
import { AsyncAjaxGet, AsyncAjaxPost, HeaderDetail } from '../../component/Common/AsyncAjaxServiceV2';
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown';
import { compressImage, getConversationData, isAndroidIOSTabIpad, isIOSTabIpad, removeConversationItem, removeConversationItemOnSend, StoreLocalConversation, utils } from '../../utils/Utils';
import { ChatListSelection, CommonMethods, LeftControlNames, MobileCallBackAction, VagaroConnectMessageType } from '../Binding/ChatBoatEnums';
import Picker from '@emoji-mart/react'
import data from '@emoji-mart/data'
import VGPopUp from '../../component/Common/VGPopUp';
import CommonMessagepopup from '../../component/Common/CommonMessagepopup';
import { FileUpload, VideoContent } from '../CommonComponent/FileUpload';
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';
import { CheckTypingUser } from '../../component/Slice/TypingUser';
import axios from "axios";
import process from 'process';
import { useSwipeable } from 'react-swipeable';
import TakeControl from '../CommonComponent/TakeControl';


const EditoerComponent = (props) => {

    const dispatch = useDispatch();
    const {
        merchantId,
        callBackChatConversation,
        currentConversetion,
        SendMessage,
        customer,
        ReactFirstName,
        ReactLastName,
        isNewChat,
        convertionData,
        isCustomerChat,
        ModifyEmployee,
        ModifyCustomer,
        LeftOption,
        ckeditortype = 1,
        messageDetails,
        EditDeleteMessage,
        LoginUserIdHash,
        ReactUserID,
        ReactBusinessName,
        customerData,
        newChat,
        middleArea,
        isFromConnectApp,
        isAndroidIOSMobile,
        isIOSMobile,
        groupMemberArrRef,
        serviceProviderHashId,
        expandMinimizeShow,
        CheckLoginUserIsRemovedFromChat,
        isLastMessageIndex,
        isFromAppResumeCallBack,
        ManageCustomerViewRights, ManageCustomerModifyRights, initialData,
        ReactProfileImage, ManageEmployeeModifyRights, ManageEmployeeViewRights,
        messageId,
        messageConvertions
    } = props;
    const { chatSelectionID, chatSelection, AddCommonMethod, CommonMethod, smsavail, connection, UserTextMarketingData, StoreBadWordString } = useChatContext();
    const AIControl = useChatContext((context) => context?.AIControl === true && context?.textmarketing?.numberStatus === 4);
    const [editorData, setEditorData] = useState('');
    const [showGhipy, setShowGhipy] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [showFileDropdown, setFileDropdown] = useState(false);
    const [showErrorMessage, setshowErrorMessage] = useState(false);
    const [showEmojis, setShowEmojis] = useState(false);
    const [show, setShow] = useState(false);
    const [vagaroLinks, setVagaroLinks] = useState(false);
    const [maxLenghtAlert, setmaxLenghtAlert] = useState(false);
    const [isTextmarketingalerts, setTextmarketingalerts] = useState(0);
    const [mentionDropdown, setmentionDropdown] = useState(false);
    const [mentionList, setMentionList] = useState([]);
    const [mentionSearchtxt, setmentionSearchtxt] = useState("");
    const [users, setusers] = useState([]);
    const isNoteRef = useRef(false);
    const isNoteRef2 = useRef(false);
    const editoerObject = useRef("")
    const editoerObject2 = useRef("")
    const ref = useRef(null);
    const sendConvertion = useRef(null);
    sendConvertion.current = convertionData;
    const isMessageChanged = useRef(false);
    const LoginUserIdHashref = useRef(null);
    const InputCamera = useRef(null);
    const InputPhotoLibrary = useRef(null);
    LoginUserIdHashref.current = LoginUserIdHash;
    const [ImagesCount, setImagesCount] = useState(0);
    const [showFileUploadProgressMessage, setshowFileUploadProgressMessage] = useState(false);
    const [PendingFileToUpload, setPendingFileToUpload] = useState(0);
    const [badwordAlert, setbadwordAlert] = useState(false);
    const [highlightedIndex, setHighlightedIndex] = useState(0);
    const textMarketingData = useRef(null);
    const cannabisWords = useRef("");
    const cannabisContainWords = useRef("");
    const BadKeyWordCheck = useRef(false);
    const textmarketingalerts = useRef(0);
    const DropImageRistrict = useRef(0);
    const FileUploadStatus = useRef(false);
    const MentionedDropdownRef = useRef(false);
    const cancelTokenRef = useRef(null);


    const [height, setHeight] = useState('305px');
    const [swipedUp, setSwipedUp] = useState(false);


    const EditorObject = ckeditortype === 1 ? editoerObject : editoerObject2;
    const NoteObject = ckeditortype === 1 ? isNoteRef : isNoteRef2;

    const VagaroAPIEnum = {
        UploadImage: `connect/${merchantId}/sendfile`,
        UserTyping: `connect/${merchantId}/usertyping`,
        GetVagaroLinks: `${merchantId}/VagaroConnect/vagarolinks`,
        TakeControl: `connect/${merchantId}/aibroadcast`,
    }
    let isTablet = /VagaroAndroidTablet/i.test(navigator.userAgent);

    const SetLocalStorageForConversation = (editorData) => {
        let attachment = [];
        if (ckeditortype === 1 && !isCustomerChat) {
            const ActiveLeftOption = isCustomerChat ? "Customers" : $("a.leftside-menu_options.active").text().replace(/[0-9]/g, '');
            utils.EventCall(`EditorFileChagesPersist${ckeditortype}`, (Files) => {
                if (Files) {
                    attachment = Files
                }
            })
            if ((isNewChat || newChat) && !isCustomerChat) {
                if (ActiveLeftOption === "Customers") {
                    if (customerData?.userIdHash !== undefined) {
                        StoreLocalConversation(2, "", "", customerData?.userIdHash, editorData, true, LoginUserIdHash, true, customerData, attachment)
                    }
                } else {
                    if (groupMemberArrRef !== null && groupMemberArrRef.length > 0) {
                        StoreLocalConversation(3, "", groupMemberArrRef?.map(x => x.UserIdHash), "", editorData, true, LoginUserIdHash, false, undefined, attachment)
                    }
                }
            }
            else {
                if (chatSelection.ConversationId !== "") {
                    StoreLocalConversation(1, chatSelection.ConversationId, "", "", editorData, true, LoginUserIdHash, ActiveLeftOption === "Customers" ? true : false, undefined, attachment)
                }
            }
        }
        utils.updateEvent("AttachmentLocalStorageForConversation", () => SetLocalStorageForConversation(EditorObject.current.getData()))
    }


    const GetLocalStorageForConversation = () => {
        if (ckeditortype === 1) {
            setTimeout(() => {
                if ((isNewChat || newChat) && !isCustomerChat) {
                    let SetChats = null;
                    const ActiveLeftOption = isCustomerChat ? "Customers" : $("a.leftside-menu_options.active").text().replace(/[0-9]/g, '');
                    if (ActiveLeftOption === "Customers") {
                        if (customerData !== undefined && customerData !== null && customerData !== "") {
                            SetChats = getConversationData(2, customerData.userIdHash, LoginUserIdHash);
                        }
                    } else {
                        if (groupMemberArrRef !== undefined && groupMemberArrRef !== null && groupMemberArrRef !== "" && groupMemberArrRef.length > 0) {
                            SetChats = getConversationData(3, groupMemberArrRef?.map(x => x.UserIdHash), LoginUserIdHash);
                        }
                    }
                    if (SetChats !== undefined && SetChats !== null) {
                        EditorObject.current.setData(SetChats.Message);
                        EditorObject.current.editing.view.focus();
                        EditorObject.current.model.change(writer => {
                            writer.setSelection(writer.createPositionAt(EditorObject.current.model.document.getRoot(), 'end'));
                        });
                        handleKeydown("", "", EditorObject.current, returnData => { });
                        if (SetChats.attechments && SetChats.attechments?.length > 0) {
                            utils.EventCall(`UpdatePersistFile${ckeditortype}`, SetChats.attechments)
                        }
                    } else {
                        EditorObject.current.setData("");
                    }

                } else {
                    window.ResetValueCustomerAndEmployee();
                    let SetChats = getConversationData(1, chatSelection.ConversationId, LoginUserIdHash);
                    if (SetChats !== undefined && SetChats !== null) {
                        EditorObject.current.setData(SetChats.Message);
                        EditorObject.current.editing.view.focus();
                        EditorObject.current.model.change(writer => {
                            writer.setSelection(writer.createPositionAt(EditorObject.current.model.document.getRoot(), 'end'));
                        });
                        handleKeydown("", "", EditorObject.current, returnData => { });
                        if (SetChats.attechments && SetChats.attechments?.length > 0) {
                            utils.EventCall(`UpdatePersistFile${ckeditortype}`, SetChats.attechments)
                        }
                    } else {
                        EditorObject.current.setData("");
                    }
                }
            }, 300);
        }
    }
    const handleMentionSelect = (user) => {

        const model = EditorObject.current.model;
        const selection = model.document.selection;
        const mentionTrigger = '@';
        const position = selection.getFirstPosition();
        const paragraph = position.parent;

        let paragraphText = '';
        for (const node of paragraph.getChildren()) {
            if (node.is('$text')) {
                paragraphText += node.data;
            } else if (node.is('element', 'softBreak')) {
                paragraphText += '\n';
            } else if (node.is('element', 'imageInline')) {
                paragraphText += ' ';
            } else if (node.is('element', 'vg-user')) {
                paragraphText += ' '; // Adding a space 
            }
        }

        const cursorOffset = position.offset;
        const textBeforeCursor = paragraphText.slice(0, cursorOffset);
        const lastMentionIndex = textBeforeCursor.lastIndexOf(mentionTrigger);

        model.change(writer => {
            // Remove the old text including '@'
            const startPosition = writer.createPositionAt(paragraph, lastMentionIndex);
            const endPosition = writer.createPositionAt(paragraph, cursorOffset);
            const range = writer.createRange(startPosition, endPosition);
            writer.remove(range);


            // Split the user's name by spaces
            const nameParts = user.name.split(' ');

            // Insert each part of the user's name as a separate vg-user tag
            let currentPosition = startPosition;

            if (user.isAddTeammate) {
                // Move the cursor after the last mention element
                writer.setSelection(currentPosition);
            } else {
                nameParts.forEach((namePart, index) => {
                    const mentionElement = writer.createElement('vg-user', {
                        class: 'mentionNameInChat',
                        id: user.id
                    });

                    // Insert the mention element
                    writer.insert(mentionElement, currentPosition);
                    writer.insertText(namePart, mentionElement);

                    // Move the current position after the mention element
                    currentPosition = writer.createPositionAfter(mentionElement);

                    // Add a space after each part except the last one
                    if (index < nameParts.length - 1) {
                        writer.insertText(' ', currentPosition);
                        currentPosition = writer.createPositionAt(paragraph, currentPosition.offset + 1);
                    }
                });
                // Move the cursor after the last mention element
                writer.setSelection(currentPosition);
            }

        });

        setShowDropdown(false);
        setmentionDropdown(false);
        setEditorData(EditorObject.current.getData());
        setMentionList([]);
        EditorObject.current.focus();
        if (user.isAddTeammate) {
            utils.EventCall('ClickAddMemberinGroup')
        }
    };

    useEffect(() => {
        const handleKeydown = (event) => {
            if (EditorObject.current) {
                const editor = EditorObject.current.editing;
                if (!editor) return;
                const model = editor.model;
                const document = model.document;
                const selection = document.selection;
                const position = selection.getFirstPosition();
                if (!position) return;

                const isSpace = event.code === 'Space' || event.which === 229;
                const remove_key = event.code === 'Space' || event.which === 8;
                const isBackspace = event.key === 'Backspace';

                model.change(writer => {
                    const previousNode = position.nodeBefore;
                    const nextNode = position.nodeAfter;

                    const removeNode = (node) => {
                        writer.remove(node);
                    };

                    const removeAdjacentNbsp = (node) => {
                        if (node && node.is('$text') && node.data === '\u00A0') {
                            removeNode(node);
                            return true;
                        }
                        return false;
                    };

                    if (isBackspace) {
                        if (previousNode && previousNode.is('element', 'vg-user')) {
                            const beforePreviousNode = previousNode.previousSibling;
                            removeNode(previousNode);
                            removeAdjacentNbsp(beforePreviousNode);
                        } else if (position.offset === 0 && previousNode && previousNode.is('$text')) {
                            const start = position.getShiftedBy(-1);
                            const range = writer.createRange(start, position);
                            writer.remove(range);
                            event.preventDefault();
                        } else if (nextNode && nextNode.is('$text') && nextNode.data === '\u00A0') {
                            removeNode(nextNode);
                        }
                    } else if (isSpace) {
                        // if(isAndroidIOSMobile && isIOSMobile === false){
                        // Prevent default space insertion if necessary
                        const isSpaceAlreadyThere = previousNode && previousNode.is('$text') && position.offset > 0 && position.getShiftedBy(-1).text === ' ';

                        if (isSpaceAlreadyThere) {
                            event.preventDefault(); // Prevent the extra space from being added
                        } else {
                            if (previousNode && previousNode.is('element', 'vg-user')) {
                                // Insert a space after the mention
                                const newPosition = writer.createPositionAfter(previousNode);
                                writer.setSelection(newPosition);
                                writer.insertText(' ', newPosition);
                                event.preventDefault(); // Prevent default space insertion
                            } else if (nextNode && nextNode.is('element', 'vg-user')) {
                                // If next to a mention, move cursor after mention and add space
                                const newPosition = writer.createPositionAfter(nextNode);
                                writer.setSelection(newPosition);
                                writer.insertText(' ', newPosition);
                                event.preventDefault(); // Prevent default space insertion
                            }
                        }
                    }
                    // }
                });
            }
        };

        document.addEventListener('keydown', handleKeydown);

        return () => {
            document.removeEventListener('keydown', handleKeydown);
        };
    }, [EditorObject]);

    const EditorOnChange = (event, editor) => {
        const data = editor.getData();
        if (isFromConnectApp) {
            const editorElementheight = document.querySelector('.ck-editor__editable');
            if (editorElementheight) {
                const height = editorElementheight.clientHeight;
                if (height > 100) {
                    CommonMethods.AddClass(".typeChatWrap.collapsibleChat", "mobileckMaxheight");
                }
                else {
                    CommonMethods.RemoveClass(".typeChatWrap.collapsibleChat", "mobileckMaxheight");
                }
            }
        }

        // Start mention related Changes -----------------------
        if (MentionedDropdownRef.current === true || ckeditortype == 1) {


            const mentionTrigger = '@';
            const model = editor.model;
            const document = model.document;
            const selection = document.selection;
            const position = selection.getFirstPosition();
            const paragraph = position.parent;
            const editorElement = editor.ui.view.editable.element;
            let paragraphText = '';
            if (paragraph.is('element', 'paragraph')) {
                // Collect paragraph text
                for (const node of paragraph.getChildren()) {
                    if (node.is('$text')) {
                        paragraphText += node.data;
                    } else if (node.is('element', 'softBreak')) {
                        paragraphText += '\n'; // Representing soft break as newline
                    } else if (node.is('element', 'imageInline')) {
                        paragraphText += ' '; // Adding a space for the image tag
                    } else if (node.is('element', 'vg-user')) {
                        paragraphText += ' '; // Adding a space for the vg-user tag
                    }
                }

                const mentionMatch = paragraphText?.match(/@\w+/g) || paragraphText?.match(/@/g);
                if (mentionMatch && mentionMatch.length > 0 && isCustomerChat === false) {
                    mentionMatch.push('@');
                    mentionMatch.forEach(keyword => {
                        let index = paragraphText.indexOf(keyword);
                        model.change(writer => {
                            while (index !== -1) {
                                const startPosition = writer.createPositionAt(paragraph, index);
                                const endPosition = writer.createPositionAt(paragraph, index + keyword.length);
                                const range = writer.createRange(startPosition, endPosition);
                                writer.setAttribute('mentionHighlight', true, range);
                                index = paragraphText.indexOf(keyword, index + keyword.length);
                            }
                        });
                    });
                }
            }
            // End mention related Changes -----------------------

            // Handle other functionality like keydown events, message changes, etc.
            if (event !== 0 && event !== undefined && event !== null && event !== "") {
                handleKeydown("", "", EditorObject.current, returnData => { });
            }

            // Calculate attachment count
            let Attachment = 0;
            utils.EventCall(`TotalAttachments${ckeditortype}`, (Attachments) => {
                Attachment = Attachments;
            });

            if (data || Attachment > 0) {
                if (messageDetails && messageDetails.message.length !== data.length) {
                    isMessageChanged.current = true;
                }
                if ((isCustomerChat || isAndroidIOSMobile) && ckeditortype === 1) {
                    CommonMethods.AddClass(".typeChatWrap.collapsibleChat", "mobileActiveChat");
                }
                SetLocalStorageForConversation(data);
                setshowErrorMessage(false);
            } else {
                isMessageChanged.current = false;
                setbadwordAlert(false);
                BadWordsAddClass(false);
                if (isCustomerChat || isAndroidIOSMobile) {
                    CommonMethods.RemoveClass(".typeChatWrap.collapsibleChat", "mobileActiveChat");
                }
                if (!isNewChat && !isIOSMobile) {
                    SetLocalStorageForConversation("");
                }
            }
            // Start mention related Changes -----------------------
            const cursorOffset = position.offset;
            const textBeforeCursor = paragraphText.slice(0, cursorOffset);
            const lastMentionIndex = textBeforeCursor.lastIndexOf(mentionTrigger);
            const check_mention_text = paragraphText.slice(0, cursorOffset);

            if (lastMentionIndex !== -1) {
                const beforeAtChar = textBeforeCursor[lastMentionIndex - 1];
                const mentionText = textBeforeCursor.slice(lastMentionIndex + 1);
                const afterAtChar = textBeforeCursor[lastMentionIndex + 1];

                // Check if "@" is standalone
                const isAtStandalone = (!beforeAtChar || /\s|[.,!?;:\(\)\[\]\{\}]/.test(beforeAtChar)) &&
                    (!afterAtChar || afterAtChar === ' ' || /^[^\s<]/.test(afterAtChar));

                const isAtFollowedByChar = /^[^\s<]/.test(afterAtChar);

                if (isAtStandalone) {
                    if (mentionText?.length > 0) {
                        let users_member;
                        if (newChat === false && isNewChat === false && ckeditortype == 2) {
                            if (chatSelection?.AllData?.isGroupChat === false) {
                                users_member = [
                                    {
                                        id: chatSelection?.AllData.customerIdHash,
                                        name: chatSelection?.AllData?.firstName + " " + chatSelection?.AllData?.lastName,
                                        cdnUrl: chatSelection?.AllData?.cdnUrl,
                                        photo: chatSelection?.AllData?.photo,
                                        cell: chatSelection?.AllData?.cell,
                                        firstName: chatSelection?.AllData?.firstName,
                                        lastName: chatSelection?.AllData?.lastName,
                                    }
                                ];
                                setusers(users_member);
                            } else {
                                const userlist = chatSelection?.AllData?.Allmember?.filter(item => LoginUserIdHash !== item?.memberIdHash) || [];
                                users_member = userlist?.map(arr => ({
                                    id: arr.memberIdHash,
                                    name: arr.firstName + " " + arr.lastName,
                                    cdnUrl: arr.cdnUrl,
                                    photo: arr.photo,
                                    cell: arr.cell,
                                    firstName: arr.firstName,
                                    lastName: arr.lastName,
                                }));
                                setusers(users_member);
                            }
                        }
                        const check_filter = users?.length ? users : users_member
                        const filteredUsers = check_filter?.filter(user =>
                            user?.name?.toLowerCase().includes(mentionText?.toLowerCase())
                        );
                        if (chatSelection.IsFromGroup === true && CheckLoginUserIsRemovedFromChat(chatSelection.AllData) === true) {
                            return;
                        }
                        setMentionList((/@ \s*$/.test(check_mention_text) && !/@[^ ]/.test(check_mention_text)) ? [] : isAtFollowedByChar ? filteredUsers : []);
                        setmentionDropdown(true);
                        setmentionSearchtxt(mentionText);
                        const editorRect = editorElement.getBoundingClientRect();
                        if (ckeditortype === 1) {
                            utils.EventCall(`setMentionDropDownLeft`, cursorOffset);
                        }
                    } else {
                        const ActiveLeftOption = isCustomerChat ? "Customers" : $("a.leftside-menu_options.active").text().replace(/[0-9]/g, '');
                        let users;
                        if (newChat === false && isNewChat === false) {
                            if (chatSelection?.AllData?.isGroupChat === false) {
                                users = [
                                    {
                                        id: chatSelection?.AllData.customerIdHash,
                                        name: chatSelection?.AllData?.firstName + " " + chatSelection?.AllData?.lastName,
                                        cdnUrl: chatSelection?.AllData?.cdnUrl,
                                        photo: chatSelection?.AllData?.photo,
                                        cell: chatSelection?.AllData?.cell,
                                        firstName: chatSelection?.AllData?.firstName,
                                        lastName: chatSelection?.AllData?.lastName,
                                    }
                                ];
                                setusers(users);
                            } else {
                                const userlist = chatSelection?.AllData?.Allmember?.filter(item => LoginUserIdHash !== item?.memberIdHash) || [];
                                users = userlist.map(arr => ({
                                    id: arr.memberIdHash,
                                    name: arr.firstName + " " + arr.lastName,
                                    cdnUrl: arr.cdnUrl,
                                    photo: arr.photo,
                                    cell: arr.cell,
                                    firstName: arr.firstName,
                                    lastName: arr.lastName,
                                }));
                                setusers(users);
                            }
                        }
                        else {
                            if ("Customers" === ActiveLeftOption) {
                                if (props.customerData !== undefined && props.customerData !== null && props.customerData !== "") {
                                    users = [
                                        {
                                            id: props.customerData.userIdHash,
                                            name: props.customerData.firstName + " " + props.customerData.lastName,
                                            cdnUrl: props.customerData.cdnUrl,
                                            photo: props.customerData.photo,
                                            cell: props.customerData.cell,
                                            firstName: props.customerData.firstName,
                                            lastName: props.customerData.lastName,
                                        }
                                    ];
                                    setusers(users);
                                }
                            }
                            else {
                                if (props.groupMemberArrRef !== undefined && props.groupMemberArrRef !== null && props.groupMemberArrRef !== "") {
                                    users = props.groupMemberArrRef.map(arr => ({
                                        id: arr.UserIdHash,
                                        name: arr.label,
                                        cdnUrl: arr?.profile?.split("Original/")[0],
                                        photo: arr?.profile?.split("Original/")[1],
                                        cell: arr.cell,
                                        firstName: arr?.label.split(" ")[0],
                                        lastName: arr?.label.split(" ")[1],
                                    }));
                                    setusers(users);
                                }
                            }
                        }
                        if (users !== undefined && users !== null && users !== "") {
                            if (chatSelection.IsFromGroup === true && CheckLoginUserIsRemovedFromChat(chatSelection.AllData) === true) {
                                return;
                            }
                            setMentionList(users);
                            setmentionDropdown(true);
                        }
                        setmentionSearchtxt("");

                    }
                } else {
                    setMentionList([]);
                    setmentionSearchtxt("");
                    setmentionDropdown(false);
                }
            } else {
                setMentionList([]);
                setmentionSearchtxt("");
                setmentionDropdown(false);
            }
            // End mention related Changes -----------------------
        }
        setEditorData(data);
    };

    const highlightText = (text, highlight) => {
        if (typeof highlight !== 'string' || !highlight.trim()) {
            return text;
        }

        const regex = new RegExp(`(${highlight})`, 'gi');
        const parts = text.split(regex);

        return parts.map((part, index) =>
            part.toLowerCase() === highlight.toLowerCase() ?
                <strong key={index}>{part}</strong> :
                part
        );
    };
    const ImageCount = (data) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(data, 'text/html');
        let count = 0;
        const images = doc.querySelectorAll('img');
        if (images.length > 0) {
            images.forEach(async (img) => {
                const alt = img.getAttribute('alt');
                if (alt !== 'Gif' && alt !== 'VagaroDrive') {
                    count++;
                }
            })
        }
        setImagesCount(count)
        return count;
    }
    const ClearEditor = () => {
        EditorObject.current.setData("");
        setTimeout(() => {
            CommonMethod?.ClearVideoContent()
        }, 50);
    }

    const EditorOnBlur = (event) => {
        const data = event.editor
        window.InputElemntsFocued = false;
    }
    const stopEditorFocus = useCallback(
        (event, element) => {
            process.nextTick(() => {
                element.scrollIntoView({ block: 'center', behavior: 'smooth' });
                event.stop();
            });
        },
        [],
    );

    const EditorOnFocus = (event) => {
        setFileDropdown(false);
        setShowGhipy(false);
        setShowDropdown(false);
        setShowEmojis(false);
        setOpenKeys([]);
        $(`.cke_button_icon-upload`).removeClass('active');
        $(`.cke_button__emojipanel_icon`).removeClass('active');
        $(`.cke_button_icon-gif`).removeClass('active');
        $(`.cke_button_icon-link`).removeClass('active');
        window.InputElemntsFocued = true;
        const messageElement = document.getElementsByClassName('ckeditor_2');
        if (isFromConnectApp && isAndroidIOSTabIpad() && messageElement.length > 0) {
            stopEditorFocus(event, messageElement[0])
        }
    };
    const EditorOnLoad = async (event) => {
        EditorObject.current = event;

        if (!EditorObject.current || !EditorObject.current.editing || !EditorObject.current.editing.view || !EditorObject.current.model) return;

        EditorObject.current.keystrokes.set('Shift+Enter', (evt, data) => {
            preventDefault(evt);
            simulateShiftEnter(EditorObject.current);
        });

        EditorObject.current.editing.view.document.on('keydown', (evt, data) => {
            if (data.keyCode === 38) {
                checkEditorContentAndCallFunction();
            }
        });

        const checkEditorContentAndCallFunction = () => {
            const editor = EditorObject.current;
            const editorContent = editor.getData();
            if (editorContent.trim() === '' && ckeditortype === 1) {
                customFunction();
            }
        };

        const customFunction = () => {
            window.EditOnUpArrow();
        };

        EditorObject.current.model.schema.extend('$text', { allowAttributes: ['boldText'] });

        EditorObject.current.model.schema.register('vg-user', {
            isInline: true,
            isObject: true,
            allowWhere: '$text',
            allowContentOf: '$text',
            allowAttributes: ['id', 'class']
        });
        EditorObject.current.conversion.for('upcast').elementToElement({
            view: {
                name: 'vg-user',
                attributes: {
                    class: true,
                    id: true
                }
            },
            model: (viewElement, { writer }) => {
                const attributes = {
                    class: viewElement.getAttribute('class'),
                    id: viewElement.getAttribute('id')
                };
                const content = viewElement.getChild(0) ? viewElement.getChild(0).data : ''; // Extract content if available
                const modelElement = writer.createElement('vg-user', attributes);
                writer.insert(writer.createText(content), modelElement, 'end'); // Insert content into the model element
                return modelElement;
            }
        });

        EditorObject.current.conversion.for('downcast').elementToElement({
            model: 'vg-user',
            view: (modelElement, { writer: viewWriter }) => {
                return viewWriter.createContainerElement('vg-user', {
                    id: modelElement.getAttribute('id'),
                    class: modelElement.getAttribute('class')
                });
            }
        });

        EditorObject.current.conversion.for('downcast').attributeToElement({
            model: 'boldText',
            view: (attributeValue, { writer }) => {
                return writer.createAttributeElement('span', {
                    class: 'mentionNameInChat'
                }, { priority: 5 });
            }
        });

        // Upcast conversion to handle inline style
        EditorObject.current.conversion.for('upcast').elementToAttribute({
            view: {
                name: 'span',
                classes: 'mentionNameInChat'
            },
            model: 'boldText'
        });

        EditorObject.current.conversion.for('downcast').attributeToElement({
            model: 'mentionHighlight',
            view: (attributeValue, { writer }) => {
                return writer.createAttributeElement('span', {
                    class: 'mentionHighlight',
                    data: true,
                }, { priority: 5 });
            }
        });

        EditorObject.current.conversion.for('upcast').elementToAttribute({
            view: {
                name: 'span',
                classes: 'mentionHighlight'
            },
            model: {
                key: 'mentionHighlight',
                value: true,
            }
        });

        EditorObject.current.model.schema.extend('$text', { allowAttributes: 'restrictedWordHighlight' });
        EditorObject.current.conversion.for('downcast').attributeToElement({
            model: 'restrictedWordHighlight',
            view: (attributeValue, { writer }) => {
                return writer.createAttributeElement('span', {
                    class: 'restrictedWordHighlight'
                }, { priority: 5 });
            }
        });
        EditorObject.current.conversion.for('upcast').elementToAttribute({
            view: {
                name: 'span',
                classes: 'restrictedWordHighlight'
            },
            model: {
                key: 'restrictedWordHighlight',
                value: true
            }
        });

        GetLocalStorageForConversation();

        AppendGhipyButton();

        const editor = EditorObject.current;
        editor.editing.view.document.on('drop', (evt, data) => {
            handleDrop(data.domEvent, evt);
        });

        editor.model.document.on('change:data', (event) => {
            try {
                MentionedDropdownRef.current = true;
            }
            catch (error) {
                console.log('An error occurred during the change:data event:', error);
            }
        });

        editor.ui.view.element.addEventListener('dragover', handleDragOver);

        ReactDOM.render(
            <VideoContent type={ckeditortype} CustomerChat={isCustomerChat} Connectapp={isFromConnectApp} isIOSMobile={isIOSMobile} isAndroidIOSMobile={isAndroidIOSMobile} />
            , document.getElementById('Video-content' + ckeditortype)
        );
        return () => {
            editor.ui.view.element.removeEventListener('dragover', handleDragOver);
            editor.ui.view.element.removeEventListener('drop', handleDrop);
        };
    };




    const handleKeydown = (event, data, editor, returnData) => {
        const model = editor.model;
        const root = model.document.getRoot();
        let badwordFound = false;
        const selection = model.document.selection;

        if (StoreBadWordString.current !== undefined && StoreBadWordString.current !== null && StoreBadWordString.current !== "") {
            cannabisWords.current = StoreBadWordString.current.cannabisWords.split(",");
            cannabisContainWords.current = StoreBadWordString.current.cannabisContainWords.split(",");
        }
        const ActiveLeftOption = isCustomerChat ? "Customers" : $("a.leftside-menu_options.active").text().replace(/[0-9]/g, '');

        model.change(writer => {
            const ranges = Array.from(model.createRangeIn(root));
            ranges.forEach(range => {
                if (range.item.is('$textProxy') && range.item.hasAttribute('restrictedWordHighlight')) {
                    writer.removeAttribute('restrictedWordHighlight', range.item);
                }
            });

            if (editor.getData() && "Customers" === ActiveLeftOption && StoreBadWordString.current !== undefined && StoreBadWordString.current !== null && StoreBadWordString.current !== "") {
                for (const paragraph of root.getChildren()) {
                    if (paragraph.is('element', 'paragraph')) {
                        let paragraphOffset = 0; // Offset within the current paragraph
                        let paragraphText = '';

                        // Iterate through each child of the paragraph
                        for (const node of paragraph.getChildren()) {
                            if (node.is('$text')) {
                                paragraphText += node.data;
                            } else if (node.is('element', 'softBreak')) {
                                paragraphText += '\n'; // Representing soft break as newline
                            }
                            else if (node.is('element', 'imageInline')) {
                                paragraphText += ' '; // Adding a space for the image tag
                            }
                            else if (node.is('element', 'vg-user')) {
                                paragraphText += ' '; // Adding a space for the vg-user tag
                            }
                        }

                        if (paragraphText.trim() !== "") {
                            const lowerCaseParagraphText = paragraphText.toLowerCase();

                            // Highlight single words
                            if (cannabisWords.current) {
                                cannabisWords.current.forEach(word => {
                                    const lowerCaseWord = word.toLowerCase();
                                    const regex = new RegExp(`\\b${lowerCaseWord}\\b`, 'gi');
                                    let match;
                                    while ((match = regex.exec(lowerCaseParagraphText)) !== null) {
                                        const index = match.index + paragraphOffset;
                                        const startPosition = writer.createPositionAt(paragraph, index);
                                        const endPosition = writer.createPositionAt(paragraph, index + lowerCaseWord.length);
                                        const range = writer.createRange(startPosition, endPosition);
                                        writer.setAttribute('restrictedWordHighlight', true, range);
                                        badwordFound = true;
                                    }
                                });
                            }

                            // Highlight multi-word phrases
                            if (cannabisWords.current) {
                                cannabisWords.current.forEach(phrase => {
                                    if (phrase.includes(' ')) {
                                        const regex = new RegExp(`\\b${phrase.toLowerCase().replace(/\s+/g, ' ')}\\b`, 'gi');
                                        let match;
                                        while ((match = regex.exec(lowerCaseParagraphText)) !== null) {
                                            const index = match.index + paragraphOffset;
                                            const startPosition = writer.createPositionAt(paragraph, index);
                                            const endPosition = writer.createPositionAt(paragraph, index + phrase.length);
                                            const range = writer.createRange(startPosition, endPosition);
                                            writer.setAttribute('restrictedWordHighlight', true, range);
                                            badwordFound = true;
                                        }
                                    }
                                });
                            }

                            // Highlight keywords from cannabisContainWords
                            if (cannabisContainWords.current) {
                                cannabisContainWords.current.forEach(keyword => {
                                    let index = lowerCaseParagraphText.indexOf(keyword);
                                    while (index !== -1) {
                                        const startPosition = writer.createPositionAt(paragraph, index + paragraphOffset);
                                        const endPosition = writer.createPositionAt(paragraph, index + paragraphOffset + keyword.length);
                                        const range = writer.createRange(startPosition, endPosition);
                                        writer.setAttribute('restrictedWordHighlight', true, range);
                                        index = lowerCaseParagraphText.indexOf(keyword, index + keyword.length);
                                        badwordFound = true;
                                    }
                                });
                            }

                            // Update the offset for the next paragraph
                            paragraphOffset += paragraphText.length + 1; // +1 for the soft break or space between paragraphs
                        }
                    }
                }
            }

            if (!badwordFound) {
                setbadwordAlert(false);
                returnData(false);
                BadWordsAddClass(false);
                return false;
            }
            if (event.key === 'Backspace') {
                // Start mention realted Changes -----------------------
                const position = selection.getFirstPosition();
                const nodeBefore = position.nodeBefore;
                // Check if the node before the cursor is a vg-user element
                if (nodeBefore && nodeBefore.is('element', 'vg-user')) {
                    // Remove the vg-user element
                    writer.remove(nodeBefore);
                    event.preventDefault();
                }
                // End mention realted Changes -----------------------
            }
            BadKeyWordCheck.current = true;
            //if (textmarketingalerts.current === 0) {
            setbadwordAlert(true);

            BadWordsAddClass(true);
            returnData(true);
            return true;
        });
        // }, 0);
    };

    const preventDefault = (evt) => {
        evt.preventDefault();
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handlers = useSwipeable({
        onSwiping: (e) => {
            if (e.dir === 'Up' && swipedUp === false) {
                const newHeight = Math.max(305, 350 + Math.abs(e.deltaY));
                setHeight(`${newHeight}px`);
            }
            if (e.dir === 'Down' && swipedUp === true) {
                const newHeight = Math.max(305, 300 + (350 - Math.abs(e.deltaY)));
                setHeight(`${newHeight}px`);
            }
        },
        onSwipedUp: (e) => {
            if (Math.abs(e.deltaY) > 300) {
                setHeight('calc(100vh - 45px)');
                setSwipedUp(true);
            } else {
                if (swipedUp === false) {
                    setHeight('305px');
                    setSwipedUp(false);
                }
            }
        },
        onSwipedDown: (e) => {
            if (Math.abs(e.deltaY) > 300) {
                setHeight('305px');
                setSwipedUp(false);
            } else {
                if (swipedUp === true) {
                    setHeight('calc(100vh - 45px)');
                }
            }
        },
        preventDefaultTouchmoveEvent: true,
        trackMouse: true,
    });

    const handleDrop = (event, evt) => {
        const Files = event.dataTransfer?.files || ""
        const ImageDataTransfer = new DataTransfer();
        const videoDataTransfer = new DataTransfer();
        const OtherDataTransfer = new DataTransfer();
        Object.values(Files).map((file) => {
            if (file.type.includes("image")) {
                ImageDataTransfer.items.add(file)
            } else if (file.type.includes("video") && ["video/3gpp2", "video/3gpp", "video/3gpp", "video/x-m4v", "video/x-matroska", "video/quicktime", "video/mp4", "video/webm"].includes(file.type)) {
                videoDataTransfer.items.add(file)
            } else {
                OtherDataTransfer.items.add(file)
            }
        })
        if (videoDataTransfer.files.length > 0) {
            utils.EventCall(`DropFileInInput${ckeditortype}`, videoDataTransfer);
        }
        if (OtherDataTransfer.files.length > 0) {
            DropImageRistrict.current = 1;
            return ReactDOM.render(
                <Fragment>
                    <SuccessAlert
                        alertType={"warning"}
                        strMessage={"Please select valid file only."}
                        displaytime={3000}
                    />
                </Fragment>,
                document.getElementById("OpenDialog1")
            );
        }



        if (!['image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/webp,'].includes(ImageDataTransfer.files[0]?.type || "none")) {
            if (Files[0] !== undefined && Files[0] !== null && Files[0] !== "" && Files[0].type.startsWith('video/')) {
            } else {
                DropImageRistrict.current = 1;
                return ReactDOM.render(
                    <Fragment>
                        <SuccessAlert
                            alertType={"warning"}
                            strMessage={"Please select only image files."}
                            displaytime={3000}
                        />
                    </Fragment>,
                    document.getElementById("OpenDialog1")
                );
            }
        }

        const files = Array.from(ImageDataTransfer.files);
        const count = ImageCount(EditorObject.current.getData());
        const TotalCount = count + files.length;
        if (files.length > 10 || TotalCount > 10) {
            DropImageRistrict.current = 2;
            return true;
        }
        DropImageRistrict.current = 0;
        return true;
    };

    var first = false;
    var dropdown = null;
    const handleOutsideClickPopup = (event) => {
        if (isAndroidIOSMobile) {
            dropdown = CommonMethods.IsElement("Alert") && CommonMethods.GetElement("#Alert .modal-content");
            if (first && dropdown && !dropdown[0].contains(event.target)) {
                first = false;
                document.removeEventListener('click', handleOutsideClickPopup);
                unmountComponentAtNode(document.getElementById("OpenDialog"));
            } else {
                first = true;
            }
        }
    }

    const ShoppingCartRedirect = (urlObject) => {
        let RedirectUrl = urlObject.name === "Classes" ? '/ShopOwner/ManageNewServices.aspx' : '/merchants/settings/onlineshoppingcart';
        if (!isFromConnectApp) {
            window.parent.postMessage('{"eventType": "redirectToPageVagaro","Data": "' + RedirectUrl + '"}', '*');
        }
        else {
            isFromConnectApp && CommonMethods.Actionclick("messagesAreaheaderback");
        }
    }

    const ShowAlert = (urlObject) => {
        const AlertTitle = () => {
            return (
                <Fragment>
                    <Fragment>{urlObject.name === "Classes" ? "Online Class Required" : "Online Shopping Cart Feature Required"}</Fragment>
                </Fragment>
            )
        }
        const AlertBody = () => {
            return (
                <div>
                    <Fragment>
                        {urlObject.name === "Classes" ? "You don't have any classes added in Service Class Menu. Go to Service menu and add classes" : "To use this feature please activate online shopping cart."}
                    </Fragment>
                </div>
            )
        }
        const AlertFooter = () => {
            return (
                <Fragment>
                    <Fragment>
                        <button className="btn btn-secondary" onClick={() => AlertClose()}>Cancel</button>
                        {!isFromConnectApp &&
                            <button className="btn btn-primary" onClick={() => { AlertClose(); ShoppingCartRedirect(urlObject) }}>{urlObject.name === "Classes" ? "Add Class" : "Active Shopping Cart"}</button>
                        }
                    </Fragment>

                </Fragment >
            )
        }
        const AlertClose = () => {
            first = false;
            document.removeEventListener('click', handleOutsideClickPopup);
            unmountComponentAtNode(document.getElementById("OpenDialog"));
        };

        document.addEventListener('click', handleOutsideClickPopup);

        if (isAndroidIOSMobile) {
            AddCommonMethod("MobileCallBackForBack", () => {
                if (CommonMethods.IsElement("Alert")) {
                    AlertClose();
                } else {
                    CommonMethods.Actionclick("messagesAreaheaderback");
                }
            })
        }
        return ReactDOM.render(
            <VGPopUp
                id={"Alert"}
                show={true}
                onHide={AlertClose}
                title={AlertTitle()}
                dialogClassName="vgn-modal-alert vgn-modal-small modal-mb-half"
                body={AlertBody()}
                footer={AlertFooter()}
                isRemoveTabModalIndex={false}
                isShowFooter={true}
                backdrop={true}
            />,
            document.getElementById("OpenDialog")
        );
    }


    const CustomeLink = (url, urlObject) => {
        if (urlObject.isActive === false) {
            setShowDropdown(false);
            setOpenKeys([]);
            return (ShowAlert(urlObject))
        }
        else {
            const editor = EditorObject.current;
            let linkHtml = "";            
            if (urlObject.name === "Vagaro Pay Later Invite") {
                url = url.replace("CustomerMongoId", chatSelection.AllData.customerIdForVPL);
            }
            if (urlObject === "promoCode") {
                linkHtml = url;
            }
            else {
                linkHtml = `<p><a target="_blank" href="${url}">${url}</a> </p>`;
            }

            const viewFragment = editor.data.processor.toView(linkHtml);
            const modelFragment = editor.data.toModel(viewFragment);
            editor.model.insertContent(modelFragment);
            if (!isAndroidIOSTabIpad()) {
                EditorObject.current.focus();
            }
            const updatedEditorData = editor.getData() + linkHtml;
            setEditorData(updatedEditorData);
            setShowDropdown(false);
            setOpenKeys([]);
        }
    }

    const onUploadClickFromVagaroDrive = (uploaddata) => {
        var innerHtml = "";
        for (let index = 0; index < uploaddata.length; index++) {
            const element = uploaddata[index];
            const editor = EditorObject.current;
            const Editoer = EditorObject.current.getData();

            if (element.fileType === "pdf") {
                innerHtml = innerHtml + `<p><a href="${element.fileUrl}">${element.fileUrl}</a></p>`;
            } else if (element.fileType === "xlsx" || element.fileType === "xls") {
                innerHtml = innerHtml + `<p><a href="${element.fileUrl}">${element.fileUrl}</a></p>`;
            } else if (element.fileType === "txt") {
                innerHtml = innerHtml + `<p><a href="${element.fileUrl}">${element.fileUrl}</a></p>`;
            } else if (element.fileType === "mp4") {
                innerHtml = innerHtml + `<p><a href="${element.fileUrl}">${element.fileUrl}</a></p>`;
            }
            //  else if (element.fileType === "mp3") {
            //     innerHtml = innerHtml + `<p><a href="${element.fileUrl}">${element.fileUrl}</a></p>`;
            // }
            else if (element.fileType === "jpg" || element.fileType === "png" || element.fileType === "jpeg" || element.fileType === "gif") {
                const width = 163; // Set your desired width
                const height = 'auto'; // Set your desired height
                innerHtml = innerHtml + `<p><img src=${element.fileUrl} alt="VagaroDrive" width="${width}" height="${height}"></p>`;
            } else {
                innerHtml = innerHtml + `<p><a href="${element.fileUrl}">${element.fileUrl}</a></p>`;
            }
            if (index === uploaddata.length - 1) {
                editor.setData(Editoer + innerHtml);
                setEditorData(Editoer + innerHtml);
            }
            $(`.cke_button_icon-upload`).removeClass('active');
        }
    }
    const handelFile = (e) => {
        const files = e.target.files;
        let count = ImageCount(EditorObject.current.getData());
        let FilesCount = files.length;
        let Totalcount = count + FilesCount;
        let maxSizeInBytes = 30 * 1024 * 1024;
        if (Totalcount > 10 || files.length > 10) {
            return ReactDOM.render(
                <Fragment>
                    <SuccessAlert
                        alertType={"warning"}
                        strMessage={"Please select up to 10 files."}
                        displaytime={2000}
                    />
                </Fragment>,
                document.getElementById("OpenDialog1")
            );
        }
        setshowFileUploadProgressMessage(true);
        FileUploadStatus.current = true;
        // Handle each selected file individually
        Array.from(files).forEach((file, index) => {
            if (file) {
                if (!['image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/webp'].includes(file.type)) {
                    $(`.cke_button_icon-upload`).removeClass('active');
                    setshowFileUploadProgressMessage(false);
                    FileUploadStatus.current = false;
                    return ReactDOM.render(
                        <Fragment>
                            <SuccessAlert
                                alertType={"warning"}
                                strMessage={"Please select only image files."}
                                displaytime={3000}
                            />
                        </Fragment>,
                        document.getElementById("OpenDialog1")
                    );
                }
                if (file && file.size > maxSizeInBytes) {
                    $(`.cke_button_icon-upload`).removeClass('active');
                    setshowFileUploadProgressMessage(false);
                    FileUploadStatus.current = false;
                    return ReactDOM.render(
                        <Fragment>
                            <SuccessAlert
                                alertType={"warning"}
                                strMessage={"File size limit exceeds 30 MB."}
                                displaytime={3000}
                            />
                        </Fragment>,
                        document.getElementById("OpenDialog1")
                    );
                }

                const reader = new FileReader();
                reader.onload = async (readerEvent) => {
                    const imageDataUrl = readerEvent.target.result;
                    const width = 163;
                    const height = 'auto';
                    await compressImage(readerEvent.target, imageDataUrl, async Data => {
                        await ImageUploadOnDrive(Data.url, file.name, Successdata => {
                            const ImageHtml = `<img src="${Successdata.data.data}" alt="${file.name}" width="${width}" height="${height}">`;
                            const viewFragment = EditorObject.current.data.processor.toView(ImageHtml);
                            const modelFragment = EditorObject.current.data.toModel(viewFragment);
                            EditorObject.current.model.insertContent(modelFragment);
                            simulateShiftEnter(EditorObject.current);
                            const messageElement = document.getElementsByClassName('ckeditor_2');
                            if (messageElement.length > 0) {
                                messageElement[0].scrollIntoView({ block: 'center' });
                            }
                            setshowFileUploadProgressMessage(false);
                            FileUploadStatus.current = false;
                        }, error => { return "" });
                    });
                };

                $(`.cke_button_icon-upload`).removeClass('active');
                reader.readAsDataURL(file);
            }
        });
        setFileDropdown(false);
    };

    const handleFileChange = (forCamera = false) => {
        if (isIOSMobile && isFromConnectApp) {
            // // Mobile Callback for Sent PhotoGallary access Request
            // AddCommonMethod("AccessPermissionGranted", () => {
            //     if (forCamera) {
            //         InputCamera.current.click();
            //     } else {
            //         InputPhotoLibrary.current.click();
            //     }
            // })
            // utils.CallBackGivenToMobile(MobileCallBackAction.AccessPermission, { "type": 2 })


            if (forCamera) {
                InputCamera.current.click();
            } else {
                InputPhotoLibrary.current.click();
            }


        } else {
            InputPhotoLibrary.current.click();
        }

    };

    useEffect(() => {
        if (showErrorMessage || maxLenghtAlert) {
            setTimeout(() => {
                setshowErrorMessage(false);
                setmaxLenghtAlert(false);
            }, 2000);
        }
        if (isTextmarketingalerts !== 0) {
            $(`.ckeditor_${ckeditortype} .ck.ck-reset`).addClass('textmarketing-alert');
            $(`.typeChatWrap`).addClass('textmarketing-composerwrap');
        }
        else {
            $(`.ckeditor_${ckeditortype} .ck.ck-reset`).removeClass('textmarketing-alert');
            $(`.typeChatWrap`).removeClass('textmarketing-composerwrap');
        }

    }, [showErrorMessage, maxLenghtAlert, isTextmarketingalerts]);

    const BadWordsAddClass = (IsClassAdd, keyWords) => {
        if (IsClassAdd) {
            $(`.ckeditor_${ckeditortype} .ck.ck-reset`).addClass('restrictedText-alert');
            $(`.typeChatWrap`).addClass('restrictedText-composerwrap');
        }
        else {
            $(`.ckeditor_${ckeditortype} .ck.ck-reset`).removeClass('restrictedText-alert');
            if (ckeditortype === 1) {
                $(`.typeChatWrap`).removeClass('restrictedText-composerwrap');
            }
        }
    }

    const RemoveFigure = (EditoerData) => {
        EditorObject.current.setData("");
        const RemovefigurTag = RemoveFigureTag(EditoerData)
        EditorObject.current.setData(RemovefigurTag);
        EditorObject.current.model.change(writer => {
            writer.setSelection(writer.createPositionAt(EditorObject.current.model.document.getRoot(), 'end'));
        });
    }

    const MyUploader = (loader) => {
        const EditoerData = EditorObject.current.getData();
        return {
            upload: () => {
                return loader.file
                    .then(file => new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.onload = async (readerEvent) => {
                            const imageDataUrl = readerEvent.target.result;
                            const width = 160;
                            const height = 'auto';
                            let count = ImageCount(EditorObject.current.getData());
                            if (count > 10 || DropImageRistrict.current === 2) {
                                RemoveFigure(EditoerData);
                                return ReactDOM.render(
                                    <Fragment>
                                        <SuccessAlert
                                            alertType={"warning"}
                                            strMessage={"Please select up to 10 files."}
                                            displaytime={2000}
                                        />
                                    </Fragment>,
                                    document.getElementById("OpenDialog1")
                                );
                            } else {
                                if (DropImageRistrict.current === 1) {
                                    RemoveFigure(EditoerData);
                                    return;
                                }
                                setshowFileUploadProgressMessage(true);
                                FileUploadStatus.current = true;
                                await compressImage(readerEvent.target, imageDataUrl, async (Data) => {
                                    await ImageUploadOnDrive(Data.url, file.name, Successdata => {
                                        var ImageHtml = `<p><img src="${Successdata.data.data}" alt=${file.name} width="${width}" height="${height}"></p>`;
                                        let RemovefigurTag = RemoveFigureTag(EditorObject.current.getData())
                                        ImageHtml = RemovefigurTag + ImageHtml;
                                        EditorObject.current.setData(ImageHtml);
                                        // simulateShiftEnter(EditorObject.current);
                                        const messageElement = document.getElementsByClassName('ckeditor_2');
                                        if (messageElement.length > 0) {
                                            messageElement[0].scrollIntoView({ block: 'center' });
                                        }
                                        setshowFileUploadProgressMessage(false);
                                        FileUploadStatus.current = false;
                                    }, error => { return "" });
                                });
                            }
                        };
                        reader.readAsDataURL(file);
                    }));
            }
        };
    };

    const RemoveFigureTag = (data) => {
        const stringWithoutFigures = data.replace(/<figure\b[^>]*>(.*?)<\/figure>/g, '');
        return stringWithoutFigures;
    }

    const UnderDevelopment = () => {
        return ReactDOM.render(
            <Fragment>
                <SuccessAlert
                    alertType={"warning"}
                    strMessage={"Under development."}
                    displaytime={2000}
                />
            </Fragment>,
            document.getElementById("OpenDialog1")
        );
        /*window.OpenCustomerHistoryPopupFromVagaroConnect && window.OpenCustomerHistoryPopupFromVagaroConnect(custommerProfile.userId)*/
    }

    const EditEditoerOnClick = (isFromEnter, evt, data) => {
        const ActiveLeftOption = isCustomerChat ? "Customers" : $("a.leftside-menu_options.active").text().replace(/[0-9]/g, '');
        if (UserTextMarketingData !== undefined && UserTextMarketingData !== null && UserTextMarketingData !== "") {
            if (chatSelection.AllData.isCustomerOnline === false && UserTextMarketingData.current?.isTextMessagingActivated === false && "Customers" === ActiveLeftOption && !isCustomerChat && !NoteObject.current && UserTextMarketingData.current?.numberStatus !== 5) {
                if (evt !== undefined && evt !== null && evt !== "") {
                    evt.stop();
                    data.stopPropagation();
                    data.preventDefault();
                }
                return ReactDOM.render(
                    <CommonMessagepopup
                        data={UserTextMarketingData.current}
                        isFromConnectApp={isFromConnectApp}
                        isAndroidIOSMobile={isAndroidIOSMobile}
                        isPlanActive={false}
                    />,
                    document.getElementById("OpenDialog1")
                );
            }
            else {
                if (UserTextMarketingData.current?.isTextMessagingActivated === true && "Customers" === ActiveLeftOption && !isCustomerChat && chatSelection.AllData.isCustomerOnline === false && !NoteObject.current) {
                    if (UserTextMarketingData.current?.isNumberApproved === false || UserTextMarketingData.current?.cell === "" || UserTextMarketingData.current?.isNotifyBySMS === false) {
                        if (evt !== undefined && evt !== null && evt !== "") {
                            evt.stop();
                            data.stopPropagation();
                            data.preventDefault();
                            return;
                        }
                        else {
                            return;
                        }
                    }
                }
            }
        }
        const SendData = EditorObject.current.getData();
        let CharacterLimit = SendData.replace(/<[^>]*>|&nbsp;/g, '');


        handleKeydown("", "", EditorObject.current, async returnData => {
            if (returnData) {
                if (evt !== undefined && evt !== null && evt !== "") {
                    evt.stop();
                    data.stopPropagation();
                    data.preventDefault();
                }
                return;
            }
            else {
                var buttonTextValue = document.getElementById('sendbutton1').text === "SMS";
                var Maximumlength = (("Customers" === ActiveLeftOption && chatSelection.AllData.isCustomerOnline === false) || buttonTextValue) ? 1000 : 2000;
                if (CharacterLimit.length > Maximumlength) {
                    setmaxLenghtAlert(true);
                    return;
                }
                setFileDropdown(false);
                setShowGhipy(false);
                setShowDropdown(false);
                setOpenKeys([]);
                // if (isMessageChanged.current === true || isNoteRef2.current !== messageDetails.isNote) {
                let count = ImageCount(EditorObject.current.getData());
                if (count > 10) {
                    return ReactDOM.render(
                        <Fragment>
                            <SuccessAlert
                                alertType={"warning"}
                                strMessage={"Please select up to 10 files."}
                                displaytime={2000}
                            />
                        </Fragment>,
                        document.getElementById("OpenDialog1")
                    );
                }
                var SendDataAttachment = SendData;
                var UplodStatus = 0;
                utils.EventCall(`FileUplodStatus${ckeditortype}`, (val) => {
                    UplodStatus = val
                });
                if (UplodStatus > 0) {
                    if (evt !== undefined && evt !== null && evt !== "") {
                        evt.stop();
                        data.stopPropagation();
                        data.preventDefault();
                    }
                    setshowFileUploadProgressMessage(true);
                    return;
                }
                if (document.getElementById("Video-content2") && document.getElementById("Video-content2")?.querySelectorAll('video').length > 0) {
                    SendDataAttachment += document.getElementById("Video-content2")?.querySelectorAll('video').length;
                }
                if (SendDataAttachment === undefined || SendDataAttachment === null || SendDataAttachment === "") {
                    if (isFromEnter) {
                        evt.stop();
                        data.stopPropagation();
                        data.preventDefault();
                    }
                    EditorObject.current.setData("");
                    setshowErrorMessage(true);
                    if (!isAndroidIOSTabIpad()) {
                        EditorObject.current.focus();
                    }
                    return false;
                }
                messageDetails.message = EditorObject.current.getData();
                messageDetails.isNote = isNoteRef2.current;
                messageDetails.isEdited = true;
                messageDetails.attachment = []
                const videos = document.getElementById("Video-content" + ckeditortype)?.querySelectorAll('video');
                if (videos.length > 0) {
                    videos.forEach(video => {
                        const Type = video.dataset.type;
                        const FileUrl = video.dataset.url;
                        const FileName = video.dataset.name;
                        messageDetails.attachment.push({ Type, FileUrl, FileName });
                    });
                }
                EditDeleteMessage(messageDetails, 3, isLastMessageIndex);
            }
        })
    }

    const handleFileCaptureCamera = () => {
        if (BlockUseralert()) {
            handleFileChange(true);
            setShowGhipy(false);
            setShowDropdown(false);
            setShowEmojis(false);
            setOpenKeys([]);
            $(`.ckeditor_${ckeditortype} .cke_button__emojipanel_icon`).removeClass('active');
            $(`.ckeditor_${ckeditortype} .cke_button_icon-gif`).removeClass('active');
            $(`.ckeditor_${ckeditortype} .cke_button_icon-link`).removeClass('active');
        }
    }


    const AppendGhipyButton = () => {

        if (EditorObject.current) {
            // Create a new button element

            //this custome button add for gif
            var customButtonGIF = $('<a>', {
                'id': 'Ghippy' + ckeditortype,
                'class': 'cke_button cke_button_gif',
                'role': 'button',
                'tabindex': '-1',
                'aria-labelledby': 'cke_button__custom',
                'aria-haspopup': 'true',
                'href': 'javascript:void(0);',
                'title': 'GIF'
            }).append(
                $('<span>', {
                    'class': 'cke_button_icon cke_button_icon-gif',
                    'aria-hidden': 'true',
                })
            );

            //this custome button add for Note
            var customButtonNote = $('<a>', {
                'id': 'note' + ckeditortype,
                'class': 'cke_button cke_button_note',
                'role': 'button',
                'aria-labelledby': 'cke_button__custom',
                'aria-haspopup': 'true',
                'href': 'javascript:void(0);',
                'title': 'Internal Note'
            }).append(
                $('<span>', {
                    'class': 'cke_button_icon cke_button_icon-note',
                    'aria-hidden': 'true',
                })
            );

            //this custome button add for Link
            var customButtonLink = $('<a>', {
                'id': 'Vagaro_Links' + ckeditortype,
                'class': 'cke_button cke_button_link',
                'role': 'button',
                'aria-labelledby': 'cke_button__custom',
                'aria-haspopup': 'true',
                'href': 'javascript:void(0);',
                'title': 'Vagaro Links'
            }).append(
                $('<span>', {
                    'class': 'cke_button_icon cke_button_icon-link',
                    'aria-hidden': 'true',
                })
            );

            //this custome button add for UploadFiles
            var customButtonFiles = $('<a>', {
                'id': 'Vagaro_files' + ckeditortype,
                'class': 'cke_button cke_button_upload',
                'role': 'button',
                'aria-labelledby': 'cke_button__custom',
                'aria-haspopup': 'true',
                'href': 'javascript:void(0);',
                'title': 'Attach files'
            }).append(
                $('<span>', {
                    'class': 'cke_button_icon cke_button_icon-upload',
                    'aria-hidden': 'true',
                })
            );

            //this custome button add for Send Button
            var customButtonSend = $('<a>', {
                'id': 'sendbutton' + ckeditortype,
                'class': 'cke_button cke_button_Send',
                'role': 'button',
                'tabindex': '-1',
                'aria-labelledby': 'cke_button__custom',
                'aria-haspopup': 'true',
                'href': 'javascript:void(0);',
                'text': 'Send'
            });

            var customButtonCancel = $('<a>', {
                'id': 'cancelbutton' + ckeditortype,
                'class': 'saveControl saveControl-cancel',
                'role': 'button',
                'tabindex': '-1',
                'aria-labelledby': 'cke_button__custom',
                'aria-haspopup': 'true',
                'href': 'javascript:void(0);'
            })
            var customButtonSave = $('<a>', {
                'id': 'savebutton' + ckeditortype,
                'class': 'saveControl',
                'role': 'button',
                'tabindex': '-1',
                'aria-labelledby': 'cke_button__custom',
                'aria-haspopup': 'true',
                'href': 'javascript:void(0);',
                'text': ''
            }).append(
                $('<span>', {
                    'class': 'fa fa-solid fa-check',
                    'aria-hidden': 'true',
                })
            );

            //ad  emoji
            var customButtonEmoji = $('<a>', {
                'id': 'Emoji' + ckeditortype,
                'class': 'cke_button __emojipanel custom-emoji',
                'role': 'button',
                'tabindex': '-1',
                'aria-labelledby': 'cke_button__custom',
                'aria-haspopup': 'true',
                'href': 'javascript:void(0);',
                'title': 'Emoji'
            }).append(
                $('<span>', {
                    'class': 'cke_button_icon cke_button__emojipanel_icon',
                    'aria-hidden': 'true',
                })
            );
            var Video_content = $('<div>', {
                'id': 'Video-content' + ckeditortype,
                'class': 'ck ck-content ck-editor__editable ',
                'tabindex': '-1',
                'aria-haspopup': 'true',
            })

            //this custome button Click event for gif
            customButtonGIF.on('click', function () {
                if (BlockUseralert()) {
                    setShowGhipy(showGhipy => {
                        if (!showGhipy) {
                            $(`.ckeditor_${ckeditortype} .cke_button_icon-gif`).addClass('active');
                        }
                        else {
                            $(`.ckeditor_${ckeditortype} .cke_button_icon-gif`).removeClass('active');
                        }
                        return !showGhipy
                    })
                    setFileDropdown(false);
                    setShowDropdown(false);
                    setShowEmojis(false);
                    setOpenKeys([]);
                    $(`.ckeditor_${ckeditortype} .cke_button_icon-link`).removeClass('active');
                    $(`.ckeditor_${ckeditortype} .cke_button_icon-upload`).removeClass('active');
                    $(`.ckeditor_${ckeditortype} .cke_button__emojipanel_icon`).removeClass('active');
                    if (ckeditortype === 2) {
                        CalculateHeight('Ghippy2', 'gifPluginBlock');
                    }
                }
            });
            customButtonGIF.on('touchend', function (event) {
                isIOSMobile && event.cancelable && event.target.click()
            })

            //this custome button Click event for Note
            customButtonNote.on('click', function () {
                if (BlockUseralert()) {
                    onClickOfNotes();
                    setShowEmojis(false);
                    setShowGhipy(false)
                    setFileDropdown(false);
                    setShowDropdown(false);
                    setOpenKeys([]);
                }
            });

            //this custome button Click event for Link

            customButtonLink.on('click', function () {
                if (BlockUseralert()) {
                    setShowDropdown(showDropdown => {
                        if (!showDropdown) {
                            $(`.ckeditor_${ckeditortype} .cke_button_icon-link`).addClass('active');
                        }
                        else {
                            $(`.ckeditor_${ckeditortype} .cke_button_icon-link`).removeClass('active');
                        }
                        return !showDropdown
                    });
                    setFileDropdown(false);
                    setShowGhipy(false);
                    setShowEmojis(false);
                    $(`.ckeditor_${ckeditortype} .cke_button_icon-upload`).removeClass('active');
                    $(`.ckeditor_${ckeditortype} .cke_button__emojipanel_icon`).removeClass('active');
                    $(`.ckeditor_${ckeditortype} .cke_button_icon-gif`).removeClass('active');
                }
            });
            customButtonLink.on('touchend', function (event) {
                isIOSMobile && event.cancelable && event.target.click()
            })

            //this custome button Click event for UploadFiles
            customButtonFiles.on('click', function () {
                if (BlockUseralert()) {
                    if (isCustomerChat) {
                        handleFileChange()
                    } else {
                        // setFileDropdown(showFileDropdown => {
                        //     if (!showFileDropdown) {
                        //         $(`.ckeditor_${ckeditortype} .cke_button_icon-upload`).addClass('active');
                        //     }
                        //     else {
                        //         $(`.ckeditor_${ckeditortype} .cke_button_icon-upload`).removeClass('active');
                        //     }
                        //     return !showFileDropdown
                        // });
                        handleFileChange();
                    }
                    setShowGhipy(false);
                    setShowDropdown(false);
                    setShowEmojis(false);
                    setOpenKeys([]);
                    $(`.ckeditor_${ckeditortype} .cke_button__emojipanel_icon`).removeClass('active');
                    $(`.ckeditor_${ckeditortype} .cke_button_icon-gif`).removeClass('active');
                    $(`.ckeditor_${ckeditortype} .cke_button_icon-link`).removeClass('active');
                }
            });
            customButtonFiles.on('touchend', function (event) {
                isIOSMobile && event.cancelable && event.target.click()
            })

            //this custome button Click event for Send Button
            customButtonSend.on('click', async function () {
                setFileDropdown(false);
                setShowGhipy(false);
                setShowDropdown(false);
                setShowEmojis(false);
                setOpenKeys([]);
                SentMessage(false);
            });


            customButtonCancel.on('click', function () {
                EditDeleteMessage(messageDetails, 4, isLastMessageIndex);
            });
            customButtonSave.on('click', function () {
                EditEditoerOnClick(false);
            });
            customButtonCancel.on('touchend', function (event) {
                isIOSMobile && event.cancelable && event.target.click()
            })
            customButtonSave.on('touchend', function (event) {
                isIOSMobile && event.cancelable && event.target.click()
            })



            //this custome button Click event for gif
            customButtonEmoji.on('click', function () {
                if (BlockUseralert()) {
                    setShowGhipy(false)
                    setFileDropdown(false);
                    setShowDropdown(false);
                    setOpenKeys([]);
                    setShowEmojis(showEmojis => {
                        if (!showEmojis) {
                            $(`.ckeditor_${ckeditortype} .cke_button__emojipanel_icon`).addClass('active');
                        }
                        else {
                            $(`.ckeditor_${ckeditortype} .cke_button__emojipanel_icon`).removeClass('active');
                        }
                        return !showEmojis;
                    });
                    $(`.ckeditor_${ckeditortype} .cke_button_icon-link`).removeClass('active');
                    $(`.ckeditor_${ckeditortype} .cke_button_icon-upload`).removeClass('active');
                    $(`.ckeditor_${ckeditortype} .cke_button_icon-gif`).removeClass('active');
                    if (ckeditortype === 2) {
                        CalculateHeight('Emoji2', 'EmojiPickerReact');
                    }
                }
            });
            customButtonEmoji.on('touchend', function (event) {
                isIOSMobile && event.cancelable && event.target.click()
            })

            EditorObject.current.editing.view.document.on('enter', (evt, data) => {
                if (data.isSoft === false) {
                    if (isAndroidIOSMobile && !isCustomerChat) {
                        return;
                    }
                    if (ckeditortype === 1) {
                        SentMessage(true, evt, data);
                    } else {
                        EditEditoerOnClick(true, evt, data);
                    }
                }
                else {
                    //EditEditoerOnClick();
                }
            });
            if (!isCustomerChat) {
                EditorObject.current.editing.view.document.on('keydown', (evt, data) => {
                    if (data.keyCode === 8) {
                        SetLocalStorageForConversation("");
                    }
                });
            }

            EditorObject.current.plugins.get('FileRepository').createUploadAdapter = (loader) => {
                return MyUploader(loader);
            };
            CommonMethods.RemoveElementbyID('Ghippy' + ckeditortype);
            CommonMethods.RemoveElementbyID('note' + ckeditortype);
            CommonMethods.RemoveElementbyID('Vagaro_Links' + ckeditortype);
            CommonMethods.RemoveElementbyID('Vagaro_files' + ckeditortype);
            CommonMethods.RemoveElementbyID('Emoji' + ckeditortype);
            CommonMethods.RemoveElementbyID('sendbutton' + ckeditortype);
            CommonMethods.RemoveElementbyID('cancelbutton' + ckeditortype);
            CommonMethods.RemoveElementbyID('savebutton' + ckeditortype);
            $(`.ckeditor_${ckeditortype} .ck-toolbar__items`).append(customButtonGIF);
            if (!isCustomerChat) {
                $(`.ckeditor_${ckeditortype} .ck-toolbar__items`).append(customButtonNote);
                if (ckeditortype === 2) {
                    $(`.ckeditor_${ckeditortype} .ck-toolbar__items .cke_button_note`).hide();
                    $(".cke_button_icon-note").hide();
                }
                if (props.ReactBusinessId !== 226) {
                    $(`.ckeditor_${ckeditortype} .ck-toolbar__items`).append(customButtonLink);
                }

            }
            $(`.ckeditor_${ckeditortype} .ck-toolbar__items`).append(customButtonFiles);
            $(`.ckeditor_${ckeditortype} .ck-toolbar__items`).append(customButtonEmoji);
            $(`.ckeditor_${ckeditortype} .ck-editor__main`).append(Video_content);

            if (ckeditortype === 1) {
                $(`.ckeditor_${ckeditortype} .ck-toolbar_grouping`).append(customButtonSend);
            }

            if (ckeditortype === 2) {
                $(`.ckeditor_${ckeditortype} .ck-toolbar_grouping`).append(customButtonCancel);
                $(`.ckeditor_${ckeditortype} .ck-toolbar_grouping`).append(customButtonSave);

            }
            if ("Employee" === LeftOption) {
                $(`.ckeditor_${ckeditortype} .ck-toolbar__items .cke_button_note`).hide();
                $(`.ckeditor_${ckeditortype} .ck-toolbar__items .cke_button_link`).hide();
                $(".cke_button_icon-note").hide();
                $(".cke_button_icon-link").hide();
            } else {
                $(`.ckeditor_${ckeditortype} .ck-toolbar__items .cke_button_note`).show();
                $(`.ckeditor_${ckeditortype} .ck-toolbar__items .cke_button_link`).show();
                $(".cke_button_icon-note").show();
                $(".cke_button_icon-link").show();
            }
            if (ckeditortype === 2) {
                $(`.ckeditor_${ckeditortype} .ck-toolbar__items .cke_button_note`).hide();
            }

            if (isCustomerChat) {
                const root = EditorObject.current.editing.view.document.getRoot('main');
                root.placeholder = 'Enter a message.';
            }
            //If customer is online button text should be Send and customer is offline button text should be SMS.
            if ("Customer" === LeftOption) {
                //     // isonline.current = chatSelection.AllData.isCustomerOnline;
                //     if (chatSelection.AllData.isCustomerOnline === false && smsavail.current) {
                //         $(`#sendbutton${ckeditortype}`).text("SMS");
                //     }
                //     else {
                //         $(`#sendbutton${ckeditortype}`).text("Send");
                //     }
            }
            else {
                $(`#sendbutton${ckeditortype}`).text("Send");
            }
            if (messageDetails !== undefined && EditorObject.current.getData() === "" && ckeditortype === 2) {
                const isSafari = !!window.GestureEvent;
                if (isSafari && !isAndroidIOSMobile && !isFromConnectApp) {
                    MentionedDropdownRef.current = false;
                    EditorObject.current.setData(messageDetails.message);
                    const viewFragment = EditorObject.current.data.processor.toView(messageDetails.message);
                    const modelFragment = EditorObject.current.data.toModel(viewFragment);
                    EditorObject.current.model.insertContent(modelFragment);
                    setTimeout(() => {
                        if (!isAndroidIOSTabIpad()) {
                            EditorObject.current.focus();
                        }
                    }, 100);
                    MentionedDropdownRef.current = true;
                } else {
                    MentionedDropdownRef.current = false;
                    const viewFragment = EditorObject.current.data.processor.toView(messageDetails.message);
                    const modelFragment = EditorObject.current.data.toModel(viewFragment);
                    EditorObject.current.model.insertContent(modelFragment);
                    if (!isAndroidIOSTabIpad()) {
                        EditorObject.current.focus();
                    }
                    MentionedDropdownRef.current = true;
                }

                if (messageDetails.isNote) {
                    onClickOfNotes();
                }
            }
            else {
                if (isAndroidIOSMobile === false && isTablet === false) {
                    if (!isAndroidIOSTabIpad()) {
                        EditorObject.current.focus();
                    }
                }
            }

        }

    }

    const GippyOnClick = (item) => {
        const Editoer = EditorObject.current.getData();
        let ItemUrl = item.images.downsized.url;
        const width = 163;
        const height = 'auto';
        const giphyHtml = `<img src="${ItemUrl}" alt="Gif" width="${width}" height="${height}"></img>`;
        const viewFragment = EditorObject.current.data.processor.toView(giphyHtml);
        const modelFragment = EditorObject.current.data.toModel(viewFragment);
        if (Editoer !== undefined && Editoer !== null && Editoer !== "") {
            simulateShiftEnter(EditorObject.current);
        }
        EditorObject.current.model.insertContent(modelFragment);
        if (Editoer !== undefined && Editoer !== null && Editoer !== "") {
            simulateShiftEnter(EditorObject.current);
        }
        if ((Editoer === undefined || Editoer === null || Editoer === "")) {
            simulateShiftEnter(EditorObject.current);
        }
        if (!isAndroidIOSTabIpad()) {
            EditorObject.current.focus();
        }
        setEditorData(Editoer + giphyHtml);

        setShowGhipy(false);
        setHeight('305px');
    }

    const simulateShiftEnter = (editor) => {
        editor.execute('shiftEnter');
        if (!isAndroidIOSTabIpad()) {
            EditorObject.current.focus();
        }
    };

    const onClickOfNotes = () => {
        NoteObject.current = !NoteObject.current;
        if (NoteObject.current) {
            setTextmarketingalerts(0);
            textmarketingalerts.current = 0;
            $('#sendbutton' + ckeditortype).text('Save Note');
            const root = EditorObject.current.editing.view.document.getRoot('main');
            root.placeholder = 'Internal Note - type your message here';
            $(`.ckeditor_${ckeditortype} .ck.ck-reset`).addClass('note-active');

        } else if (chatSelection.AllData.customerIdHash === "7A582EC453F2F16226BE137DC64422F53DF5E4F5" || ("Customer" === LeftOption && chatSelection.AllData.isCustomerOnline === false && UserTextMarketingData.current?.isTextMessagingActivated === true && UserTextMarketingData.current?.isNumberApproved === true && UserTextMarketingData.current?.cell !== "" && UserTextMarketingData.current?.isNotifyBySMS === true)) {
            $('#sendbutton' + ckeditortype).text('SMS');
            const root = EditorObject.current.editing.view.document.getRoot('main');
            root.placeholder = 'Write a message...';
            $(`.ckeditor_${ckeditortype} .ck.ck-reset`).removeClass('note-active');
        }
        else {
            TextMarketingAlerts();
            $('#sendbutton' + ckeditortype).text('Send');
            const root = EditorObject.current.editing.view.document.getRoot('main');
            root.placeholder = 'Write a message...';
            $(`.ckeditor_${ckeditortype} .ck.ck-reset`).removeClass('note-active');

        }
        if (!isAndroidIOSTabIpad()) {
            EditorObject.current.focus();
        }

        $(`.ckeditor_${ckeditortype} .cke_button_icon-upload`).removeClass('active');
        $(`.ckeditor_${ckeditortype} .cke_button__emojipanel_icon`).removeClass('active');
        $(`.ckeditor_${ckeditortype} .cke_button_icon-gif`).removeClass('active');
        $(`.ckeditor_${ckeditortype} .cke_button_icon-link`).removeClass('active');
    }


    const checkContent = (content) => {
        const nbspRegex = /&nbsp;$/;
        var nbpsValue = nbspRegex.test(content);
        if (nbpsValue) {
            const removedNbsp = content.replace(/&nbsp;/, '');

            const parser = new DOMParser();
            const doc = parser.parseFromString(removedNbsp, 'text/html');
            // Remove specified tags    
            doc.querySelectorAll('p, a, br').forEach((node) => node.remove());
            // Get the modified HTML content   
            const modifiedContent = doc.body.innerHTML;

            const containsFilesOrImages = /<img|<file/.test(content);
            const containsAlphabets = /[a-zA-Z]/.test(modifiedContent);
            if (!containsFilesOrImages && !containsAlphabets) {
                return true
            } else {
                return false
            }
        } else {
            return false;
        }
    };

    const RemoveImageBase64 = async (SendData, onSuccess) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(SendData.message, 'text/html');

        const images = doc.querySelectorAll('img');
        if (images.length > 0) {
            let count = 0;
            images.forEach(async (img) => {
                const alt = img.getAttribute('alt');
                const src = img.getAttribute('src');
                if ((alt !== 'Gif' && alt !== 'VagaroDrive') && (src && src.startsWith('data:image'))) {
                    const src = img.getAttribute('src');
                    await ImageUploadOnDrive(src, alt, data => {
                        img.setAttribute('src', data.data.data);
                        img.setAttribute('alt', alt);
                        count++;
                        if (count === images.length) {
                            const updatedHtmlString = doc.body.innerHTML;
                            onSuccess(updatedHtmlString);
                        }
                    }, error => { return "" });
                }
                else {
                    count++;
                    if (count === images.length) {
                        const updatedHtmlString = doc.body.innerHTML;
                        onSuccess(updatedHtmlString);
                    }
                }
            })
        }
        else {
            const updatedHtmlString = doc.body.innerHTML;
            onSuccess(updatedHtmlString);
        }
    }

    const TextMarketingAlerts = (evt) => {
        const ActiveLeftOption = isCustomerChat ? "Customers" : $("a.leftside-menu_options.active").text().replace(/[0-9]/g, '');
        if ((UserTextMarketingData.current?.isTextMessagingActivated === true && "Customers" === ActiveLeftOption && !isCustomerChat && chatSelection.AllData.isCustomerOnline === false && !NoteObject.current) || (UserTextMarketingData.current?.isTextMessagingActivated === true && "Customers" === ActiveLeftOption && !isCustomerChat && !NoteObject.current && (isNewChat || newChat))) {
            if (UserTextMarketingData.current?.isNumberApproved === false || UserTextMarketingData.current?.cell === "" || UserTextMarketingData.current?.isNotifyBySMS === false) {
                if (UserTextMarketingData?.current.isNumberApproved === false && UserTextMarketingData?.current.isTextMessagingActivated === true && UserTextMarketingData.current?.numberStatus !== 5) {
                    setTextmarketingalerts(1);
                    textmarketingalerts.current = 1;
                    return;
                }
                else if (UserTextMarketingData?.current.cell === "" && UserTextMarketingData?.current.isTextMessagingActivated === true && UserTextMarketingData.current?.numberStatus !== 5) {
                    setTextmarketingalerts(2);
                    textmarketingalerts.current = 2;
                    return;
                }
                else if (UserTextMarketingData?.current.isNotifyBySMS === false && UserTextMarketingData?.current.isTextMessagingActivated === true && UserTextMarketingData.current?.numberStatus !== 5) {
                    setTextmarketingalerts(3);
                    textmarketingalerts.current = 3;
                    return;
                }
                else if (UserTextMarketingData?.current.numberStatus === 5 && UserTextMarketingData?.current.isTextMessagingActivated === true) {
                    setTextmarketingalerts(4);
                    textmarketingalerts.current = 4;
                    return;
                }
                else {
                    setTextmarketingalerts(0);
                    textmarketingalerts.current = 0;
                }
                if (evt !== undefined && evt !== null && evt !== "") {
                    evt.stop();
                    data.stopPropagation();
                    data.preventDefault();
                    return;
                }
                else {
                    return;
                }
            }
        }
    }

    const GetConversationId = () => {
        if (isNewChat || newChat) {
            return null;
        } else if (chatSelection.ConversationId && chatSelection.ConversationId !== "") {
            return chatSelection.ConversationId;
        } else if (chatSelection.AllData.conversationId && chatSelection.AllData.conversationId !== "") {
            return chatSelection.AllData.conversationId;
        } else {
            return null;
        }
    }

    const SendMessageRequest = (isFromEnter, evt, data) => {
        const ActiveLeftOption = isCustomerChat ? "Customers" : $("a.leftside-menu_options.active").text().replace(/[0-9]/g, '');
        const SendData = EditorObject.current.getData();
        var SendDataAttachment = SendData;
        if (document.getElementById("Video-content1") && document.getElementById("Video-content1")?.querySelectorAll('video').length > 0) {
            SendDataAttachment += document.getElementById("Video-content1")?.querySelectorAll('video').length;
        }
        if (SendDataAttachment === undefined || SendDataAttachment === null || SendDataAttachment === "") {
            if (isFromEnter) {
                evt.stop();
                data.stopPropagation();
                data.preventDefault();
            }
            EditorObject.current.setData("");
            setshowErrorMessage(true);
            if (!isAndroidIOSTabIpad()) {
                EditorObject.current.focus();
            }
            return false;
        } if (checkContent(SendData)) {
            if (isFromEnter) {
                evt.stop();
                data.stopPropagation();
                data.preventDefault();
            }
            EditorObject.current.setData("");
            setshowErrorMessage(true);
            if (!isAndroidIOSTabIpad()) {
                EditorObject.current.focus();
            }
            return false;
        }
        let SendMessage = RemoveImageAttribute(SendData);
        const RequestData = {};
        RequestData.conversationId = GetConversationId();
        RequestData.SenderId = props.LoginUserIDV2;
        // RequestData.fullname = customer === undefined || customer === null || customer === "" ? ReactFirstName + " " + ReactLastName : isCustomerChat ? ReactFirstName + " " + ReactLastName : null;
        RequestData.fullname = ReactFirstName + " " + ReactLastName;
        RequestData.message = SendMessage;
        RequestData.MemberIds = customer === undefined || customer === null || customer === "" ? null : customer.current;
        RequestData.status = 0;
        RequestData.isnote = NoteObject.current;
        RequestData.attachments = [];
        RequestData.isMessageFromCustomer = isCustomerChat ? true : false;
        RequestData.isDeleted = false;
        RequestData.isGroupUpdateMessage = false;
        RequestData.SentByHash = LoginUserIdHashref.current;
        RequestData.IsRemove = false;
        RequestData.chanelId = chatSelection.AllData.isGroupChat ? props.MerchantChannelId.toString() : chatSelection.AllData.chanelId === "" || chatSelection.AllData.chanelId === undefined ? chatSelection.AllData.customerIdHash + props.ReactBusinessId.toString() + "" : chatSelection.AllData.chanelId;
        RequestData.messageType = "Customers" === ActiveLeftOption ? isCustomerChat ? VagaroConnectMessageType.CustomerMessageSentByCustomer : VagaroConnectMessageType.CustomerMessageSentByEmployee : VagaroConnectMessageType.EmployeeChatMessage;
        RequestData.isCustomerChat = "Customers" === ActiveLeftOption ? true : false;
        if (UserTextMarketingData?.current !== undefined && UserTextMarketingData?.current !== null && UserTextMarketingData?.current !== "") {
            RequestData.isAvalible = "Customers" === ActiveLeftOption ? UserTextMarketingData?.current.userInfo : "";
        } else {
            RequestData.isAvalible = "";
        }
        RequestData.isVcard = false;

        //changes for Group chat pending. Add members in group chat
        if ((isNewChat || newChat)) {
            RequestData.isGroupChat = false;
            RequestData.members = [];
        } else {
            RequestData.isGroupChat = chatSelection.AllData.isGroupChat;
            RequestData.members = chatSelection.AllData.members !== undefined && chatSelection.AllData.members !== null ? chatSelection.AllData.members : [];
        }

        var TempChatResponse = {
            messageId: "",
            tempMessageId: utils.generateCustomMessageID(),
            message: RequestData.message,
            messageDate: new Date(),
            sentByHash: LoginUserIdHash,
            fullName: RequestData.fullname,
            isNote: RequestData.isnote,
            isDeleted: RequestData.isDeleted,
            sentBy: RequestData.SenderId,
            isVcard: false
        };
        RequestData.tempMessageId = TempChatResponse.tempMessageId;
        if (isCustomerChat) {
            RequestData.conversationId = chatSelection.ConversationId;
            callBackChatConversation(TempChatResponse, currentConversetion)
        } else {
            if ((!isNewChat && !newChat) && callBackChatConversation) {
                callBackChatConversation(TempChatResponse, currentConversetion);
            }
        }

        // if (isCustomerChat && RequestData.conversationId === null) {
        //     connection.invoke("LeaveGroup", window.ReactBusinessId, LoginUserIdHash);
        // }
        return RequestData;
    }
    const TakeControlRequest = () => {
        const ActiveLeftOption = isCustomerChat ? "Customers" : $("a.leftside-menu_options.active").text().replace(/[0-9]/g, '');
        const RequestData = {};
        RequestData.conversationId = (isNewChat || newChat) ? null : chatSelection.ConversationId;
        RequestData.SenderId = props.ReactShopOwnerId || HeaderDetail.ShopOwnerId;
        RequestData.fullname = "Connect AI";
        RequestData.message = "";
        RequestData.MemberIds = customer === undefined || customer === null || customer === "" ? null : customer.current;
        RequestData.status = 0;
        RequestData.isnote = false;
        RequestData.attachments = [];
        RequestData.isMessageFromCustomer = false;
        RequestData.isDeleted = false;
        RequestData.isGroupUpdateMessage = true;
        RequestData.SentByHash = LoginUserIdHashref.current;
        RequestData.IsRemove = false;
        RequestData.chanelId = chatSelection.AllData.isGroupChat ? props.MerchantChannelId.toString() : chatSelection.AllData.chanelId === "" || chatSelection.AllData.chanelId === undefined ? chatSelection.AllData.customerIdHash + props.ReactBusinessId.toString() + "" : chatSelection.AllData.chanelId;
        RequestData.messageType = VagaroConnectMessageType.CustomerMessageSentByAI;
        RequestData.isCustomerChat = true;
        RequestData.connectionId = "";
        RequestData.DeviceID = navigator.userAgent
        if (UserTextMarketingData?.current !== undefined && UserTextMarketingData?.current !== null && UserTextMarketingData?.current !== "") {
            RequestData.isAvalible = "Customers" === ActiveLeftOption ? UserTextMarketingData?.current.userInfo : "";
        } else {
            RequestData.isAvalible = "";
        }
        if ((isNewChat || newChat)) {
            RequestData.isGroupChat = false;
            RequestData.members = [];
        } else {
            RequestData.isGroupChat = chatSelection.AllData.isGroupChat;
            RequestData.members = chatSelection.AllData.members !== undefined && chatSelection.AllData.members !== null ? chatSelection.AllData.members : [];
        }
        RequestData.tempMessageId = utils.generateCustomMessageID();
        return RequestData;
    }
    const UserTypingMessageRequest = () => {
        const RequestData = {};
        RequestData.conversationId = chatSelection?.ConversationId ? chatSelection.ConversationId : null;
        RequestData.UserId = props.LoginUserIdHash;
        RequestData.FullName = ReactFirstName + " " + ReactLastName;
        RequestData.message = "User Typing";
        RequestData.MessageId = props.LoginUserIDV2;
        RequestData.MessageFromCustomer = isCustomerChat ? true : false;
        RequestData.chanelId = chatSelection.AllData.isGroupChat ? props.MerchantChannelId.toString() : chatSelection.AllData.chanelId === "" || chatSelection.AllData.chanelId === undefined ? chatSelection.AllData.customerIdHash + props.ReactBusinessId.toString() + "" : chatSelection.AllData.chanelId;
        RequestData.messageType = VagaroConnectMessageType.UserTyping;
        if (ReactProfileImage && ReactProfileImage !== "") {
            RequestData.Image = initialData.ReactCdnUrl + "Original/" + ReactProfileImage
        }
        RequestData.Data = "";
        return RequestData;
    }
    const UserTypingMessage = async () => {
        let MemberIsexists = false;
        if (chatSelection.IsFromGroup === true && CheckLoginUserIsRemovedFromChat(chatSelection.AllData) === true) {
            MemberIsexists = true;
        }

        if (cancelTokenRef.current) {
            cancelTokenRef.current.cancel('Operation canceled due to new request.');
        }

        // Create a new cancel token for this request
        const newCancelToken = axios.CancelToken.source();
        cancelTokenRef.current = newCancelToken;

        const access_token = await useGenerateToken(dispatch);
        if (connection.state === "Connected" && MemberIsexists === false) {
            await AsyncAjaxPost(
                VagaroAPIEnum.UserTyping,
                UserTypingMessageRequest(),
                access_token,
                function onSuccess(data) {
                    try {
                        if (data != null && data.data != null && data.data.data != null) {
                        }
                    } catch (error) {
                        utils.appInsightTrack(
                            props.ReactBusinessId,
                            props.ReactUserID,
                            "Brodcast Massage User Typing",
                            UserTypingMessageRequest(),
                            error
                        );
                    }
                },
                function OnError(data) {
                    utils.appInsightTrack(
                        props.ReactBusinessId,
                        props.ReactUserID,
                        "Brodcast Massage User Typing",
                        UserTypingMessageRequest(),
                        data
                    );
                },
                true,
                newCancelToken?.token
            );
        }
    }

    // const BroadCastTakeControlRequest = () => {
    //     const RequestData = {};
    //     const currentTime = new Date();
    //     currentTime.setDate(currentTime.getDate() + 1); 
    //     RequestData.conversationId = chatSelection?.ConversationId ? chatSelection.ConversationId : null;
    //     RequestData.UserId = props.LoginUserIdHash;        
    //     RequestData.chanelId = chatSelection.AllData.isGroupChat ? props.MerchantChannelId.toString() : chatSelection.AllData.chanelId === "" || chatSelection.AllData.chanelId === undefined ? chatSelection.AllData.customerIdHash + props.ReactBusinessId.toString() + "" : chatSelection.AllData.chanelId;
    //     RequestData.messageType = VagaroConnectMessageType.TakeControl;
    //     RequestData.Date_Time = currentTime;
    //     return RequestData;
    // }

    // const BroadCastTakeControl = async () => {
    //     let MemberIsexists = false;
    //     if (chatSelection.IsFromGroup === true && CheckLoginUserIsRemovedFromChat(chatSelection.AllData) === true) {
    //         MemberIsexists = true;
    //     }
    //     const access_token = await useGenerateToken(dispatch);
    //     if (connection.state === "Connected" && MemberIsexists === false) {
    //         await AsyncAjaxPost(
    //             VagaroAPIEnum.TakeControl,
    //             BroadCastTakeControlRequest(),
    //             access_token,
    //             function onSuccess(data) {
    //                 try {
    //                     if (data != null && data.data != null && data.data.data != null) {
    //                     }
    //                 } catch (error) {
    //                     utils.appInsightTrack(
    //                         props.ReactBusinessId,
    //                         props.ReactUserID,
    //                         "BroadCast Take Control",
    //                         BroadCastTakeControlRequest(),
    //                         error
    //                     );
    //                 }
    //             },
    //             function OnError(data) {
    //                 utils.appInsightTrack(
    //                     props.ReactBusinessId,
    //                     props.ReactUserID,
    //                     "BroadCast Take Control",
    //                     BroadCastTakeControlRequest(),
    //                     data
    //                 );
    //             },
    //             true
    //         );
    //     }
    // }

    const SentMessage = async (isFromEnter, evt, data) => {
        const ActiveLeftOption = isCustomerChat ? "Customers" : $("a.leftside-menu_options.active").text().replace(/[0-9]/g, '');
        if (cancelTokenRef.current) {
            cancelTokenRef.current.cancel('Operation canceled due to massage Send.');
        };
        utils.EventCall('StopTypingBrodcast');
        if (connection.state !== "Connected") {
            if (isFromEnter) {
                evt.stop();
                data.stopPropagation();
                data.preventDefault();
            }
            return ReactDOM.render(
                <Fragment>
                    <SuccessAlert
                        alertType={"warning"}
                        strMessage={`Please wait while the chat initializes.`}
                        displaytime={2000}
                    />
                </Fragment>,
                document.getElementById("OpenDialog1")
            );
        }
        if (FileUploadStatus.current) {
            return;
        }
        let MemberIsexists = false;
        var Rights = CommonMethod.CheckModifyRights() || false;
        if (props.isInternet.current === false) {
            return
        }
        if (chatSelection.IsFromGroup === true && CheckLoginUserIsRemovedFromChat(chatSelection.AllData) === true) {
            MemberIsexists = true;
        }
        if ("Customers" === ActiveLeftOption && Rights === false && !isCustomerChat) {
            if (isFromEnter) {
                evt.stop();
                data.stopPropagation();
                data.preventDefault();
            }
            utils.ShowAlert("Alert", "You Don't Have Rights", "Close");
            return;
        }
        else {
            if (!isCustomerChat && (isNewChat || newChat) && (customer.current === undefined || customer.current === null || customer.current === "")) {
                if (isFromEnter) {
                    evt.stop();
                    data.stopPropagation();
                    data.preventDefault();
                }
                return ReactDOM.render(
                    <Fragment>
                        <SuccessAlert
                            alertType={"warning"}
                            strMessage={`Please Select Any ${LeftOption}`}
                            displaytime={2000}
                        />
                    </Fragment>,
                    document.getElementById("OpenDialog1")
                );
                //utils.ShowAlert("Alert", `Please Select Any ${LeftOption}`, "Close");
            }
            if (!newChat) {
                if ((chatSelection.IsBlocked === true || (chatSelection.IsFromGroup === true && MemberIsexists === true && !isNewChat))) {
                    if (isFromEnter) {
                        evt.stop();
                        data.stopPropagation();
                        data.preventDefault();
                    }

                    return ReactDOM.render(
                        <Fragment>
                            <SuccessAlert
                                alertType={"warning"}
                                strMessage={(chatSelection.IsFromGroup === true && MemberIsexists === true) ? `You can not send message` : isCustomerChat ? `${ReactBusinessName} is currently not accepting messages at this time. Please try calling the business directly.` : `You can not send message to blocked User`}
                                displaytime={2000}
                            />
                        </Fragment>,
                        document.getElementById("OpenDialog1")
                    );

                }
                if (chatSelection.AllData?.isBusinessExcluded === true) {
                    if (isFromEnter) {
                        evt.stop();
                        data.stopPropagation();
                        data.preventDefault();
                    }

                    return ReactDOM.render(
                        <Fragment>
                            <SuccessAlert
                                alertType={"warning"}
                                strMessage={`You can not send message`}
                                displaytime={2000}
                            />
                        </Fragment>,
                        document.getElementById("OpenDialog1")
                    );
                }
            }
            var SendData = EditorObject.current.getData();
            var SendDataAttachment = SendData;
            var UplodStatus = 0;
            utils.EventCall(`FileUplodStatus${ckeditortype}`, (val) => {
                UplodStatus = val
            });
            if (UplodStatus > 0) {
                if (evt !== undefined && evt !== null && evt !== "") {
                    evt.stop();
                    data.stopPropagation();
                    data.preventDefault();
                }
                setshowFileUploadProgressMessage(true);
                return;
            }
            if (document.getElementById("Video-content1") && document.getElementById("Video-content1")?.querySelectorAll('video').length > 0) {
                SendDataAttachment += document.getElementById("Video-content1")?.querySelectorAll('video').length;
            }
            if (SendDataAttachment === undefined || SendDataAttachment === null || SendDataAttachment === "") {
                if (isFromEnter) {
                    evt.stop();
                    data.stopPropagation();
                    data.preventDefault();
                }
                EditorObject.current.setData("");
                setshowErrorMessage(true);
                if (!isAndroidIOSTabIpad()) {
                    EditorObject.current.focus();
                }
                return false;
            }

            if (UserTextMarketingData !== undefined && UserTextMarketingData !== null && UserTextMarketingData !== "") {
                if ((chatSelection.AllData.isCustomerOnline === false && UserTextMarketingData.current?.isTextMessagingActivated === false && UserTextMarketingData.current?.numberStatus !== 5 && "Customers" === ActiveLeftOption && !isCustomerChat && !NoteObject.current) || (UserTextMarketingData.current?.isTextMessagingActivated === false && "Customers" === ActiveLeftOption && !isCustomerChat && !NoteObject.current && (isNewChat || newChat))) {
                    if (evt !== undefined && evt !== null && evt !== "") {
                        evt.stop();
                        data.stopPropagation();
                        data.preventDefault();
                    }
                    return ReactDOM.render(
                        <CommonMessagepopup
                            data={UserTextMarketingData.current}
                            isFromConnectApp={isFromConnectApp}
                            isAndroidIOSMobile={isAndroidIOSMobile}
                            isPlanActive={false}
                        />,
                        document.getElementById("OpenDialog1")
                    );

                }
                else {
                    if ((UserTextMarketingData.current?.isTextMessagingActivated === true && "Customers" === ActiveLeftOption && !isCustomerChat && chatSelection.AllData.isCustomerOnline === false && !NoteObject.current) || (UserTextMarketingData.current?.isTextMessagingActivated === true && "Customers" === ActiveLeftOption && !isCustomerChat && !NoteObject.current && (isNewChat || newChat))) {
                        if (UserTextMarketingData.current?.isNumberApproved === false || UserTextMarketingData.current?.cell === "" || UserTextMarketingData.current?.isNotifyBySMS === false) {
                            if (UserTextMarketingData?.current.isNumberApproved === false && UserTextMarketingData?.current.isTextMessagingActivated === true && UserTextMarketingData.current?.numberStatus !== 5) {
                                setTextmarketingalerts(1);
                                textmarketingalerts.current = 1;
                                return;
                            }
                            else if (UserTextMarketingData?.current.cell === "" && UserTextMarketingData?.current.isTextMessagingActivated === true && UserTextMarketingData.current?.numberStatus !== 5) {
                                setTextmarketingalerts(2);
                                textmarketingalerts.current = 2;
                                return;
                            }
                            else if (UserTextMarketingData?.current.isNotifyBySMS === false && UserTextMarketingData?.current.isTextMessagingActivated === true && UserTextMarketingData.current?.numberStatus !== 5) {
                                setTextmarketingalerts(3);
                                textmarketingalerts.current = 3;
                                return;
                            }
                            else if (UserTextMarketingData?.current.numberStatus === 5 && UserTextMarketingData?.current.isTextMessagingActivated === true) {
                                setTextmarketingalerts(4);
                                textmarketingalerts.current = 4;
                                return;
                            }
                            else {
                                setTextmarketingalerts(0);
                                textmarketingalerts.current = 0;
                            }
                            if (evt !== undefined && evt !== null && evt !== "") {
                                evt.stop();
                                data.stopPropagation();
                                data.preventDefault();
                                return;
                            }
                            else {
                                return;
                            }
                        }
                    }
                }
            }


            let CharacterLimit = SendData.replace(/<[^>]*>|&nbsp;/g, '');
            var buttonTextValue = document.getElementById('sendbutton' + ckeditortype).text === "SMS";


            handleKeydown("", "", EditorObject.current, async returnData => {
                if (returnData) {
                    if (evt !== undefined && evt !== null && evt !== "") {
                        evt.stop();
                        data.stopPropagation();
                        data.preventDefault();
                        if (!isAndroidIOSTabIpad()) {
                            setFocusToEnd();
                        }
                    }
                    return;
                }
                else {
                    var Maximumlength = (("Customers" === ActiveLeftOption && chatSelection.AllData.isCustomerOnline === false) || buttonTextValue) ? 1000 : 2000;
                    if (CharacterLimit.length > Maximumlength) {
                        setmaxLenghtAlert(true);
                        if (evt !== undefined && evt !== null && evt !== "") {
                            evt.stop();
                            data.stopPropagation();
                            data.preventDefault();
                        }
                        return;
                    }
                    if (SendDataAttachment === undefined || SendDataAttachment === null || SendDataAttachment === "") {
                        if (isFromEnter) {
                            evt.stop();
                            data.stopPropagation();
                            data.preventDefault();
                        }
                        EditorObject.current.setData("");
                        setshowErrorMessage(true);
                        if (!isAndroidIOSTabIpad()) {
                            EditorObject.current.focus();
                        }
                        return false;
                    }
                    let count = ImageCount(EditorObject.current.getData());
                    if (count > 10) {
                        return ReactDOM.render(
                            <Fragment>
                                <SuccessAlert
                                    alertType={"warning"}
                                    strMessage={"Please select up to 10 files."}
                                    displaytime={2000}
                                />
                            </Fragment>,
                            document.getElementById("OpenDialog1")
                        );
                        //utils.ShowAlert("Alert", "Please select up to 10 files.", "Close");
                    }
                    let RequestData = SendMessageRequest(isFromEnter, evt, data);
                    EditorObject.current.setData("");
                    removeConversationItemOnSend(LoginUserIdHash);
                    await RemoveImageBase64(RequestData, Data => {
                        window.onCkeditoerResize();
                        if (Data !== false) {
                            RequestData.message = Data;
                            const parser = new DOMParser();
                            const doc = parser.parseFromString(Data, 'text/html');
                            const videos = document.getElementById("Video-content1")?.querySelectorAll('video');
                            const images = doc.querySelectorAll('img');
                            if (images.length > 0) {
                                images.forEach(img => {
                                    const FileUrl = img.getAttribute('src');
                                    const FileName = img.getAttribute('alt');
                                    RequestData.attachments.push({ FileUrl, FileName });
                                });
                            }
                            if (videos.length > 0) {
                                videos.forEach(video => {
                                    const Type = video.dataset.type;
                                    const FileUrl = video.dataset.url;
                                    const FileName = video.dataset.name;
                                    RequestData.attachments.push({ Type, FileUrl, FileName });
                                });
                            }
                            ClearEditor();
                            SendMessage(RequestData, EditorObject.current, sendConvertion.current, false, isAndroidIOSMobile);
                            if (NoteObject.current) {
                                onClickOfNotes();
                            }
                        }
                    });
                }
            })
        }
    }
    window.EditorFunction = (Message, Link, Place) => {
        if (Message !== undefined && EditorObject.current) {
            const Linkitem = (Link === undefined || Link === "") ? " " : Link
            const Editoer = EditorObject.current.getData();
            const LinkHtml = `${Message}<a href="${Linkitem}">${Linkitem}</a>`;
            const viewFragment = EditorObject.current.data.processor.toView(LinkHtml);
            const modelFragment = EditorObject.current.data.toModel(viewFragment);
            EditorObject.current.model.insertContent(modelFragment);
            if (!isAndroidIOSTabIpad()) {
                EditorObject.current.focus();
            }
            setEditorData(Editoer + LinkHtml);
        }
    };

    const setFocusToEnd = () => {
        const editor = EditorObject.current;
        if (editor) {
            const model = editor.model;
            const root = model.document.getRoot();
            model.change(writer => {
                const endPosition = writer.createPositionAt(root, 'end');
                const range = writer.createRange(endPosition, endPosition);
                writer.setSelection(range);
            });
            editor.editing.view.focus();
        }
    };

    window.onCkeditoerResize = () => {
        if (!$(".modal-dialog").is(":visible") && isAndroidIOSMobile === false && !isAndroidIOSTabIpad()) {
            setTimeout(() => {
                EditorObject.current.focus();
            }, 50);
        }

    };

    window.CheckcustomerOnline = (CuatomerIsOnline = false) => {
        if (CuatomerIsOnline) {
            setTextmarketingalerts(0);
            textmarketingalerts.current = 0;
        }
        else {
            if (UserTextMarketingData?.current !== undefined && UserTextMarketingData?.current !== null && UserTextMarketingData?.current !== "") {
                if (UserTextMarketingData?.current.isNumberApproved === false && UserTextMarketingData?.current.isTextMessagingActivated === true && UserTextMarketingData?.current.numberStatus !== 5) {
                    setTextmarketingalerts(1);
                    textmarketingalerts.current = 1;
                }
                else if (UserTextMarketingData?.current.cell === "" && UserTextMarketingData?.current.isTextMessagingActivated === true && UserTextMarketingData?.current.numberStatus !== 5) {
                    setTextmarketingalerts(2);
                    textmarketingalerts.current = 2;
                }
                else if (UserTextMarketingData?.current.isNotifyBySMS === false && UserTextMarketingData?.current.isTextMessagingActivated === true && UserTextMarketingData?.current.numberStatus !== 5) {
                    setTextmarketingalerts(3);
                    textmarketingalerts.current = 3;
                }
                else if (UserTextMarketingData?.current.numberStatus === 5 && UserTextMarketingData?.current.isTextMessagingActivated === true) {
                    setTextmarketingalerts(4);
                    textmarketingalerts.current = 4;
                }
                else {
                    setTextmarketingalerts(0);
                    textmarketingalerts.current = 0;
                }
            }
        }
    }

    window.onTextMarketingAlerts = (TextmarketingData, isUpdate = false) => {
        textMarketingData.current = TextmarketingData;
        const ActiveLeftOption = isCustomerChat ? "Customers" : $("a.leftside-menu_options.active").text().replace(/[0-9]/g, '');
        if ((TextmarketingData !== undefined && TextmarketingData !== null && TextmarketingData !== "" && !isCustomerChat && "Customers" === ActiveLeftOption && chatSelection.AllData.isCustomerOnline === false && isUpdate === false) || ((TextmarketingData !== undefined && TextmarketingData !== null && TextmarketingData !== "" && !isCustomerChat && "Customers" === ActiveLeftOption && isUpdate === false) && (isNewChat || newChat))) {
            if (TextmarketingData.isNumberApproved === false && TextmarketingData.isTextMessagingActivated === true && TextmarketingData.numberStatus !== 5) {
                setTextmarketingalerts(1);
                textmarketingalerts.current = 1;
            }
            else if (TextmarketingData.cell === "" && TextmarketingData.isTextMessagingActivated === true && TextmarketingData.numberStatus !== 5) {
                setTextmarketingalerts(2);
                textmarketingalerts.current = 2;
            }
            else if (TextmarketingData.isNotifyBySMS === false && TextmarketingData.isTextMessagingActivated === true && TextmarketingData.numberStatus !== 5) {
                setTextmarketingalerts(3);
                textmarketingalerts.current = 3;
            }
            else if (TextmarketingData.numberStatus === 5 && TextmarketingData.isTextMessagingActivated === true) {
                setTextmarketingalerts(4);
                textmarketingalerts.current = 4;
            }
            else {
                setTextmarketingalerts(0);
                textmarketingalerts.current = 0;
            }
        }
        else {
            setTextmarketingalerts(0);
            textmarketingalerts.current = 0;
        }
    }

    // window.top.CkeditoerResize = () => {
    //     setTimeout(() => {
    //         EditorObject.current.focus();
    //     }, 50);
    // };

    window.onScrollCloseItems = () => {
        let emojiElement = document.getElementById("Emoji2");
        let ghippyElement = document.getElementById("Ghippy2");
        let emojiClass = document.getElementsByClassName("EmojiPickerReact");
        let ghippyClass = document.getElementsByClassName("gifPluginBlock");

        if ((emojiElement || ghippyElement) && emojiClass.length > 0) {
            CalculateHeight('Emoji2', 'EmojiPickerReact');
        } else if ((emojiElement || ghippyElement) && ghippyClass.length > 0) {
            CalculateHeight('Ghippy2', 'gifPluginBlock');
        }
    }

    window.SetEditorValueFromLocalStorage = () => {
        GetLocalStorageForConversation();
    }

    const UploadImageRequest = (conversationId, src, fileName) => {
        const RequestData = {};
        RequestData.ConversationId = conversationId;
        RequestData.FileContent = src;
        RequestData.UploadFileName = fileName;
        return RequestData;
    }

    const ImageUploadOnDrive = async (src, fileName, onComplete, onfailer) => {
        const access_token = await useGenerateToken(dispatch);
        await AsyncAjaxPost(
            VagaroAPIEnum.UploadImage,
            UploadImageRequest(chatSelection.ConversationId, src, fileName),
            access_token,
            function onSuccess(data) {
                try {
                    if (data != null && data.data != null && data.data.data != null) {
                        if (onComplete) {
                            onComplete(data);
                        }
                        // return data.data.data;
                    }
                    else {
                        onfailer(data);
                        utils.appInsightTrack(
                            props.ReactBusinessId,
                            props.ReactUserID,
                            "Image Upload on Drive",
                            UploadImageRequest(chatSelection.ConversationId, src, fileName),
                            data != null && data.data != null ? data.data : ""
                        );
                    }
                } catch (error) {
                    onfailer(data);
                    utils.appInsightTrack(
                        props.ReactBusinessId,
                        props.ReactUserID,
                        "Image Upload on Drive",
                        UploadImageRequest(chatSelection.ConversationId, src, fileName),
                        error
                    );
                }
            },
            function OnError(data) {
                onfailer(data);
                utils.appInsightTrack(
                    props.ReactBusinessId,
                    props.ReactUserID,
                    "Image Upload on Drive",
                    UploadImageRequest(chatSelection.ConversationId, src, fileName),
                    data
                );
            },
            true
        );
    }

    const GetVagaroLinks = async () => {
        const access_token = await useGenerateToken(dispatch);
        await AsyncAjaxGet(
            VagaroAPIEnum.GetVagaroLinks,
            access_token,
            function onSuccess(data) {
                try {
                    if (data != null && data.data != null && data.data.data != null) {
                        setVagaroLinks(data.data.data);
                    }
                    else {
                        utils.appInsightTrack(
                            props.ReactBusinessId,
                            props.ReactUserID,
                            "Vagaro Links",
                            "",
                            data != null && data.data != null ? data.data : ""
                        );
                    }
                } catch (error) {
                    utils.appInsightTrack(
                        props.ReactBusinessId,
                        props.ReactUserID,
                        "Vagaro Links",
                        "",
                        error
                    );
                }
            },
            function OnError(data) {
                utils.appInsightTrack(
                    props.ReactBusinessId,
                    props.ReactUserID,
                    "Vagaro Links",
                    "",
                    data
                );
            },
            false
        );
    }



    const RemoveImageAttribute = (SendData) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(SendData, 'text/html');

        const images = doc.querySelectorAll('img');
        images.forEach((img) => {
            // const alt = img.getAttribute('alt');
            // if (alt !== 'Gif') {
            img.removeAttribute('width');
            img.removeAttribute('height');
            // }
        });

        // Convert the modified DOM back to a string
        const updatedHtmlString = doc.body.innerHTML;
        var modifiedHtmlString = updatedHtmlString.replace(/(<img\b[^>]*>)\s*(<br>|&nbsp;)*\s*(?=<img\b)/gi, '$1');
        modifiedHtmlString = updatedHtmlString.replace(/<span class="restrictedWordHighlight">(.*?)<\/span>/gi, '$1');
        return modifiedHtmlString;

    }

    const addEmoji = (e) => {
        if (e.unified !== undefined) {
            let sym = e.unified.split("-");
            let codesArray = [];
            sym.forEach((el) => codesArray.push("0x" + el));
            let emoji = String.fromCodePoint(...codesArray);
            const viewFragment = EditorObject.current.data.processor.toView(emoji);
            const modelFragment = EditorObject.current.data.toModel(viewFragment);
            EditorObject.current.model.insertContent(modelFragment);
            if (!isAndroidIOSTabIpad()) {
                EditorObject.current.focus();
            }
            setShowEmojis(!showEmojis)
            if (!isAndroidIOSTabIpad()) {
                EditorObject.current.focus();
            }
        }
        else {
            setShowEmojis(!showEmojis)
        }
    };


    useEffect(() => {
        if (EditorObject.current) {
            setFileDropdown(false);
            setShowGhipy(false);
            setShowDropdown(false);
            setShowEmojis(false);
            setHeight('305px');
            setOpenKeys([]);
            GetLocalStorageForConversation();
            if (NoteObject.current) {
                onClickOfNotes();
            }
            if ("Employee" === LeftOption) {
                $(`.ckeditor_${ckeditortype} .ck-toolbar__items .cke_button_note`).hide();
                $(`.ckeditor_${ckeditortype} .ck-toolbar__items .cke_button_link`).hide();
                $(".cke_button_icon-note").hide();
                $(".cke_button_icon-link").hide();
            } else {
                $(`.ckeditor_${ckeditortype} .ck-toolbar__items .cke_button_note`).show();
                $(`.ckeditor_${ckeditortype} .ck-toolbar__items .cke_button_link`).show();
                $(".cke_button_icon-note").show();
                $(".cke_button_icon-link").show();
            }
            //If customer is online button text should be Send and customer is offline button text should be SMS.
            if ("Customer" === LeftOption) {
                //     // isonline.current = chatSelection.AllData.isCustomerOnline;
                //     if (chatSelection.AllData.isCustomerOnline === false && smsavail.current ) {
                //         $(`#sendbutton${ckeditortype}`).text("SMS");
                //     }
                //     else {
                //         $(`#sendbutton${ckeditortype}`).text("Send");
                //     }
            }
            else {
                $(`#sendbutton${ckeditortype}`).text("Send");
            }
            // Start mention realted Changes -----------------------
            if (chatSelection?.AllData?.isGroupChat === false) {
                setusers([
                    {
                        id: chatSelection?.AllData.customerIdHash,
                        name: chatSelection?.AllData?.firstName + " " + chatSelection?.AllData?.lastName,
                        cdnUrl: chatSelection?.cdnUrl,
                        photo: chatSelection?.Image,
                        cell: chatSelection?.AllData?.cell,
                        firstName: chatSelection?.AllData?.firstName,
                        lastName: chatSelection?.AllData?.lastName,
                    }
                ])

            } else {
                const userlist = chatSelection?.AllData?.Allmember?.filter(item => LoginUserIdHash !== item?.memberIdHash) || [];
                setusers(userlist.map((arr) => ({
                    id: arr.memberIdHash,
                    name: arr.firstName + " " + arr.lastName,
                    cdnUrl: arr.cdnUrl,
                    photo: arr.photo,
                    cell: arr.cell,
                    firstName: arr.firstName,
                    lastName: arr.lastName,
                })))
            }
            // END mention realted Changes -----------------------

            setTimeout(() => {
                if (isFromAppResumeCallBack?.current === undefined || isFromAppResumeCallBack?.current === false) {
                    if (!isAndroidIOSTabIpad()) {
                        EditorObject.current.focus();
                    }
                }
            }, 200);
        }
    }, [chatSelectionID, chatSelection.AllData.isCustomerOnline]);

    const handleOutsideClick = (event) => {

        if (ref.current && (!ref.current.contains(event.target) || (typeof event?.target?.className === 'string' && (event.target.className.includes("fa-solid fa-plus") || event.target.className.includes("mobile-contextmenu show dropdown"))))) {
            setFileDropdown(false);
            setShowGhipy(false);
            setShowDropdown(false);
            setShowEmojis(false);
            setHeight('305px');
            setOpenKeys([]);
            $(`.ckeditor_${ckeditortype} .cke_button_icon-upload`).removeClass('active');
            $(`.ckeditor_${ckeditortype} .cke_button__emojipanel_icon`).removeClass('active');
            $(`.ckeditor_${ckeditortype} .cke_button_icon-gif`).removeClass('active');
            $(`.ckeditor_${ckeditortype} .cke_button_icon-link`).removeClass('active');
        }
    };
    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);
        return () => { document.removeEventListener('click', handleOutsideClick); };
    }, []);

    useEffect(() => {
        if (!isCustomerChat) {
            GetVagaroLinks();
        }
        const ActiveLeftOption = isCustomerChat ? "Customers" : $("a.leftside-menu_options.active").text().replace(/[0-9]/g, '');
        if ("Customers" !== ActiveLeftOption) {
            setTextmarketingalerts(0);
            textmarketingalerts.current = 0;
        }

        if (EditorObject.current !== "") {
            GetLocalStorageForConversation();
        }
        setbadwordAlert(false);
        // GetBadContainWords();

        return () => { setVagaroLinks(false) }
    }, [chatSelectionID]);

    const BlockUseralert = () => {
        let MemberIsexists
        if (chatSelection.IsFromGroup === true) {
            MemberIsexists = chatSelection.AllData.members.filter(item => item.memberIdHash === LoginUserIdHash);
        }
        if (chatSelection.IsBlocked === true || (chatSelection.IsFromGroup === true && MemberIsexists.length === 0 && !isNewChat)) {
            return ReactDOM.render(
                <Fragment>
                    <SuccessAlert
                        alertType={"warning"}
                        strMessage={(chatSelection.IsFromGroup === true && MemberIsexists.length === 0) ? `You Can not send message` : isCustomerChat ? `${ReactBusinessName} is currently not accepting messages at this time. Please try calling the business directly.` : `You can not send message to blocked User`}
                        displaytime={2000}
                    />
                </Fragment>,
                document.getElementById("OpenDialog1")
            );
        }
        return true;
    }

    const [openKeys, setOpenKeys] = useState([]);
    const [OpenSubMenu, setOpenSubMenu] = useState(false);
    const toggleKey = (key) => {
        setOpenKeys(prevOpenKeys => ({
            ...Object.fromEntries(Object.keys(prevOpenKeys).map(k => [k, k === key ? !prevOpenKeys[k] : false])),
            [key]: !prevOpenKeys[key]
        }));
        setTimeout(() => {
            document.getElementById(key)?.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }, 10);
    };

    const CalculateHeight = (iconId, iconClass) => {
        // if (expandMinimizeShow !== 2 && !isAndroidIOSMobile) {
        let checkIedit = document.getElementById(iconId);
        if (checkIedit !== undefined && checkIedit !== "" && checkIedit !== null) {
            var dropdownTop = $(`#${iconId}`).offset().top + $(`#${iconId}`).outerHeight();
            var windowHeight = $(window).height();
            var dropdownHeight = $(`.${iconClass}`).outerHeight();
            if (windowHeight - dropdownTop < dropdownHeight) {
                $(`.${iconClass}`).css("top", $(`#${iconId}`).offset().top - dropdownHeight);
                $(`.${iconClass}`).css("left", $(`#${iconId}`).offset().left);
                $(`.${iconClass}`).css("position", "fixed");
                $(`.${iconClass}`).css("bottom", "inherit");
                $(`.${iconClass}`).css("transition", "none");
                $(`.${iconClass}`).css("animation", "none")


            } else {
                if (dropdownHeight !== undefined && dropdownHeight !== null && dropdownHeight !== "") {
                    $(`.${iconClass}`).css("top", $(`#${iconId}`).offset().top + $(`#${iconId}`).outerHeight());
                    $(`.${iconClass}`).css("left", $(`#${iconId}`).offset().left);
                    $(`.${iconClass}`).css("position", "fixed");
                    $(`.${iconClass}`).css("bottom", "inherit");
                    $(`.${iconClass}`).css("transition", "none");
                    $(`.${iconClass}`).css("animation", "none");

                }
            }
        }
        // }
    }
    const UpgradNow = () => {
        return ReactDOM.render(
            <Fragment>
                <SuccessAlert
                    alertType={"warning"}
                    strMessage={"Under development."}
                    displaytime={2000}
                />
            </Fragment>,
            document.getElementById("OpenDialog1")
        );
    }

    const OpenCustomerEditPopup = () => {
        if (!ManageCustomerViewRights || !ManageCustomerModifyRights) {
            return ReactDOM.render(
                <Fragment>
                    <SuccessAlert
                        alertType={"warning"}
                        strMessage={"You Don't Have Rights."}
                        displaytime={3000}
                    />
                </Fragment>,
                document.getElementById("OpenDialog1")
            );
        }
        const CustId = customer?.current !== undefined && customer?.current !== null && customer?.current !== "" ? customer?.current : chatSelection.Id
        if (!isFromConnectApp) {
            window.parent.postMessage('{"eventType": "openCustomerEditProfile","Data": "' + CustId + '"}', '*');
        } else if (isFromConnectApp) {
            utils.CallBackGivenToMobile(MobileCallBackAction.CustomerProfile, { "customerId": CustId, "isNumberUpdate": true })
        }
    }

    const OpenRestrictedPopup = () => {
        $("body").addClass("ris-popup");

        return ReactDOM.render(
            <CommonMessagepopup
                data={""}
                isFromConnectApp={isFromConnectApp}
                isAndroidIOSMobile={isAndroidIOSMobile}
                isRestricted={true}
            />,
            document.getElementById("OpenDialog1")
        );
    }

    return (
        <Fragment>
            <div style={{ display: (AIControl && !newChat && !isNewChat) ? "none" : "block" }}>
                <div ref={ref}>
                    {showErrorMessage &&
                        <div className='typeMsgText'>Please type a message....</div>
                    }
                    {showFileUploadProgressMessage &&
                        <div className='typeMsgText'>Please wait while uploading files....</div>
                    }
                    {ckeditortype === 1 && chatSelection.IsBlocked !== true &&
                        <HandleBrodcastMassage inputValue={editorData} broadcastTyping={() => { UserTypingMessage() }} />
                    }
                    {maxLenghtAlert && <div className='typeMsgText'>The maximum character limit allowed for a message is {(("Customers" === (isCustomerChat ? "Customers" : $("a.leftside-menu_options.active").text().replace(/[0-9]/g, '')) && chatSelection.AllData.isCustomerOnline === false) || document.getElementById('sendbutton1').text === "SMS") ? 1000 : 2000} characters.</div>}
                    {isTextmarketingalerts === 1 ? <div className='alert-text'>It may take up to 48 hours for your number to be approved.</div> : isTextmarketingalerts === 2 ? <div className='alert-text'>Customer does not have a phone number. <a href={false} onClick={() => OpenCustomerEditPopup()}>Add Number</a></div> : isTextmarketingalerts === 3 ? <div className='alert-text'>Customer has opted out of your text marketing. They can resume by texting &#x2018;Start&#x2019;.</div> : isTextmarketingalerts === 4 ? <div className='alert-text'>Your toll free number is in pending review, try sending after it is approved.</div> : <></>}
                    {badwordAlert && <div className='restricted-alert'>Remove restricted content to send message. <a href={false} onClick={() => OpenRestrictedPopup()}>Learn More</a></div>}

                    {!isCustomerChat && (
                        <HandleMentionDropdown showMention={mentionDropdown} Member={mentionList} IsFromGroup={chatSelection?.AllData?.isGroupChat} handleUserChange={handleMentionSelect} SearchText={mentionSearchtxt} isAndroidIOSMobile={isAndroidIOSMobile} setmentionDropdown={setmentionDropdown} ManageCustomerViewRights={ManageCustomerViewRights} ManageEmployeeModifyRights={ManageEmployeeModifyRights} ManageEmployeeViewRights={ManageEmployeeViewRights} isCustomerChat={isCustomerChat} newChat={newChat} isNewChat={isNewChat} expandMinimizeShow={expandMinimizeShow} ckeditortype={ckeditortype} isIOSMobile={isIOSMobile} />
                    )}
                    <div id='mobileBottomEditer' className="mobileListmenuWrap" >
                        {((isAndroidIOSMobile && !isAndroidIOSTabIpad()) || isCustomerChat) &&
                            <Dropdown className="mobileListmenu">
                                <div className="mobile-overlay-black"></div>
                                <Dropdown.Toggle variant="">
                                    <i class="fa-solid fa-plus"></i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={(e) => { handleFileCaptureCamera(); }} title='Emoji' className='useCamera'><i class="fa-regular fa-camera"></i>Use Camera</Dropdown.Item>
                                    <Dropdown.Item onClick={(e) => { CommonMethods.Actionclick('Vagaro_files' + ckeditortype) }} title='Attach File' className='photoLibrary'><i class="fa-regular fa-image"></i>Photo Library</Dropdown.Item>
                                    {/* <Dropdown.Item onClick={(e) => { UpgradNow(); }} title='GIF'><i class="fa-regular fa-folder"></i>Browse File</Dropdown.Item> */}
                                    {/* <Dropdown.Item onClick={(e) => { CommonMethods.Actionclick('Vagaro_files' + ckeditortype) }} title='Vagaro Link'><i class="fa-regular fa-cloud-arrow-up"></i>Vagaro Drive</Dropdown.Item> */}
                                    <Dropdown.Item onClick={(e) => { CommonMethods.Actionclick('Ghippy' + ckeditortype) }} title='Intarnal Note' className='sendGif'><i class="fa-regular fa-gif"></i>Send a GIF</Dropdown.Item>
                                    {"Employee" !== LeftOption && props.ReactBusinessId !== 226 && <Dropdown.Item onClick={(e) => { CommonMethods.Actionclick('Vagaro_Links' + ckeditortype) }} title='Intarnal Note' className='vagaroLinks'><i class="fa-regular fa-link"></i>Vagaro Links</Dropdown.Item>}
                                </Dropdown.Menu>
                            </Dropdown>}
                        {/* <Dropdown show={showMention}>
                    <Dropdown.Menu style={{position:'static', width:'20%'}}>
                    {filteredMembers?.length > 0 ? (
                        filteredMembers?.map((item) => (
                        <Dropdown.Item key={item?.id}>
                            {item?.firstName} {item?.lastName}
                        </Dropdown.Item>
                        ))
                    ) : (
                        <Dropdown.Item>No results found</Dropdown.Item>
                    )}
                    </Dropdown.Menu>
                </Dropdown> */}

                        <div className={(isAndroidIOSMobile && isIOSMobile === false) ? "ckeditor_" + ckeditortype + " showOutline" : "ckeditor_" + ckeditortype} >
                            <CKEditor
                                editor={ClassicEditor}

                                onReady={EditorOnLoad}

                                onChange={(event, editor) => EditorOnChange(event, editor)}
                                onBlur={EditorOnBlur}
                                config={{
                                    toolbar: [],
                                    placeholder: 'Write a message...',
                                    mediaEmbed: {
                                        previewsInData: true,
                                        removeProviders: ['youtube', 'afreecaTV', 'dailymotion'],
                                    },
                                }}


                                onFocus={EditorOnFocus}
                            />

                        </div>
                        {"Employee" !== LeftOption && !isAndroidIOSTabIpad() &&
                            <div className="iconchat" onClick={() => CommonMethods.Actionclick('note' + ckeditortype)}><i class="fa-regular fa-note-sticky"></i></div>
                        }
                    </div>
                    <div>
                        {showGhipy && (
                            <>
                                {(isAndroidIOSMobile && isFromConnectApp && ckeditortype === 1) ?
                                    <div className='gifPluginBlock'>
                                        <div id='giffyFormobile' className={`giffyWrap ${swipedUp ? 'swipeUp' : 'swipeDown'}`}
                                            {...handlers} style={{ height: height }}>
                                            <ReactGiphySearchbox
                                                apiKey="mrRNz3wAaeI5qw3S0Yweycp4lP8lBu79"
                                                onSelect={(item) => GippyOnClick(item)}
                                                searchPlaceholder="Search"
                                                autoFocus={isAndroidIOSTabIpad() ? false : isAndroidIOSMobile ? false : true}
                                                masonryConfig={[
                                                    { columns: 3, imageWidth: 117, gutter: 5 },
                                                    { mq: '280px', columns: 2, imageWidth: 121, gutter: 5 },
                                                    { mq: '360px', columns: 2, imageWidth: 161, gutter: 5 },

                                                    { mq: '370px', columns: 2, imageWidth: 166, gutter: 5 },
                                                    { mq: '375px', columns: 2, imageWidth: 169, gutter: 5 },
                                                    { mq: '390px', columns: 2, imageWidth: 176, gutter: 5 },
                                                    { mq: '412px', columns: 2, imageWidth: 188, gutter: 5 },
                                                    { mq: '414px', columns: 2, imageWidth: 188, gutter: 5 },
                                                    { mq: '767px', columns: 2, imageWidth: 365, gutter: 5 },
                                                    { mq: '769px', columns: 4, imageWidth: 77, gutter: 3 },
                                                    { mq: '768px', columns: 4, imageWidth: 70, gutter: 3 },
                                                    { mq: '1024px', columns: 4, imageWidth: 77, gutter: 3 },

                                                ]}

                                            />
                                        </div>
                                    </div>
                                    :
                                    <div className='gifPluginBlock'>
                                        <ReactGiphySearchbox
                                            apiKey="mrRNz3wAaeI5qw3S0Yweycp4lP8lBu79"
                                            onSelect={(item) => GippyOnClick(item)}
                                            searchPlaceholder="Search"
                                            autoFocus={isAndroidIOSTabIpad() ? false : isAndroidIOSMobile ? false : true}
                                            masonryConfig={isAndroidIOSMobile && isFromConnectApp && ckeditortype === 2 ?
                                                [
                                                    { columns: 3, imageWidth: 117, gutter: 5 },
                                                    { mq: '280px', columns: 2, imageWidth: 121, gutter: 5 },
                                                    { mq: '360px', columns: 2, imageWidth: 161, gutter: 5 },

                                                    { mq: '370px', columns: 2, imageWidth: 166, gutter: 5 },
                                                    { mq: '375px', columns: 2, imageWidth: 169, gutter: 5 },
                                                    { mq: '390px', columns: 2, imageWidth: 176, gutter: 5 },
                                                    { mq: '412px', columns: 2, imageWidth: 188, gutter: 5 },
                                                    { mq: '414px', columns: 2, imageWidth: 188, gutter: 5 },
                                                    { mq: '767px', columns: 2, imageWidth: 365, gutter: 5 },
                                                    { mq: '769px', columns: 4, imageWidth: 77, gutter: 3 },
                                                    { mq: '768px', columns: 4, imageWidth: 70, gutter: 3 },
                                                    { mq: '1024px', columns: 4, imageWidth: 77, gutter: 3 },

                                                ]
                                                :
                                                [
                                                    { columns: 3, imageWidth: 117, gutter: 5 },
                                                    { mq: '280px', columns: 4, imageWidth: 57, gutter: 5 },
                                                    { mq: '360px', columns: 4, imageWidth: 77, gutter: 5 },

                                                    { mq: '370px', columns: 4, imageWidth: 75, gutter: 5 },
                                                    { mq: '375px', columns: 4, imageWidth: 80, gutter: 5 },
                                                    { mq: '390px', columns: 4, imageWidth: 84, gutter: 5 },
                                                    { mq: '412px', columns: 4, imageWidth: 90, gutter: 5 },
                                                    { mq: '414px', columns: 4, imageWidth: 90, gutter: 5 },
                                                    // Customer window
                                                    { mq: '420px', columns: 4, imageWidth: 75, gutter: 5 },
                                                    { mq: '430px', columns: 4, imageWidth: 94, gutter: 5 },
                                                    { mq: '540px', columns: 4, imageWidth: 121, gutter: 5 },
                                                    { mq: '767px', columns: 4, imageWidth: 178, gutter: 5 },
                                                    { mq: '769px', columns: 4, imageWidth: 77, gutter: 3 },

                                                    { mq: '768px', columns: 4, imageWidth: 70, gutter: 3 },
                                                    { mq: '1024px', columns: 4, imageWidth: 77, gutter: 3 },

                                                ]}
                                        />
                                    </div>
                                }
                            </>

                        )}
                    </div>
                    <div>
                        {showEmojis && (
                            <div className='EmojiPickerReact'>
                                {/* <EmojiPicker skinTonesDisabled="true" height={350} width={382} searchPlaceholder={"Search Emoji"} onEmojiClick={(e) => addEmoji(e)}
                            /> */}
                                <Picker data={data} onEmojiSelect={(e) => addEmoji(e)} autoFocus={isAndroidIOSTabIpad() ? false : isAndroidIOSMobile ? false : true} navPosition={"bottom"} previewPosition={"none"} skinTonePosition={"none"} perLine={"8"} maxFrequentRows={"2"} theme={isCustomerChat ? "light" : document.querySelector('html').getAttribute('data-theme') === 'light' ? "light" : "dark"} />
                                {/* dynamicWidth={"true"} */}
                            </div>
                        )}
                    </div>
                    <div className='vcLinkWrapForMobile'>
                        {showDropdown && (props.ReactBusinessId !== 226) &&
                            <Dropdown show={showDropdown} className='vcDrop-wrap linkWrap mobile-contextmenu'>
                                <Dropdown.Toggle></Dropdown.Toggle>
                                <Dropdown.Menu className='vcDrop-group'>
                                    {vagaroLinks && vagaroLinks.length > 0 &&
                                        <div className='titleFormobile'>Vagaro Links</div>
                                    }
                                    <div className='vagaroLinkWrap'>
                                        {vagaroLinks && vagaroLinks.length > 0 && vagaroLinks.map((data, index) => {
                                            if (data.name !== undefined && data.name !== null && data.name !== "") {
                                                return (
                                                    <a className='vcDrop-group-item' key={index} href={() => false} onClick={() => CustomeLink(data.url, data)}>{data.name}</a>
                                                )
                                            } else {
                                                return (
                                                    <div key={index}>
                                                        {Object.keys(data).map((key, keyIndex) => {
                                                            if (Array.isArray(data[key]) && data[key].length > 0) {
                                                                return (
                                                                    <div key={keyIndex} id={key} className={openKeys[key] ? 'vcDrop-dropBlock active' : 'vcDrop-dropBlock'}>
                                                                        <div className='vcDrop-dropBlock-parent vcDrop-group-item' onClick={() => toggleKey(key)}>
                                                                            {key === 'dailyDeals' ? 'Daily Deal' : key === 'forms' ? 'Forms' : 'Promo Code'} <i className="fa-solid fa-caret-down float-end"></i>
                                                                        </div>
                                                                        <div className='vcDrop-dropBlock-childWrap'>
                                                                            {openKeys[key] && data[key].map((item, itemIndex) => (
                                                                                <a className="vcDrop-group-item vcDrop-dropBlock-child" key={itemIndex} href={() => false} onClick={() => CustomeLink(item.url, key)}>
                                                                                    <span>{item.name}</span>
                                                                                </a>
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            } else {
                                                                return null;
                                                            }
                                                        })}
                                                    </div>
                                                );
                                            }
                                        })}
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                        }
                    </div>
                    <div>
                        {showFileDropdown &&
                            <div show={showFileDropdown} className='vcDrop-wrap driveWrap'>
                                <div className='vcDrop-group'>
                                    <a className='vcDrop-group-item' onClick={() => handleFileChange() /*UnderDevelopment()*/} href={() => false}><i class="fa-regular fa-display"></i> Upload from this device</a>
                                    <a className='vcDrop-group-item' onClick={() => { setShow(true); setFileDropdown(false); }} href={() => false}><i class="fa-regular fa-cloud-arrow-up"></i> Vagaro Drive</a>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                {show && <VagaroDrive
                    {...props}
                    show={show}
                    setShow={setShow}
                    onUploadClickFromVagaroDrive={(dataevent) => onUploadClickFromVagaroDrive(dataevent)}
                />}
                {/* <input id='inputCamera' ref={InputCamera} onClick={(e) => { e.target.value = "" }} onChange={(e) => handelFile(e)} style={{ display: "none" }} type="file" capture="camera" accept="image/jpeg,image/png,image/gif,image/bmp,image/webp" multiple ></input> */}
                {/* <input id='inputFile' ref={InputPhotoLibrary} onClick={(e) => { e.target.value = "" }} onChange={(e) => handelFile(e)} style={{ display: "none" }} type="file" accept="image/jpeg,image/png,image/gif,image/bmp,image/webp" multiple ></input> */}
                {EditorObject.current &&
                    <FileUpload Type={ckeditortype} Editor={EditorObject} MassageDetail={messageDetails !== undefined && ckeditortype === 2 ? messageDetails : null} inputFile='inputFile' InputPhotoLibrary={InputPhotoLibrary} InputCamera={InputCamera} merchantId={merchantId} HandleImage={(e) => handelFile(e)} FileUploadProgress={setshowFileUploadProgressMessage} FileUplodStatus={setPendingFileToUpload} EditorOnChange={() => isFromConnectApp && EditorOnChange(null, EditorObject.current)} isFromConnectApp={isFromConnectApp} isIOSMobile={isIOSMobile} isCustomerChat={isCustomerChat} />
                }
            </div>
            <div style={{ display: isFromConnectApp && isAndroidIOSMobile ? (AIControl && !newChat && !isNewChat && messageConvertions !== undefined && Object.entries(messageConvertions).length !== 0) ? "block" : "none" : (AIControl && !newChat && !isNewChat) ? "block" : "none" }}>
                <TakeControl {...props} GetRequest={TakeControlRequest} />
            </div>

        </Fragment >
    );
};

const HandleBrodcastMassage = ({ inputValue, broadcastTyping }) => {
    const [isTyping, setIsTyping] = useState(false);
    const typingTimer = useRef(null);

    useEffect(() => {
        if (inputValue !== '') {
            if (!isTyping) {
                setIsTyping(true);
            }

            if (typingTimer.current) {
                clearTimeout(typingTimer.current);
            }

            const timer = setTimeout(() => {
                setIsTyping(false);
                broadcastTyping && broadcastTyping();
            }, 1500);

            typingTimer.current = timer
        } else {
            setIsTyping(false);
        }
        utils.updateEvent("StopTypingBrodcast", () => {
            if (typingTimer.current) {
                clearTimeout(typingTimer.current);
            }
        })
    }, [inputValue]);

    return <></>;
};

const HandleMentionDropdown = ({ showMention = false, Member, IsFromGroup = false, handleUserChange = () => { }, SearchText, isAndroidIOSMobile, setmentionDropdown, ManageCustomerViewRights, ManageCustomerModifyRights, ManageEmployeeViewRights, ManageEmployeeModifyRights, isCustomerChat, newChat, isNewChat, expandMinimizeShow, ckeditortype, isIOSMobile }) => {
    const DropdownRef = useRef(null);
    const dropdownToggleRef = useRef(null);
    const [highlightedIndex, setHighlightedIndex] = useState(0);
    const itemRefs = useRef([]);
    const ActiveLeftOption = isCustomerChat ? "Customers" : $("a.leftside-menu_options.active").text().replace(/[0-9]/g, '');
    useEffect(() => {
        if (showMention) {
            const handleClickOutside = (event) => {
                if (dropdownToggleRef.current && !dropdownToggleRef.current.contains(event.target)) {
                    setmentionDropdown(false);
                }
            };
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }
    }, [showMention]);
    const highlightText = (text, highlight) => {
        if (typeof highlight !== 'string' || !highlight.trim()) {
            return <>{text}</>;
        }
        const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
        return parts.map((part, index) =>
            part.toLowerCase() === highlight.toLowerCase() ?
                <strong key={index}>{part}</strong> :
                <span key={index}>{part}</span>
        );
    };
    const displayedMembers = Member?.slice(0, 5);

    const handleKeyDown = (event) => {
        const totalItems = IsFromGroup ? displayedMembers?.length + 2 : displayedMembers?.length;
        switch (event.key) {
            case 'ArrowDown':
                event.preventDefault();
                setHighlightedIndex(prevIndex => (prevIndex + 1) % totalItems);
                break;
            case 'ArrowUp':
                event.preventDefault();
                setHighlightedIndex(prevIndex => (prevIndex - 1 + totalItems) % totalItems);
                break;
            case 'Enter':
            case 'Tab':
                event.preventDefault();
                if (highlightedIndex < displayedMembers.length) {
                    handleUserChange(displayedMembers[highlightedIndex]);
                    setmentionDropdown(false);
                } else if (highlightedIndex === displayedMembers.length) {
                    if (IsFromGroup) {
                        handleUserChange({ name: "Everyone", id: 1 });
                    }
                    setmentionDropdown(false);
                } else if (highlightedIndex === displayedMembers.length + 1) {
                    handleUserChange({ name: "", id: 1, isAddTeammate: true });
                    setmentionDropdown(false);
                }
                setHighlightedIndex(0);
                break;
            case 'Escape':
                event.preventDefault();
                setHighlightedIndex(0);
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        const handleDropdownKeyDown = (event) => {
            if (showMention) {
                handleKeyDown(event);
            }
        };

        document.addEventListener('keydown', handleDropdownKeyDown);

        return () => {
            // Remove keydown listener
            document.removeEventListener('keydown', handleDropdownKeyDown);
        };
    }, [showMention, highlightedIndex, Member, IsFromGroup]);

    useEffect(() => {
        if (showMention && itemRefs.current[highlightedIndex]) {
            // Ensure the highlighted item is visible
            itemRefs.current[highlightedIndex].scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
            });
        }
    }, [highlightedIndex, showMention]);
    useEffect(() => {
        if (showMention) {
            const dropdownMenu = DropdownRef.current.querySelector('.dropdown-menu');
            if (dropdownMenu) {
                dropdownMenu.scrollTop = 0;
            }
        }
    }, [showMention]);
    const HandleMentionDropdownPosition = () => {
        try {
            setHighlightedIndex(0);
            const DropdownBounding = DropdownRef.current.getBoundingClientRect();
            const windowWidth = $(window).width();
            const selection = window.getSelection();
            const range = selection.getRangeAt(0);
            const rects = range.getClientRects();
            const rect = rects[0];
            if (ckeditortype === 1) {
                const bottomComposerHeight = document.getElementById('mobileBottomEditer').offsetHeight;
                if (isAndroidIOSMobile === true) {
                    process.nextTick(() => {
                        const elements = document.querySelectorAll('.mentionHighlight');
                        if (elements.length > 0) {
                            elements.forEach(mention => {
                                mention.addEventListener('click', (event) => {
                                    const rect = event.target.getBoundingClientRect();
                                    if (rect.left + DropdownRef.current.clientWidth > windowWidth) {
                                        DropdownRef.current.style.left = `${windowWidth - DropdownRef.current.clientWidth}px`;
                                    } else {
                                        DropdownRef.current.style.left = `${rect.left}px`;
                                    }
                                    DropdownRef.current.style.bottom = `${bottomComposerHeight + 8}px`;
                                    DropdownRef.current.style.top = 'auto';
                                    DropdownRef.current.style.position = 'absolute';
                                });
                                mention.click()
                            });
                        }
                    }
                    )
                } else {
                    if (rect && (isAndroidIOSMobile === false || isAndroidIOSTabIpad())) {
                        if (DropdownRef.current) {
                            if (isAndroidIOSTabIpad() && (rect.left + DropdownRef.current.clientWidth > windowWidth)) {
                                DropdownRef.current.style.left = `${windowWidth - DropdownRef.current.clientWidth}px`;
                            } else {
                                DropdownRef.current.style.left = `${rect.left}px`;
                            }
                            // if (isIOSTabIpad()) {
                            //     let height = window.outerHeight - visualViewport.height;
                            //     DropdownRef.current.style.top = 'auto';
                            //     DropdownRef.current.style.bottom = `${height + DropdownRef.current.offsetHeight + 25}px`;
                            //     console.log('isIOSTabIpad bottom', height, DropdownRef.current.offsetHeight);
                            // } else {
                            //     DropdownRef.current.style.top = `${rect.bottom - DropdownRef.current.offsetHeight - 25}px`;
                            // }
                            DropdownRef.current.style.top = `${rect.bottom - DropdownRef.current.offsetHeight - 25}px`;
                            DropdownRef.current.style.position = 'fixed';
                            DropdownRef.current.style.zIndex = '9999999';
                        }
                    } else {
                        process.nextTick(() => {
                            const elements = document.querySelectorAll('.mentionHighlight');
                            if (elements.length > 0) {
                                elements.forEach(mention => {
                                    mention.addEventListener('click', (event) => {
                                        const rect = event.target.getBoundingClientRect();
                                        var Excludewidth = 0;
                                        var leftBar = document.getElementById('leftBar');
                                        var chatListBlock = document.getElementById('chatListBlock');
                                        if (leftBar?.offsetWidth) {
                                            Excludewidth += leftBar.offsetWidth;
                                        }
                                        if (chatListBlock?.offsetWidth) {
                                            Excludewidth += chatListBlock.offsetWidth;
                                        }
                                        if (DropdownRef.current) {
                                            DropdownRef.current.style.left = `${rect.left - Excludewidth}px`;
                                            DropdownRef.current.style.top = `${event.target.offsetTop - DropdownRef.current.offsetHeight + 15}px`;
                                            DropdownRef.current.style.position = 'absolute';
                                            DropdownRef.current.style.zIndex = '9999999';

                                            if (expandMinimizeShow === 2) {
                                                DropdownRef.current.style.top = `${event.target.offsetTop - DropdownRef.current.offsetHeight}px`;
                                            }

                                            if (isAndroidIOSMobile === true) {
                                                //DropdownRef.current.style.left = `${0}px`;
                                                DropdownRef.current.style.top = `${event.target.offsetTop}px`;
                                                DropdownRef.current.style.position = 'absolute';
                                                DropdownRef.current.style.zIndex = '9999999';
                                            }
                                        }
                                    });
                                    mention.click()
                                });
                            }
                        })
                    }
                }
            } else {
                if (rect && (isAndroidIOSMobile === false || isAndroidIOSTabIpad())) {
                    if (DropdownRef.current) {
                        process.nextTick(() => {
                            const selection = window.getSelection();
                            const range = selection.getRangeAt(0);
                            const rects = range.getClientRects();
                            const rect = rects[0];
                            if (isAndroidIOSTabIpad() && (rect.left + DropdownRef.current.clientWidth > windowWidth)) {
                                DropdownRef.current.style.left = `${windowWidth - DropdownRef.current.clientWidth}px`;

                            } else {
                                DropdownRef.current.style.left = `${rect.left}px`;
                            }
                            if (rect.top < DropdownRef.current.clientHeight) {
                                DropdownRef.current.style.top = `${rect.y + 25}px`;
                            } else {
                                DropdownRef.current.style.top = `${rect.bottom - DropdownRef.current.offsetHeight - 25}px`;
                            }
                        });

                        // DropdownRef.current.style.top = `${rect.bottom - DropdownRef.current.offsetHeight - 25}px`;
                        DropdownRef.current.style.position = 'fixed';
                        DropdownRef.current.style.zIndex = '9999999';
                    }
                } else {

                    DropdownRef.current.style.left = `${0}px`;
                    DropdownRef.current.style.top = `-${DropdownRef.current.offsetHeight}px`;
                    DropdownRef.current.style.position = 'absolute';
                    DropdownRef.current.style.zIndex = '9999999';
                }
                // if (DropdownRef.current) {
                //     const rect = DropdownRef.current.getBoundingClientRect();
                //     const offsetTop = rect.top + window.scrollY; // Top position relative to the document
                //     const offsetLeft = rect.left + window.scrollX;
                //     const outerHeight = rect.height; // Height of the element
                //     var dropdownTop = rect.top + window.scrollY + rect.height;
                //     var windowHeight = $(window).height();
                //     var dropdownHeight = rect.height;;
                //     if (windowHeight - dropdownTop < dropdownHeight) {
                //         DropdownRef.current.style.top = `${offsetTop}px`;
                //     } else {
                //         if (dropdownHeight !== undefined && dropdownHeight !== null && dropdownHeight !== "") {
                //             DropdownRef.current.style.top = `${offsetTop + rect.height}px`;
                //         }
                //     }
                // }

            }
        } catch (error) {
            if (DropdownRef.current?.style && isIOSTabIpad()) DropdownRef.current.style.position = 'static';
            console.log(error);
        }
    }
    useEffect(() => {
        if (showMention) {
            HandleMentionDropdownPosition()
        }
    }, [showMention, Member, isAndroidIOSMobile]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (DropdownRef.current && !DropdownRef.current.contains(event.target)) {
                setmentionDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleUserChange]);
    return (
        <div>
            <Dropdown className='mentionListMainWrap' ref={DropdownRef} show={showMention} style={{ display: showMention ? 'block' : 'none' }}>
                <Dropdown.Toggle variant="" id="dropdown-basic" className='abcd'></Dropdown.Toggle>
                <Dropdown.Menu className='mention-popup'>
                    <div className='mention-popup_topArea'>
                        <Dropdown.ItemText>Suggestions</Dropdown.ItemText>
                        {displayedMembers?.length > 0 ? (
                            displayedMembers?.map((item, index) => (
                                <Dropdown.Item
                                    key={item.id}
                                    onClick={() => handleUserChange(item)}
                                    // className={highlightedIndex === index ? 'active' : ''}
                                    className={`${displayedMembers.length === 1 ? 'onlyoneMember' : ''} ${highlightedIndex === index ? 'active' : ''}`}
                                    onMouseEnter={() => setHighlightedIndex(index)}
                                    onTouchEnd={(e) => isIOSMobile && e.cancelable && e.target?.click()}
                                    aria-selected={highlightedIndex === index}
                                    ref={(el) => (itemRefs.current[index] = el)}
                                >
                                    <div className='mention-everyone-row'>
                                        <div className='icon-box'>
                                            <div className="avatar avatar-sm" data-avatar="1">
                                                <div className="avatar-group-img-wrap">
                                                    <div>
                                                        {item?.photo ?
                                                            <img alt='avatar' className="avatar-group-member" src={item?.cdnUrl + 'Original/' + item?.photo} />
                                                            : <div className="review-profile-inner-text vg-avatar-inital-inner-text">{utils.setLetter(item?.firstName || "", item?.lastName || "")}</div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='mentionListName'>
                                            <div className='m-head-text'>
                                                {highlightText(item.name, SearchText)}
                                            </div>
                                            {ActiveLeftOption === "Customers" ? (ManageCustomerViewRights || ManageCustomerModifyRights) && <div className='m-disc-text'>{item?.cell}</div> : (ManageEmployeeViewRights || ManageEmployeeModifyRights) &&
                                                <div className='m-disc-text'>{item?.cell}</div>
                                            }
                                        </div>
                                    </div>
                                </Dropdown.Item>
                            ))
                        ) : (
                            null
                            // <Dropdown.Item>
                            //     <div className='mention-everyone-row'>
                            //         <div>
                            //             <div className='m-head-text'>No Result found</div>
                            //         </div>
                            //     </div>
                            // </Dropdown.Item>
                        )}

                    </div>

                    {IsFromGroup && (newChat === false && isNewChat === false) && (
                        <>
                            <Dropdown.Item
                                key="everyone"
                                onClick={() => handleUserChange({ name: "Everyone", id: 1 })}
                                onTouchEnd={(e) => isIOSMobile && e.cancelable && e.target?.click()}
                                className={highlightedIndex === displayedMembers.length ? 'active' : ''}
                                aria-selected={highlightedIndex === displayedMembers.length}
                                ref={(el) => (itemRefs.current[displayedMembers.length] = el)}
                            >
                                <div className='mention-everyone-row'>
                                    <div className='icon-box'><i className="fa-solid fa-users"></i></div>
                                    <div>
                                        <div className='m-head-text'>Everyone</div>
                                        <div className='m-disc-text'>Notify everyone in the chat</div>
                                    </div>
                                </div>
                            </Dropdown.Item>
                            {expandMinimizeShow !== 2 &&
                                < Dropdown.Item
                                    key="add-teammate"
                                    onClick={() => handleUserChange({ name: "", id: 1, isAddTeammate: true })}
                                    onTouchEnd={(e) => isIOSMobile && e.cancelable && e.target?.click()}
                                    className={highlightedIndex === displayedMembers.length + 1 ? 'active' : ''}
                                    aria-selected={highlightedIndex === displayedMembers.length + 1}
                                    ref={(el) => (itemRefs.current[displayedMembers.length + 1] = el)}
                                >
                                    <div className='mention-everyone-row'>
                                        <div className='icon-box addMemnerBlock'>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                                                <path d="M624 208h-64v-64c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v64h-64c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h64v64c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-64h64c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm-400 48c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z" />
                                            </svg>
                                        </div>
                                        <div>
                                            <div className='m-head-text'>Add Teammate</div>
                                            <div className='m-disc-text'>Add a teammate to this chat</div>
                                        </div>
                                    </div>
                                </Dropdown.Item>
                            }
                        </>
                    )}
                </Dropdown.Menu>
            </Dropdown>
        </div >
    )
}
export default EditoerComponent;
