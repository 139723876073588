import moment from "moment";
import $ from "jquery";
import { appInsightTrackEvent } from "./AppInsights";
import ReactDom, { createPortal, unmountComponentAtNode } from "react-dom";
import React, { Fragment } from "react";
// import SuccessAlert from "../components/Common/SuccessAlert";
import VGPopUp from "../component/Common/VGPopUp";
import ReactDOM from 'react-dom';
import SuccessAlert from "../component/Common/SuccessAlert";
import { CommonMethods, LeftControlNames, MobileCallBackAction } from "../Vagaroconnect/Binding/ChatBoatEnums";
import EventEmitter from "events";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

var noRecHeight = null;
var nDotCount = 0;
var isandroidiospro = false;
var getVersion;
var firstCallTimer = 1000;
var secondCallTimer = 300;
var sortTimeout = 500;
var androidVersion = 584;
var iosVersion = 547;
var androidVersionForRman = 599;
var iosVersionForRman = 557;
let isIpad = /Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1;
let isIpadold = /iPad/i.test(navigator.userAgent);
var upsertChannelTimer = 5;
var CountryDateFormatList = [];
const Emitter = new EventEmitter();

const ErrorType = {
  Success: 1,
  Fail: 2,
  Warning: 3,
};

const ResponseCode = {
  Success: 1000,
  ResponseFail: 1001,
  RecordExists: 1002,
  PartialAuthorize: 5008,
  Fail: 60000,
  EmptyResultSet: 1032
}
const CallBackAction = {
  SalesSummaryHeader: 7,
  SalesSummaryDetailsHeader: 8,
  HideAllMenus: 9,
  ShowResetMenu: 10,
  PrintSendMailMenu: 11,
  PrintSortFilterMenus: 12,
  HideAllMenusIOU: 13,
  ShowIconDetails: 15,
  ShowPrintSortByMenu: 16,
  ShowFooter: 1,
  HideFooter: 2,
  ActinBackFromReact: 69,
  ActionMenuOption: 60,
  FirstTimeFilterCall: 14,
  PrintFilterGridHideLegendTimePeriod: 17,
  PrintFilterChartMenus: 18,
  PrintSortFilterChartMenus: 19,
  ShowPrintChartSortByMenu: 20,
  PrintFilterGridShowLegendTimePeriod: 21,
  ShowPrintGridShowLegendTimePeriodMenu: 22,
  ShowPrintGridHideLegendTimePeriodMenu: 23,
  ChartTimeperiod: 24,
  PrintSortSearchFilterMenus: 26,
  DepositsFilter: 27,
  HideAddGiftCertificate: 29,
}
const ReportOption = {
  BussinessCost: "1",
  TotalSale: "2",
  TransactionReceipt: "3",
  ViewDetails: "4",
  ViewHistoryNoShow: "5",
  ViewCancellationPolicy: "6",
  EditIOU: "7",
  VoidIOU: "8",
  PayIOU: "9",
  ViewCustomerProfile: "10",
  CommissionbyService: "11",
  CommissionbyClass: "12",
  CommissionbyAttendee: "13",
  CommissionbyProduct: "14",
  EditTimeCard: "15",
  DeleteTimeCard: "16",
  EditMembership: "18",
  EditBalance: "19",
  CancelMembership: "20",
  RefundMembership: "21",
  History: "22",
  Paused: "23",
  AutoRenew: "24",
  Refunded: "26",
  selectall: "27",
  active: "28",
  Inactive: "29",
  paused: "30",
  Cancelled: "31",
  any: "32",
  Customer: "33",
  Membershipname: "34",
  BusinessName: "35",
  ServiceFlatRate: "36",
  CBCFlatRate: "37",
  ProductFlatRate: "38",
  ClassFlatRate: "39",
  EditMembershipFilter: "40",
  ViewBundleDetails: "41",
  ViewBrandDetails: "42",
  SMS: "43",
  PromotionEmail: "44",
  TextMarketing: "45",
  Integrations: "46",
  RefundPurchaseProduct: "47",
  viewShippingDetails: "48",
  HourlyPayDrillThrough: "49",
  Service: "50",
  Class: "51",
  Product: "52",
  ServiceTieredRevenue: "53",
  ClassTieredRevenue: "54",
  ProductTieredRevenue: "55",
  RentandFees: "56",
  ServiceThreshold: "57",
  ProductThreshold: "58",
  ClassThreshold: "59",
  ServiceCost: "60",
  ClassCost: "61",
  ProductCost: "62",
  Tip: "63",
  OrderDetailPopup: "64",
  Pending: "65",
  Shipped: "66",
  Delivered: "67",
  Cancel: "68",
  TaxPopup: "69",
  OrderStatusPopup: "70",
  EditGiftCertificates: "71",
  HistoryGiftCertificates: "72",
  VoidGiftCertificates: "73",
  ViewGiftCertificates: "74",
  PriceBased: "75",
  EditPackage: "76",
  HistoryPackage: "77",
  CancelPackage: "78",
  PauseAutoRenewPackage: "79",
  ResumeAutoRenewPackage: "80",
  EditBalancePackage: "81",
  RefundPackage: "82",
  ActivePackage: "83",
  SignatureRequired: "84",
  InHouseNew: "85",
  InHouseReturning: "86",
  OnlineNew: "87",
  OnlineReturning: "88",
  ProjectedService: "89",
  ProjectedServiceAddon: "90",
  ProjectedClass: "91",
  ProjectedClassAddon: "92",
  ProjectedRent: "93",
  ProjectedPackage: "94",
  ProjectedFeesData: "95",
  ProjectedMembership: "96",
  ServiceCharge: "97",
  ServiceAddonCharge: "98",
  ClassCharge: "99",
  ClassAddonCharge: "100",
  ProductCharge: "101",
  GiftCertificateCharge: "102",
  PackageCharge: "103",
  MembershipCharge: "104",
  RentCharge: "105",
  TipCharge: "106",
  FeesChargeData: "107",
  AppCancelled: "108",
  Deleted: "109",
  Denied: "110",
  Noshows: "111",
  ServiceCompleted: "112",
  Accepted: "113",
  Show: "114",
  NoShow: "115",
  InProgress: "116",
  AwaitConfirmation: "117",
  confirmed: "118",
  ReadyToStart: "119",
  Requested: "120",
  NR: "121",
  NNR: "122",
  RR: "123",
  RNR: "124",
  EditPayroll: "125",
  SkipEmployee: "126",
  UnSkipEmployee: "127",
  PaidTimeOffDrillThrough: "128",
  ApproveTimeoff: "129",
  DenyTimeoff: "130"
}
const DateFormatType = {
  SmallDate: 1, // Ex. 29 Apr 2024
  SmallDateWithTime: 2, // Ex. 2 May 2024 7:49 AM
  LargeDate: 3, // Ex. Monday, 13-May-2024
  LargeDateWithTime: 4, //Ex Monday, 13-May-2024 7:49 AM
};
const utils = {
  expandMinimizeSize: 0,
  fillStringTemplate: (template, value1 = "", value2 = "", value3 = "", value4 = "", value5 = `""`) => {
    return template.replace('{1}', value1).replace('{2}', value2).replace('{3}', value3).replace('{4}', value4).replace('{5}', value5);
  },
  //   displayErrorMessage: (type, message) => {
  //     console.log(message);
  //     //  alert(message);
  //     return ReactDom.render(
  //       <Fragment>
  //         <SuccessAlert
  //           alertType={
  //             type == ErrorType.Success
  //               ? "success"
  //               : type == ErrorType.Fail
  //                 ? "fail"
  //                 : "warning"
  //           }
  //           strMessage={message || "Some error Occured. Please try again."}
  //           displaytime={2000}
  //         />
  //       </Fragment>,
  //       document.getElementById("OpenDialog1")
  //     );
  //   },

  FormatPrice: (val, CurrencySymbol) => {
    let value = parseFloat(val);
    if (typeof val != undefined && val != null && val != "") {
      if (val < 0) {
        return (
          "(" +
          CurrencySymbol +
          utils.getPositiveValue(value).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
          +
          ")"
        );
      } else {
        return (
          CurrencySymbol +
          value.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        );
      }
    } else {
      return CurrencySymbol + "0.00";
    }
  },
  FormatPriceForCapital: (val, CurrencySymbol) => {
    let value = parseFloat(val);
    if (typeof val != undefined && val != null && val != "") {
      if (val < 0) {
        return (
          CurrencySymbol + "(" +
          utils.getPositiveValue(value).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
          +
          ")"
        );
      } else {
        return (
          CurrencySymbol +
          value.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        );
      }
    } else {
      return CurrencySymbol + "0.00";
    }
  },
  FormatPriceForExcel: (val, CurrencySymbol) => {
    let value = parseFloat(val);
    if (typeof val != undefined && val != null && val != "") {
      if (val < 0) {
        return (
          "-" +
          CurrencySymbol +
          utils.getPositiveValue(value).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })

        );
      } else {
        return (
          CurrencySymbol +
          value.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        );
      }
    } else {
      return CurrencySymbol + "0.00";
    }
  },
  FormatWithoutCurrency: (val) => {
    let value = parseFloat(val);
    if (typeof val != undefined && val != null && val != "") {
      if (val < 0) {
        return (
          "(" +
          utils.getPositiveValue(value).toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })
          +
          ")"
        );
      } else {
        return (
          value.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })
        );
      }
    }
  },
  FormatWithoutDecimal: (val, CurrencySymbol) => {
    let value = parseFloat(val);
    if (typeof val != undefined && val != null && val != "") {
      if (val < 0) {
        return (
          "(" +
          CurrencySymbol + utils.getPositiveValue(value).toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })
          +
          ")"
        );
      } else {
        return (
          CurrencySymbol + value.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })
        );
      }
    } else {
      return CurrencySymbol + val;
    }
  },
  FormatValue: (val) => {
    return ((val).toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }))
  },
  DateWiseViewtypeChange: (daysdifference) => {
    if (daysdifference < 14) {
      utils.GetTimePeriodCallback("D");
      return "D";
    }
    else if (daysdifference >= 14 && daysdifference < 51) {
      utils.GetTimePeriodCallback("W");
      return "W";
    }
    else if (daysdifference >= 51 && daysdifference <= 365) {
      utils.GetTimePeriodCallback("M");
      return "M";
    }
    else {
      utils.GetTimePeriodCallback("Y");
      return "Y";
    }
  },
  IOUGCstatus: (tran) => {
    let IOUstatustext = null;
    //invoice - Ticket#2490965 - DEV: Issue Review - IOU showing on transaction list - Galveston Holistic Wellness (Shawn Porche - Galveston, TX)
    if (tran.IsIOU == false) {
      IOUstatustext = "";
      return IOUstatustext;
    }
    //iou
    switch (tran.IOUStatus) {
      case 1:
        IOUstatustext = " IOU (Outstanding)";
        break;
      case 2:
        IOUstatustext = " IOU (Paid)";
        break;
      case 3:
        IOUstatustext = " IOU (Void)";
        break;
      default:
        IOUstatustext = "";
    }
    return IOUstatustext;
  },
  FormatPhoneByCountry: (phonenumber, countryID) => {
    try {
      if (
        phonenumber != null &&
        typeof phonenumber != "undefined" &&
        phonenumber.length != 0 &&
        phonenumber.length > 8
      ) {
        var numbers = phonenumber.replace(/\D/g, ""),
          FormatPhone = { 0: "(", 3: ") ", 6: "-" }; //US
        if (
          typeof countryID != "undefined" &&
          countryID != null &&
          countryID * 1 > 0
        ) {
          if (countryID == 2 && numbers.length == 10) {
            numbers = "0" + numbers;
          } else if (countryID == 4 && numbers.length == 9) {
            numbers = "0" + numbers;
          }
        }
        if (
          typeof countryID != "undefined" &&
          countryID != null &&
          countryID * 1 > 0
        ) {
          if (countryID == 2) {
            //UK
            FormatPhone = { 3: " ", 7: " " };
          } else if (countryID == 3) {
            // Canada
            FormatPhone = { 3: "-", 6: "-" };
          } else if (countryID == 4) {
            // Australia
            FormatPhone = { 0: "(", 2: ") ", 6: " " };
          }
        }
        phonenumber = "";
        for (var i = 0; i < numbers.length; i++) {
          phonenumber += (FormatPhone[i] || "") + numbers[i];
        }
      }
      return phonenumber;
    } catch (e) {
      return phonenumber;
    }
  },
  padDigits: (number, digits) => {
    return Array(Math.max(digits - String(number).length + 1, 0)).join(0) + number;
  },
  checknagative: (val, option, CurrencySymbol) => {
    if (val == undefined) return;
    var ival;
    if (val < 0) {
      if (option == 1) ival = "(" + Math.abs(val) + ")";
      else if (option == 2)
        ival = "(" + CurrencySymbol + Math.abs(val).toFixed(2) + ")";
      else ival = "(" + Math.abs(val).toFixed(2) + ")";
    } else {
      if (option == 1) ival = val;
      else if (option == 2) ival = CurrencySymbol + val.toFixed(2);
      else ival = val.toFixed(2);
    }
    return ival;
  },
  ReplaceCurrency: (val, CurrencySymbol) => {
    return parseFloat(val.replace("+", "").replace("-", "").replace(CurrencySymbol, ""));
  },
  FormatAmountWithCurrency: (val, CurrencySymbol) => {
    if (typeof val != undefined && val != null && val != "") {
      let newval = utils.ReplaceCurrency(val, CurrencySymbol);
      if (newval < 0) {
        return CurrencySymbol + "(" + newval.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }) + ")";
      } else {
        return CurrencySymbol + newval.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      }
    }
    else {
      return "";
    }
  },
  FormatAmountWithoutCurrency: (val, CurrencySymbol) => {
    if (typeof val != undefined && val != null && val != "") {
      let amt = parseFloat(val);
      if (amt > 0) {
        return (
          CurrencySymbol +
          amt.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        );
      } else {
        return "--";
      }
    }
    else {
      return "";
    }
  },
  StorageFromDate: () => {
    const cache_DateRange = "reportFilterDate";
    const dateRange = JSON.parse(localStorage.getItem("reportFilterDate"));
    return typeof localStorage === "object" &&
      localStorage[cache_DateRange] !== null &&
      localStorage[cache_DateRange] !== undefined &&
      localStorage[cache_DateRange].length > 0 ? moment(dateRange.startDate).format("YYYY-MM-DD") : moment().startOf("month").format("YYYY-MM-DD")
  },
  StorageToDate: () => {
    const cache_DateRange = "reportFilterDate";
    const dateRange = JSON.parse(localStorage.getItem("reportFilterDate"));
    return typeof localStorage === "object" &&
      localStorage[cache_DateRange] !== null &&
      localStorage[cache_DateRange] !== undefined &&
      localStorage[cache_DateRange].length > 0 ? moment(dateRange.endDate).format("YYYY-MM-DD") : moment().endOf("month").format("YYYY-MM-DD")
  },
  setTableHeight: () => {
    var vHeight = $(window).height();
    var modalHead;
    if ($(".modal-header").length) {
      modalHead = $(".modal-header:visible").outerHeight(true);
    } else {
      modalHead = 0;
    }
    var modalsearch;
    if ($(".searc-filters").length) {
      modalsearch = $(".searc-filters").outerHeight(true);
    } else {
      modalsearch = 0;
    }
    var modalfooter;
    if ($(".modal-footer").length) {
      modalfooter = $(".modal-footer:visible").outerHeight(true);
    } else {
      modalfooter = 0;
    }
    var payrollFilter;
    if ($(".commision_filter_row").length) {
      payrollFilter = $(".commision_filter_row").outerHeight(true);
    } else {
      payrollFilter = 0;
    }

    var TransactionTableHeight;
    var TransactionTableMargin
    if ($("#tbltransactionpopup").length) {
      TransactionTableHeight = $("#tbltransactionpopup").outerHeight(true);
      TransactionTableMargin = 35;
    } else if ($("#tblsourcepopup").length) {
      TransactionTableHeight = $("#tblsourcepopup").outerHeight(true);
      TransactionTableMargin = 35;
    } else if ($("#tblnewvsreturning").length) {
      TransactionTableHeight = $("#tblnewvsreturning").outerHeight(true);
      TransactionTableMargin = 35;
    } else if ($("#tbltrendsandappointmentsummary").length) {
      TransactionTableHeight = $("#tbltrendsandappointmentsummary").outerHeight(true);
      TransactionTableMargin = 35;
    } else if ($("#tblappointmentsummary").length) {
      TransactionTableHeight = $("#tblappointmentsummary").outerHeight(true);
      TransactionTableMargin = 35;
    } else if ($("#tblmissedcheckouts").length) {
      TransactionTableHeight = $("#tblmissedcheckouts").outerHeight(true);
      TransactionTableMargin = 35;
    } else {
      TransactionTableHeight = 0;
      TransactionTableMargin = 0;
    }

    var payrollTableHeight;
    var payrollTableMargin
    if ($(".payrollpopupID").length) {
      payrollTableHeight = $(".payrollpopupID").outerHeight(true);
      payrollTableMargin = 35;
    } else {
      payrollTableHeight = 0;
      payrollTableMargin = 0;
    }


    var calTableHeight = 0;
    if ($(".dettachedCustomerList").length) {
      calTableHeight = 303;
    }
    else if ($(".mobiledettachedCustomerList").length) {
      calTableHeight = "100%";
    }
    else {
      calTableHeight = vHeight - modalHead - modalsearch - modalfooter - payrollFilter - payrollTableMargin - TransactionTableMargin - 81;
    }
    if ($(".dettachedCustomerList").length) {
      noRecHeight = 303;
      $(".modalTableScroll").css("height", calTableHeight);
      noRecHeight = calTableHeight;
    }
    else if ($(".mobiledettachedCustomerList").length) {
      noRecHeight = "100%";
      $(".modalTableScroll").css("height", calTableHeight);
      noRecHeight = calTableHeight;
    }
    else {
      if (TransactionTableHeight > calTableHeight || payrollTableHeight > calTableHeight) {
        $(".modalTableScroll").css("height", calTableHeight);
        noRecHeight = calTableHeight;
      }
      else {
        $(".modalTableScroll").css("height", TransactionTableHeight + payrollTableHeight + 9);
        noRecHeight = TransactionTableHeight + payrollTableHeight - 20;
      }

    }
  },
  setCompanyPaysHeight: () => {
    let windowHeight, exportfoot, elementHeight, payrollHeaderNote, payrollGridTabScroll, payrollNote, galleryTabMenu, reviewTop, submitPayrollTag, submitBox, modelFooter, calculateTableHeight;

    windowHeight = window.innerHeight;

    if ($(".report-searchbox").length) {
      elementHeight = $(".report-searchbox").outerHeight(true);
    } else {
      elementHeight = 0;
    }

    if ($(".exportfootSection").length) {
      exportfoot = $(".exportfootSection").outerHeight(true) + 110;
    } else {
      exportfoot = 0;
    }

    if ($(".payroll_note").length) {
      payrollNote = $(".payroll_note").outerHeight(true);
    } else {
      payrollNote = 0
    }

    if ($("#payrollHeader").length) {
      if (navigator != null && navigator.userAgent != null && navigator.userAgent != "") {
        if ((navigator.userAgent.indexOf("com.vagaro") > -1)) {
          payrollHeaderNote = $("#payrollHeader").outerHeight(true) - 50 + payrollNote;
        } else {
          payrollHeaderNote = $("#payrollHeader").outerHeight(true) + 0 + payrollNote;
        }
      } else {
        payrollHeaderNote = $("#payrollHeader").outerHeight(true) + 0 + payrollNote;
      }
    } else {
      payrollHeaderNote = 0;
    }

    if ($(".payrollGridTabscroll").length) {
      payrollGridTabScroll = $(".payrollGridTabscroll").outerHeight(true);
    } else {
      payrollGridTabScroll = 0;
    }

    if ($(".gallery-tabs-parent").length) {
      galleryTabMenu = $(".gallery-tabs-parent").outerHeight(true);
    } else {
      galleryTabMenu = 0;
    }

    if ($(".reviewpayroll").length) {
      reviewTop = $(".reviewpayroll").outerHeight(true);
    } else {
      reviewTop = 0;
    }

    if ($(".payrollNoteTag").length) {
      submitPayrollTag = $(".payrollNoteTag").outerHeight(true);
    } else {
      submitPayrollTag = 0;
    }

    if ($(".submitBox").length) {
      submitBox = $(".submitBox").outerHeight(true) + 8;
    } else {
      submitBox = 0;
    }

    calculateTableHeight = windowHeight - elementHeight - exportfoot - payrollHeaderNote - payrollGridTabScroll - galleryTabMenu - reviewTop - submitPayrollTag - submitBox;
    $(".tableScrollCompnayPayTab").css("height", calculateTableHeight);
  },
  setTexedAndDebited: () => {
    let windowHeight, exportfoot, elementHeight, payrollHeaderNote, payrollGridTabScroll, payrollNote, reviewScroll, reviewPayrollDebited, galleryTabMenu, reviewTop, submitPayrollTag, submitBox, calculateTableHeight;
    windowHeight = $(window).height();

    if ($(".report-searchbox").length) {
      elementHeight = $(".report-searchbox").outerHeight(true);
    } else {
      elementHeight = 0;
    }

    if ($(".exportfootSection").length) {
      exportfoot = $(".exportfootSection").outerHeight(true);
    } else {
      exportfoot = 0;
    }

    if ($(".payroll_note").length) {
      payrollNote = $(".payroll_note").outerHeight(true) + 10;
    } else {
      payrollNote = 0
    }
    if ($(".reviewpayroll").length) {
      reviewScroll = $(".v").outerHeight(true) + 10;
    } else {
      reviewScroll = 0
    }

    if ($("#payrollHeader").length) {
      if (navigator != null && navigator.userAgent != null && navigator.userAgent != "") {
        if ((navigator.userAgent.indexOf("com.vagaro") > -1)) {
          payrollHeaderNote = $("#payrollHeader").outerHeight(true) - 50 + payrollNote;
        } else {
          payrollHeaderNote = $("#payrollHeader").outerHeight(true) + 0 + payrollNote;
        }
      } else {
        payrollHeaderNote = $("#payrollHeader").outerHeight(true) + 0 + payrollNote;
      }
    } else {
      payrollHeaderNote = 0;
    }

    if ($(".payrollGridTabscroll").length) {
      payrollGridTabScroll = $(".payrollGridTabscroll").outerHeight(true);
    } else {
      payrollGridTabScroll = 0;
    }

    if ($(".gallery-tabs-parent").length) {
      galleryTabMenu = $(".gallery-tabs-parent").outerHeight(true);
    } else {
      galleryTabMenu = 0;
    }

    if ($(".reviewpayroll").length) {
      reviewScroll = $(".reviewpayroll").outerHeight(true) + 20;
    } else {
      reviewScroll = 0;
    }

    if ($("#reviewPayrollDebited").length) {
      reviewPayrollDebited = $("#reviewPayrollDebited").outerHeight(true);
    } else {
      reviewPayrollDebited = 0;
    }

    if ($(".payrollNoteTag").length) {
      submitPayrollTag = $(".payrollNoteTag").outerHeight(true) + 100;
    } else {
      submitPayrollTag = 0;
    }

    if ($(".submitBox").length) {
      submitBox = $(".submitBox").outerHeight(true);
    } else {
      submitBox = 0;
    }

    calculateTableHeight = windowHeight - elementHeight - exportfoot - payrollGridTabScroll - reviewPayrollDebited - galleryTabMenu - submitPayrollTag - reviewScroll - 110;

    $(".tableScrollTaxedAndDebited").css("height", calculateTableHeight);
  },
  setSinglePayroll: () => {
    let windowHeight, exportfoot, elementHeight, payrollHeaderNote, payrollNote, galleryTabMenu, payrollTabmenus, calculateTableHeight

    windowHeight = $(window).height();

    if ($(".report-searchbox").length) {
      elementHeight = $(".report-searchbox").outerHeight(true);
    } else {
      elementHeight = 0;
    }

    if ($(".exportfootSection").length) {
      exportfoot = $(".exportfootSection").outerHeight(true);
    } else {
      exportfoot = 0;
    }

    if ($(".payroll_note").length) {
      payrollNote = $(".payroll_note").outerHeight(true) + 10;
    } else {
      payrollNote = 0
    }

    if ($("#payrollHeader").length) {
      if (navigator != null && navigator.userAgent != null && navigator.userAgent != "") {
        if ((navigator.userAgent.indexOf("com.vagaro") > -1)) {
          payrollHeaderNote = $("#payrollHeader").outerHeight(true) - 50 + payrollNote;
        } else {
          payrollHeaderNote = $("#payrollHeader").outerHeight(true) + 0 + payrollNote;
        }
      } else {
        payrollHeaderNote = $("#payrollHeader").outerHeight(true) + 0 + payrollNote;
      }
    } else {
      payrollHeaderNote = 0;
    }

    if ($(".gallery-tabs-parent").length) {
      galleryTabMenu = $(".gallery-tabs-parent").outerHeight(true);
    } else {
      galleryTabMenu = 0;
    }

    if ($(".payroll_note").length) {
      payrollTabmenus = $(".payroll_note").outerHeight(true);
    } else {
      payrollTabmenus = 0;
    }

    calculateTableHeight = windowHeight - elementHeight - exportfoot - payrollHeaderNote - galleryTabMenu - payrollTabmenus;
    $(".tableScrollSinglePayroll").css("height", calculateTableHeight);
  },
  setTableHeightReport: () => {
    var vHeight = $(window).height();
    // var ele1Height = $(".report-searchbox").outerHeight(true);
    var ele1Height;
    if ($(".report-searchbox").length) {
      ele1Height = $(".report-searchbox").outerHeight(true);
    } else {
      ele1Height = 0;
    }
    var Exportfoot;
    if ($(".exportfootSection").length) {
      Exportfoot = $(".exportfootSection").outerHeight(true);
    } else {
      Exportfoot = 0;
    }
    var salessummarynotes;
    if ($(".tablenote").length) {
      salessummarynotes = $(".tablenote").outerHeight(true);
    } else {
      salessummarynotes = 0;
    }
    var pointsSummary;
    if ($(".pointsSummary").length) {
      pointsSummary = $(".pointsSummary").outerHeight(true);
    } else {
      pointsSummary = 0;
    }

    var addDeviceTopheader;
    addDeviceTopheader = 0;
    if (navigator != null && navigator.userAgent != null && navigator.userAgent != "") {
      if ((navigator.userAgent.indexOf("com.vagaro") > -1)) {
        addDeviceTopheader = 50;
      }
    } else {
      addDeviceTopheader = 0;
    }

    var addtimecard;
    if ($(".addtimecard").length) {
      addtimecard = $(".addtimecard").outerHeight(true);
    } else {
      addtimecard = 0;
    }

    var addIouSection;
    if ($(".addIouSection").length) {
      addIouSection = $(".addIouSection").outerHeight(true);
    } else {
      addIouSection = 0;
    }
    var ChartTab;
    if ($(".chart-tab").length) {
      var sourceTab;
      if ($(".sourceTabs").length) {
        sourceTab = -40;
      } else {
        sourceTab = 0;
      }
      ChartTab = $(".chart-tab").outerHeight(true) + 12 + sourceTab;
    } else {
      ChartTab = 0;
    }
    var payRollNote;
    if ($(".payroll_note").length) {
      payRollNote = $(".payroll_note").outerHeight(true) + 10;
    } else {
      payRollNote = 0
    }
    var payRollHead;
    if ($("#payrollHeader").length) {
      if (navigator != null && navigator.userAgent != null && navigator.userAgent != "") {
        if ((navigator.userAgent.indexOf("com.vagaro") > -1)) {
          payRollHead = $("#payrollHeader").outerHeight(true) - 50 + payRollNote;
        }
        else {
          payRollHead = $("#payrollHeader").outerHeight(true) + 0 + payRollNote;
        }
      }
      else {
        payRollHead = $("#payrollHeader").outerHeight(true) + 0 + payRollNote;
      }

    } else {
      payRollHead = 0;
    }

    var failedPaymentHead;
    if ($("#failedPaymentHeader thead").length) {
      failedPaymentHead = $("#failedPaymentHeader thead").outerHeight(true);
      if (navigator != null && navigator.userAgent != null && navigator.userAgent != "") {
        if ((navigator.userAgent.indexOf("com.vagaro") > -1)) {
          failedPaymentHead = $("#failedPaymentHeader thead").outerHeight(true) - 45;
        } else {
          failedPaymentHead = $("#failedPaymentHeader thead").outerHeight(true) - 70;
        }
      }
      else {
        failedPaymentHead = $("#failedPaymentHeader thead").outerHeight(true) - 70;
      }
    }
    else {
      failedPaymentHead = 0
    }

    var membershipHead;
    if ($("#membershipHeader thead").length) {
      if (navigator != null && navigator.userAgent != null && navigator.userAgent != "") {
        if ((navigator.userAgent.indexOf("com.vagaro") > -1)) {
          membershipHead = $("#membershipHeader thead").outerHeight(true) - 45;
        } else {
          membershipHead = $("#membershipHeader thead").outerHeight(true) - 85;
        }
      }
      else {
        membershipHead = $("#membershipHeader thead").outerHeight(true) - 85;
      }
    }
    else {
      membershipHead = 0
    }

    var mergeCustomerHeader;
    if ($(".w-settingHeadblock").length) {
      mergeCustomerHeader = $(".w-settingHeadblock").outerHeight(true);
    } else {
      mergeCustomerHeader = 0;
    }


    var PayrollGridTabScroll;
    if ($(".payrollGridTabscroll").length) {
      PayrollGridTabScroll = $(".payrollGridTabscroll").outerHeight(true);
    } else {
      PayrollGridTabScroll = 0;
    }

    var DivemployeesalaryPayscroll;
    if ($(".divemployeesalarypayscroll").length) {
      DivemployeesalaryPayscroll = $(".divemployeesalarypayscroll").outerHeight(true);
    } else {
      DivemployeesalaryPayscroll = 0;
    }

    var EditOnboardingDataDiv;
    if ($(".editonboardingData").length) {
      DivemployeesalaryPayscroll = $(".editonboardingData").outerHeight(true);
    } else {
      EditOnboardingDataDiv = 0;
    }

    var mergectmCheck;
    if ($(".mergectm_check").length) {
      mergectmCheck = $(".mergectm_check").outerHeight(true) + 40;
    } else {
      mergectmCheck = 0;
    }

    var mergecustosearchTop;
    if ($(".mergecustosearch").length) {
      mergecustosearchTop = $(".mergecustosearch").outerHeight(true);
    } else {
      mergecustosearchTop = 0;
    }
    var Drawerlist;
    if ($(".vg-fixtop-offset").length) {
      Drawerlist = $(".vg-fixtop-offset").outerHeight(true) + 12;
    } else {
      Drawerlist = 0;
    }
    var calTableHeight = vHeight - ele1Height - Exportfoot - salessummarynotes - pointsSummary - addtimecard - addIouSection - ChartTab - payRollHead - mergecustosearchTop - mergeCustomerHeader + failedPaymentHead + membershipHead - mergectmCheck - Drawerlist - PayrollGridTabScroll - DivemployeesalaryPayscroll - EditOnboardingDataDiv - 55;
    $(".tableScrollReport").css("height", calTableHeight + addDeviceTopheader);
    $(".tableScrolldrawer").css("max-height", calTableHeight);
    $(".tableScrollReport1").css("height", calTableHeight);
  },
  setTableHeightReportTra: () => {
    var vHeight = $(window).height();
    // var ele1Height = $(".report-searchbox").outerHeight(true);
    // var ele1Height;

    var Exportfoot;
    if ($(".exportfootSection").length) {
      Exportfoot = $(".exportfootSection").outerHeight(true);
    } else {
      Exportfoot = 0;
    }
    var Drawerlist;
    if ($(".vg-fixtop-offset").length) {
      Drawerlist = $(".vg-fixtop-offset").outerHeight(true) + 12;
    } else {
      Drawerlist = 0;
    }
    var calTableHeight = vHeight - Exportfoot - Drawerlist - 162;
    $(".tableScrollReport").css("height", calTableHeight);
  },
  setCustomerTableHeightReport: () => {
    var vHeight = $(window).height();
    // var ele1Height = $(".report-searchbox").outerHeight(true);
    var ele1Height;
    if ($(".runReport").length) {
      ele1Height = $(".runReport").outerHeight(true);
    } else {
      ele1Height = 0;
    }
    var Exportfoot;
    if ($(".exportfootSection").length) {
      Exportfoot = $(".exportfootSection").outerHeight(true);
    } else {
      Exportfoot = 0;
    }
    var addDeviceTopheader;
    addDeviceTopheader = 0;
    if (navigator != null && navigator.userAgent != null && navigator.userAgent != "") {
      if ((navigator.userAgent.indexOf("com.vagaro") > -1)) {
        addDeviceTopheader = 50;
      }
    } else {
      addDeviceTopheader = 0;
    }
    var ChartTab;
    if ($(".chart-tab").length) {
      var sourceTab;
      if ($(".sourceTabs").length) {
        sourceTab = -40;
      } else {
        sourceTab = 0;
      }
      ChartTab = $(".chart-tab").outerHeight(true) + 12 + sourceTab;
    } else {
      ChartTab = 0;
    }
    var addIouSection;
    if ($(".addIouSection").length) {
      addIouSection = $(".addIouSection").outerHeight(true);
    } else {
      addIouSection = 0;
    }
    var mergeCustomerHeader;
    if ($(".w-settingHeadblock").length) {
      mergeCustomerHeader = $(".w-settingHeadblock").outerHeight(true);
    } else {
      mergeCustomerHeader = 0;
    }

    var mergectmCheck;
    if ($(".mergectm_check").length) {
      mergectmCheck = $(".mergectm_check").outerHeight(true) + 40;
    } else {
      mergectmCheck = 0;
    }

    var mergecustosearchTop;
    if ($(".mergecustosearch").length) {
      mergecustosearchTop = $(".mergecustosearch").outerHeight(true);
    } else {
      mergecustosearchTop = 0;
    }
    var ctmheightAdjust;
    if ($(".advancesalesfilter").length) {
      ctmheightAdjust = $(".advancesalesfilter").outerHeight(true) + 15;
    } else {
      ctmheightAdjust = 0;
    }

    var calTableHeight = vHeight - ele1Height - Exportfoot - ctmheightAdjust - addIouSection - ChartTab - mergecustosearchTop - mergeCustomerHeader + - mergectmCheck - 55;
    $(".tableScrollReport").css("height", calTableHeight + addDeviceTopheader);
  },
  setTableHeightCombineReport: () => {
    var vHeight = $(window).height();

    var ele1Height;
    if ($(".report-searchbox").length) {
      ele1Height = $(".report-searchbox").outerHeight(true);
    }
    else {
      ele1Height = 0;
    }
    var Exportfoot;
    if ($(".exportfootSection").length) {
      Exportfoot = $(".exportfootSection").outerHeight(true);
    } else {
      Exportfoot = 0;
    }
    var addIouSection;
    if ($(".addIouSection").length) {
      addIouSection = $(".addIouSection").outerHeight(true);
    } else {
      addIouSection = 0;
    }
    var ChartTab;
    if ($(".chart-tab").length) {
      ChartTab = $(".chart-tab").outerHeight(true) + 12;
    } else {
      ChartTab = 0;
    }

    var calTableHeight = vHeight - ele1Height - Exportfoot - addIouSection - ChartTab - 55;
    $(".tableScrollReport").css("height", calTableHeight);
    $(".tableScrollmergecustomer").css("height", calTableHeight + 10);
  },

  setTableHeightReport2: () => {
    var vHeight = $(window).height();
    var ele1Height = $(".report-searchbox").outerHeight(true);
    var Exportfoot;
    if ($(".exportfootSection").length) {
      Exportfoot = $(".exportfootSection").outerHeight(true);
    } else {
      Exportfoot = 0;
    }
    var salessummarynotes;
    if ($(".tablenote").length) {
      salessummarynotes = $(".tablenote").outerHeight(true);
    } else {
      salessummarynotes = 0;
    }
    var pointsSummary;
    if ($(".pointsSummary").length) {
      pointsSummary = $(".pointsSummary").outerHeight(true);
    } else {
      pointsSummary = 0;
    }
    var addIouSection;
    if ($(".addIouSection").length) {
      addIouSection = $(".addIouSection").outerHeight(true);
    } else {
      addIouSection = 0;
    }
    var ChartTab;
    if ($(".chart-tab").length) {
      ChartTab = $(".chart-tab").outerHeight(true) + 12;
    } else {
      ChartTab = 0;
    }
    var calTableHeight = vHeight - ele1Height - Exportfoot - salessummarynotes - pointsSummary - addIouSection - ChartTab - 87;
    $(".tableScrollReport").css("height", calTableHeight);
  },
  setAppointmentTableHeightReport: () => {
    var vHeight = $(window).height();
    var ele1Height = $(".report-searchbox").outerHeight(true);
    var Exportfoot;
    if ($(".exportfootSection").length) {
      Exportfoot = $(".exportfootSection").outerHeight(true);
    } else {
      Exportfoot = 0;
    }
    var filterSearch;
    if ($(".report-searchbox").length) {
      filterSearch = $(".report-searchbox").outerHeight(true) + 12;
    } else {
      filterSearch = 0;
    }
    var salessummarynotes;
    if ($(".tablenote").length) {
      salessummarynotes = $(".tablenote").outerHeight(true);
    } else {
      salessummarynotes = 0;
    }




    var ChartTab;
    if ($(".chart-tab").length) {
      ChartTab = $(".chart-tab").outerHeight(true) + 12;
    } else {
      ChartTab = 0;
    }
    var calTableHeight = vHeight - filterSearch - Exportfoot - ChartTab - 55;
    $(".tableScrollReport").css("height", calTableHeight);
  },

  setTableHeightReportSubmitPopup: () => {
    let vHeight = $("#submitPayrollPopup").height();
    let modalHeader;
    let reviewPayroll;
    let block;
    let modalFooter;
    let debitByGustoTable;

    if ($(".modal-header").length) {
      modalHeader = $(".modal-header").outerHeight(true);
    } else {
      modalHeader = 0;
    }

    if ($(".reviewPayroll").length) {
      reviewPayroll = $(".reviewPayroll").outerHeight(true);
    } else {
      reviewPayroll = 0;
    }

    if ($(".block").length) {
      block = $(".block").outerHeight(true);
    } else {
      block = 0;
    }

    if ($(".modal-footer").length) {
      modalFooter = $(".modal-footer").outerHeight(true);
    } else {
      modalFooter = 0;
    }

    if ($(".debitbygustotable").length) {
      debitByGustoTable = $(".debitbygustotable").outerHeight(true);
    } else {
      debitByGustoTable = 0;
    }

    let calculatePopupHeight = vHeight - modalHeader - reviewPayroll - block - modalFooter - debitByGustoTable - 40;

    $(".tablePopupReport").css("height", calculatePopupHeight);
    $(".tablePopupReportTaxed").css("height", calculatePopupHeight);
  },

  setTableHeightReportpayroll: () => {
    var vHeight = $(window).height();
    var ele1Height = $(".report-searchbox").outerHeight(true);
    var Exportfoot;
    if ($(".exportfootSection").length) {
      Exportfoot = $(".exportfootSection").outerHeight(true);
    } else {
      Exportfoot = 0;
    }


    var weeklypayrollrow;
    if ($("#payrollHeader").length) {
      weeklypayrollrow = $("#payrollHeader").outerHeight(true);
    } else {
      weeklypayrollrow = 0;
    }

    var paysandtaxtabrow;
    if ($(".paysandtaxtab").length) {
      paysandtaxtabrow = $(".paysandtaxtab").outerHeight(true);
    } else {
      paysandtaxtabrow = 0;
    }

    var calTableHeight = vHeight - ele1Height - weeklypayrollrow - paysandtaxtabrow - Exportfoot - 70;
    $(".tableScrollReport").css("height", calTableHeight);
  },
  setTableHeightReportpayrollHistory: () => {
    var vHeight = $(window).height();
    var ele1Height = $(".report-searchbox").outerHeight(true);
    var Exportfoot;
    if ($(".exportfootSection").length) {
      Exportfoot = $(".exportfootSection").outerHeight(true);
    } else {
      Exportfoot = 0;
    }


    var weeklypayrollrow;
    if ($("#payrollHeader").length) {
      weeklypayrollrow = $("#payrollHeader").outerHeight(true);
    } else {
      weeklypayrollrow = 0;
    }

    var paysandtaxtabrow;
    if ($(".paysandtaxtab").length) {
      paysandtaxtabrow = $(".paysandtaxtab").outerHeight(true);
    } else {
      paysandtaxtabrow = 0;
    }

    var calTableHeight = vHeight - ele1Height - weeklypayrollrow - paysandtaxtabrow - Exportfoot - 80;

    $(".tableScrollReport").css("height", calTableHeight);
  },
  setAppointmentTableHeightReport: () => {
    var vHeight = $(window).height();
    var ele1Height = $(".report-searchbox").outerHeight(true);
    var Exportfoot;
    if ($(".exportfootSection").length) {
      Exportfoot = $(".exportfootSection").outerHeight(true);
    } else {
      Exportfoot = 0;
    }
    var filterSearch;
    if ($(".report-searchbox").length) {
      filterSearch = $(".report-searchbox").outerHeight(true) + 12;
    } else {
      filterSearch = 0;
    }
    var salessummarynotes;
    if ($(".tablenote").length) {
      salessummarynotes = $(".tablenote").outerHeight(true);
    } else {
      salessummarynotes = 0;
    }
    var ChartTab;
    if ($(".chart-tab").length) {
      ChartTab = $(".chart-tab").outerHeight(true) + 12;
    } else {
      ChartTab = 0;
    }
    var calTableHeight = vHeight - filterSearch - Exportfoot - ChartTab - 55;
    $(".tableScrollReport").css("height", calTableHeight);
  },





  setTableNoRecordFoundHeightReport: () => {
    $(".noreacordmodalTableScroll").css("height", noRecHeight).css("min-height", 200);
  },
  InventoryFormatAmount: (val, CurrencySymbol) => {
    if (typeof val != undefined && val != null && val != "") {
      let amt = parseFloat(val);
      return (
        CurrencySymbol +
        amt.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      );
    } else {
      let amt = parseFloat(0);
      return (
        CurrencySymbol +
        amt.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      );
    }
  },
  SetPageTitle: (title) => {
    var titleData = '5002|~|' + title;
    if (navigator.userAgent.toLowerCase().indexOf("safari") < 0 && (navigator.userAgent.toLowerCase().indexOf("iphone") > -1 || navigator.userAgent.toLowerCase().indexOf("ipad") > -1)) {
      setTimeout(function () {
        window.location = "js-call:myObjectiveCFunction:" + titleData;
      }, 1000);
    }
    else if (navigator.userAgent.toLowerCase().indexOf("android") > -1 && navigator.userAgent.toLowerCase().indexOf("vagaropro") > -1) {
      if (!(window.JSInterface == undefined))
        window.JSInterface.CallWSMethod(titleData);
    }
    else {
      document.title = "VAGARO" + ((title == undefined || title == null) ? "" : " | " + title);
      if ($('.nav-link.active').length > 0) {
        utils.ScrollLeftNavBar();
      }
    }
  },
  ScrollLeftNavBar: () => {
    try {
      var arr;
      arr = window.location.pathname.split('/');
      arr = (arr[arr.length - 1] == undefined || arr[arr.length - 1] == null) ? "" : arr[arr.length - 1].toLowerCase();
      var id = $('div#' + arr);
      var scrollTo = $('div#' + arr);
      var container = $('#sideBar');
      if ((scrollTo.offset().top - 230) > 0) {
        $('#sideBar').animate({ scrollTop: scrollTo.offset().top - container.offset().top + container.scrollTop() }, 'slow');
      }
    }
    catch (error) {

    }
  },
  ScrollingTop: (TableId) => {
    var leftposition = $("." + TableId).position();
    if (
      typeof leftposition != "undefined" &&
      leftposition != null &&
      leftposition != ""
    ) {
      $(".report-content").animate(
        { scrollTop: leftposition.top - 100 },
        100
      );
    } else {
      $(".report-content").animate(
        { scrollTop: $(".report-content").width() + 700 },
        100
      );
    }
  },
  sticky_relocate: () => {
    var arrow = $('.vg-table-arrow');
    if ($('.table-responsive').offset().top <= $('.fullreport ').offset().top) {
      arrow.addClass('vg-arrow-mobile');
      arrow.css({ "position": "fixed", "top": $('.fullreport ').offset().top + 2 + "px", "margin-right": "1.5%", "right": "12px" });
    }
    else {
      arrow.removeClass('vg-arrow-mobile');
      arrow.removeAttr("style")
    }
    $('.vg-show-filter').slideUp('slow');
    $("#advancedFiltershow1 > span").removeClass("fa-angle-up").addClass("fa-angle-down");
  },
  appInsightTrack: (businessID, loginUserID, report, objectParameter, Message) => {
    var objdata = {};
    objdata.Name = "React Report";
    objdata.Bid = businessID;
    objdata.Userid = loginUserID;
    objdata.Description = Message;
    objdata.Fname = report;
    objdata.Parameters = JSON.stringify(
      objectParameter
    );
    appInsightTrackEvent(objdata);
  },
  ReactvalidateDecimal: (textbox, entryMode, decimalPlaces, callbackAmount) => {
    nDotCount = 0;
    var i = 0;
    var appendZerosString;
    textbox.value = ReactcheckMaxLength(
      ReactstripChars(textbox.value, entryMode),
      textbox.maxLength
    );
    if (entryMode == 1 && ReactidentifyDot(textbox.value) == true) {
      if (textbox.value == ".") textbox.value = "0.00";
      textbox.value =
        Math.round(textbox.value * Math.pow(10, decimalPlaces)) /
        Math.pow(10, decimalPlaces);

      if (ReactidentifyDot(textbox.value) == false) {
        if (decimalPlaces > 0) appendZerosString = ".";
        else appendZerosString = "";
        for (i = 0; i < decimalPlaces; i++) {
          appendZerosString = appendZerosString + "0";
        }
        var tmpApp = textbox.value + appendZerosString;
        if (parseInt(tmpApp.length) <= parseInt(textbox.maxLength))
          textbox.value = textbox.value + appendZerosString;

      } else {
        if (
          textbox.value.length - 1 - ReactidentifyDotPosition(textbox.value) <
          decimalPlaces
        ) {
          appendZerosString = "";
          for (
            i = textbox.value.length - 1 - ReactidentifyDotPosition(textbox.value);
            i < decimalPlaces;
            i++
          ) {
            appendZerosString = appendZerosString + "0";
          }
          var tmpApp = textbox.value + appendZerosString;
          if (parseInt(tmpApp.length) <= parseInt(textbox.maxLength))
            textbox.value = textbox.value + appendZerosString;
        }
      }
    } else if (entryMode == 1 && ReactidentifyDot(textbox.value) == false) {
      textbox.value =
        Math.round(textbox.value * Math.pow(10, decimalPlaces)) /
        Math.pow(10, decimalPlaces);
      if (decimalPlaces > 0) appendZerosString = ".";
      else appendZerosString = "";
      for (i = 0; i < decimalPlaces; i++) {
        appendZerosString = appendZerosString + "0";
      }
      var tmpApp = textbox.value + appendZerosString;
      if (parseInt(tmpApp.length) <= parseInt(textbox.maxLength))
        textbox.value = textbox.value + appendZerosString;
    }
    callbackAmount(textbox.value)

  },
  ExpandAllGridColumn: (elem) => {
    elem = $(elem.currentTarget);
    if ($(elem).find('span').hasClass('glyphicon-chevron-right')) {
      $(elem).find('span').removeClass('glyphicon-chevron-right').addClass('glyphicon-chevron-left');
      $('.grid-contents').animate({ scrollLeft: $('.grid-contents').width() + 1200 }, 'slow');
    }
    else {
      $(elem).find('span').removeClass('glyphicon-chevron-left').addClass('glyphicon-chevron-right');
      $('.grid-contents').animate({ scrollLeft: 0 }, 'fast');
    }
  },
  getViewport: () => {
    var viewPortWidth;
    var viewPortHeight;
    if (typeof window.innerWidth != "undefined") {
      viewPortWidth = window.innerWidth;
      viewPortHeight = window.innerHeight;
    }
    else if (
      typeof document.documentElement != "undefined" &&
      typeof document.documentElement.clientWidth != "undefined" &&
      document.documentElement.clientWidth !== 0
    ) {
      viewPortWidth = document.documentElement.clientWidth;
      viewPortHeight = document.documentElement.clientHeight;
    }
    else {
      viewPortWidth = document.getElementsByTagName("body")[0].clientWidth;
      viewPortHeight = document.getElementsByTagName("body")[0].clientHeight;
    }
    var tblWidth = $(".vg-table").width();
    if (tblWidth !== undefined && tblWidth !== "undefined") {
      viewPortWidth = viewPortWidth - $("#sideBar").width();
      if (tblWidth > viewPortWidth) {
        $("#divexpandArrow").css("display", "block");
      } else {
        $("#divexpandArrow").css("display", "none");
      }
    }
  },
  getPositiveValue: (input) => {
    if (!input) {
      return 0;
    }
    return Math.abs(input);
  },
  getInventroyValue: (val, CurrencySymbol) => {
    return (
      (val < 0 ? "-" : "") +
      CurrencySymbol +
      (val < 0
        ? utils.getPositiveValue(val).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
        : val.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }))
    );
  },

  // Mobile Number Format
  formatMobileNumber: (number, countryId) => {
    if (number != null && typeof number != "undefined") {
      number = (number + "").trim();
      countryId =
        typeof countryId != "undefined" &&
          countryId != null &&
          countryId * 1 > 0
          ? countryId
          : 1;
      var onlyDigit = ("" + number).replace(/\D/g, "");
      var origLen = onlyDigit.length;
      if (origLen > 8) {
        //US
        var m = onlyDigit.match(/^(\d{3})(\d{3})(\d{4})$/);

        if (countryId === 2) {
          //UK
          if (origLen === 10) {
            onlyDigit = "0" + onlyDigit;
          }
          var arrNumber = onlyDigit.match(/^(\d{3})(\d{4})(\d{3}|\d{4})$/);
          number = !arrNumber
            ? number + ".."
            : "" + arrNumber[1] + " " + arrNumber[2] + " " + arrNumber[3];
        } else if (countryId === 3) {
          // Canada
          number = !m ? number + ".." : m[1] + "-" + m[2] + "-" + m[3];
        } else if (countryId === 4) {
          // Australia
          if (origLen === 9) {
            onlyDigit = "0" + onlyDigit;
          }
          var arrNumber = onlyDigit.match(
            /^(\d{2})(\d{4})(\d{3}|\d{4}|\d{5})$/
          );
          number = !arrNumber
            ? number + ".."
            : "(" + arrNumber[1] + ") " + arrNumber[2] + " " + arrNumber[3];
        } else {
          //attach ".." if len is more than fixedLen
          number = !m ? number + ".." : "(" + m[1] + ") " + m[2] + "-" + m[3];
        }
      }
    }
    return number;
  },
  CheckIsFromProApp: (setisandroidiospro) => {
    if (
      navigator != null &&
      navigator.userAgent != null &&
      navigator.userAgent != ""
    ) {
      if (navigator.userAgent.indexOf("VagaroAndroidPhone") > -1 || (navigator.userAgent.indexOf("com.vagaro.iospro") > -1 && navigator.userAgent.indexOf("iPhone") > -1)) {
        $("body").addClass("vg_ismobile");
        isandroidiospro = true;
        return setisandroidiospro(true);
      }
      else {
        $("body").removeClass("vg_ismobile");
        isandroidiospro = false;
        return setisandroidiospro(false);
      }
    }
    else {
      return setisandroidiospro(false);
    }
  },
  CheckIsFromProAppWithTablet: (setisandroidiosprowithtablet) => {

    if (
      navigator != null &&
      navigator.userAgent != null &&
      navigator.userAgent != ""
    ) {
      if ((navigator.userAgent.indexOf("com.vagaro") > -1)) {
        return setisandroidiosprowithtablet(true);
      }
      else {
        return setisandroidiosprowithtablet(false);

      }
    }
    else {
      return setisandroidiosprowithtablet(false);
    }
  },
  CheckIsIphoneIospro: (setisiphonepro) => {
    if (
      navigator != null &&
      navigator.userAgent != null &&
      navigator.userAgent != ""
    ) {
      if ((navigator.userAgent.indexOf("com.vagaro.iospro") > -1 && navigator.userAgent.indexOf("iPhone") > -1)) {
        $("body").addClass("vg_ismobileiosonly")
        return setisiphonepro(true);
      }
      else {
        $("body").removeClass("vg_ismobileiosonly")
        return setisiphonepro(false);
      }
    }
    else {
      return setisiphonepro(false);
    }
  },
  CheckIsFromIpadAndroidTab: (setIsIpadAndroid) => {
    if (
      navigator != null &&
      navigator.userAgent != null &&
      navigator.userAgent != ""
    ) {
      var iPadAndroid = navigator.userAgent.match(/ipad/i) || navigator.userAgent.match(/iphone/i) || navigator.userAgent.match(/itouch/i) || navigator.userAgent.match(/android/i) || false;
      if ((navigator.userAgent.toLowerCase().indexOf("com.vagaro.iospro") > -1 || navigator.userAgent.toLowerCase().indexOf("com.vagaro.androidpro") > -1) && iPadAndroid !== false) {
        return setIsIpadAndroid(true);
      }
      else {
        return setIsIpadAndroid(false);
      }
    }
    else {
      return setIsIpadAndroid(false);
    }
  },
  GetProAppVersion: (setAppVersion) => {
    var _getVersion = null;
    if (navigator != null && navigator.userAgent != null && navigator.userAgent != "") {
      if (navigator.userAgent.indexOf("VagaroAndroidPhone") > -1 || (navigator.userAgent.indexOf("com.vagaro.iospro") > -1 && navigator.userAgent.indexOf("iPhone") > -1) || (navigator.userAgent.indexOf("com.vagaro.androidpro") > -1 || navigator.userAgent.indexOf("VagaroAndroidTablet") > -1 || navigator.userAgent.toLowerCase().indexOf("ipad") > -1)) {
        var _spliltUserAgent = navigator.userAgent.split("(VagaroPro");
        if (_spliltUserAgent.length > 0 && navigator.userAgent.indexOf("VagaroPro") > -1) {
          var _arrVersion = _spliltUserAgent[1].split(')')[0];

          if (_arrVersion != null && typeof _arrVersion != 'undefined' && _arrVersion.length > 0) {
            var _replaceVersion = _arrVersion.replace(')', '').replace(' ', '');
            if (_replaceVersion.split('.').length < 3) {
              _getVersion = _replaceVersion.replace('.', '') + '0';
            }
            else {
              _getVersion = _replaceVersion.replace('.', '').replace('.', '');
            }
          }
        }
        getVersion = _getVersion;
        return setAppVersion(_getVersion);
      }
    }
  },

  CallBackGivenToMobileApp: (NativeActionVal, PageTitle, Footer, TimerCount, IsFullLength) => {
    if (
      navigator != null &&
      navigator.userAgent != null &&
      navigator.userAgent != ""
    ) {
      var jsonData = JSON.stringify({ NativeAction: NativeActionVal, Footer: Footer, IsFullLength: IsFullLength }).replace(/\\\"/g, '"').replace(/"/g, "\\\"");
      var message = '{"message": "","messageType": 0,"screenTitle":"' + PageTitle + '","screenType": 0,"navType": 0,"action": "53|~|' + jsonData + '" }';
      var objData = '5006||~||' + message;

      if (navigator.userAgent.indexOf("VagaroAndroidPhone") > -1 || navigator.userAgent.indexOf("Android") > -1 || navigator.userAgent.indexOf("VagaroAndroidTablet") > -1) {
        if (!(window.JSInterface === undefined))
          window.JSInterface.CallWSMethod(objData);
      }
      else if ((navigator.userAgent.indexOf("com.vagaro.iospro") > -1 || navigator.userAgent.indexOf("iPhone") > -1 || isIpad || isIpadold)) {
        setTimeout(() => { window.location = "js-call:myObjectiveCFunction:" + objData }, TimerCount);
      }
    }
  },

  CallBackGivenToMobile: (action, arr, navType = "") => {
    if (
      navigator != null &&
      navigator.userAgent != null &&
      navigator.userAgent != ""
    ) {
      var jsonData = "";
      if (action === MobileCallBackAction.RedirectToLink || action === MobileCallBackAction.PhoneNumber || action === MobileCallBackAction.DownlodImage || action === MobileCallBackAction.DownlodVideo || action === MobileCallBackAction.PalyVideoPlayer || action === MobileCallBackAction.CopyText) {
        jsonData = arr;
      } else {
        jsonData = JSON.stringify(arr).replace(/\\\"/g, '"').replace(/"/g, "\\\"");
      }
      var message = '{"message": "","messageType": 0,"screenTitle":"","screenType": 0,"navType": 0,"action": "' + action + '|~|' + jsonData + '" }'
      if (navType === "Email") {
        message = '{"message": "","messageType": 0,"screenTitle":"","screenType": 0,"navType": 53,"action": "' + action + '" }'
      }
      var objData = '5006||~||' + message;
      console.log(objData);
      if (navigator.userAgent.indexOf("VagaroAndroidPhone") > -1 || navigator.userAgent.indexOf("Android") > -1 || navigator.userAgent.indexOf("VagaroAndroidTablet") > -1) {
        if (!(window.JSInterface === undefined))
          window.JSInterface.CallWSMethod(objData);
      }
      else if ((navigator.userAgent.indexOf("com.vagaro.iospro") > -1 || navigator.userAgent.indexOf("iPhone") > -1 || isIpad || isIpadold)) {
        if (navigator.userAgent.toLowerCase().indexOf("vagaroapp") > -1) {
          let msg = JSON.parse(message)
          let jsData = JSON.stringify(msg).replace(/\\\"/g, '"').replace(/"/g, '\\"');
          window.parent.postMessage('{"eventType": "mobilecallbackios","Data": "' + jsData + '"}', '*');
        } else {
          setTimeout(() => { window.location = "js-call:myObjectiveCFunction:" + objData }, 150);
        }
      }
    }
  },
  CallBackGivenToMobileRequestTab: () => {
    if (
      navigator != null &&
      navigator.userAgent != null &&
      navigator.userAgent != ""
    ) {
      var jsonData = JSON.stringify({ eventAction: 2 }).replace(/\\\"/g, '"').replace(/"/g, "\\\"");
      var message = '{"message": "","messageType": 0,"screenTitle":"","screenType": 0,"navType": 0,"action": "109|~|' + jsonData + '" }'
      var objData = '5006||~||' + message;
      if (navigator.userAgent.indexOf("VagaroAndroidPhone") > -1) {
        if (!(window.JSInterface === undefined))
          window.JSInterface.CallWSMethod(objData);
      }
      else if ((navigator.userAgent.indexOf("com.vagaro.iospro") > -1 && navigator.userAgent.indexOf("iPhone") > -1)) {
        setTimeout(() => { window.location = "js-call:myObjectiveCFunction:" + objData }, 100);
      }
    }
  },
  handleNativeTimePicker: (slotId, open_time) => {
    // var jsonData = JSON.stringify({ eventAction: "1", selectedActionTag: selectedAction, startDate: moment(fromDateMobile).format("YYYY/MM/DD"), endDate: moment(toDateMobile).format("YYYY/MM/DD"), businessIds: ReactBusinessId }).replace(/\\\"/g, '"').replace(/"/g, "\\\"")
    var message = `{"message": "","messageType": 0,"screenTitle":"","screenType": 0,"navType": 0,"action": "78|~|{\\"actionTag\\":${slotId}, \\"time\\":\\"${open_time}\\"}"}`;
    utils.MobileDatePicker(message);
  },
  getStatusText: (numberStatus) => {
    switch (numberStatus) {
      case 1:
      case 2:
        return "Pending Review";
      case 3:
        return "In Review";
      case 4:
        return "Approved";
      case 5:
        return "Rejected";
      default:
        return "Pending Review";
    }
  },
  CallBackGivenToMobileSelectTab: (tab) => {
    if (
      navigator != null &&
      navigator.userAgent != null &&
      navigator.userAgent !== "" &&
      !window.history.preventTabSelection
    ) {
      var selecteTab = tab === 2 ? 2 : 3;
      var jsonData = JSON.stringify({ eventAction: 1, selectedTab: selecteTab }).replace(/\\\"/g, '"').replace(/"/g, "\\\"");
      var message = '{"message": "","messageType": 0,"screenTitle":"","screenType": 0,"navType": 0,"action": "109|~|' + jsonData + '" }'
      var objData = '5006||~||' + message;
      if (navigator.userAgent.indexOf("VagaroAndroidPhone") > -1 || navigator.userAgent.indexOf("Android") > -1 || navigator.userAgent.indexOf("VagaroAndroidTablet") > -1) {
        if (!(window.JSInterface === undefined))
          window.JSInterface.CallWSMethod(objData);
      }
      else if ((navigator.userAgent.indexOf("com.vagaro.iospro") > -1 || navigator.userAgent.indexOf("iPhone") > -1 || isIpad || isIpadold)) {
        setTimeout(() => { window.location = "js-call:myObjectiveCFunction:" + objData }, 100);
      }
    }
  },
  stopTabSelection: () => {
    window.history.preventTabSelection = true;
    setTimeout(() => {
      window.history.preventTabSelection = null;
    }, 1000);
  },
  MultiLocationCallBack: (ReactBusinessId) => {
    if (navigator != null && navigator.userAgent != null && navigator.userAgent != "") {
      var selectedAction;
      if (typeof localStorage === 'object' && localStorage["selectedActionTag"] !== undefined && localStorage["selectedActionTag"] !== null) {
        selectedAction = localStorage.getItem("selectedActionTag");
      } else {
        selectedAction = "5";
      }
      var fromDateMobile = utils.StorageFromDate()
      var toDateMobile = utils.StorageToDate()
      var jsonData = JSON.stringify({ eventAction: "1", selectedActionTag: selectedAction, startDate: moment(fromDateMobile).format("YYYY/MM/DD"), endDate: moment(toDateMobile).format("YYYY/MM/DD"), businessIds: ReactBusinessId }).replace(/\\\"/g, '"').replace(/"/g, "\\\"")
      var message = '{"message": "","messageType": 0,"screenTitle":"","screenType": 0,"navType": 0,"action": "84|~|' + jsonData + '" }';
      if (navigator.userAgent.indexOf("VagaroAndroidPhone") > -1) {
        utils.MobileDatePicker(message);
      } else if ((navigator.userAgent.indexOf("com.vagaro.iospro") > -1 && navigator.userAgent.indexOf("iPhone") > -1)) {
        setTimeout(() => { utils.MobileDatePicker(message); }, 900);
      }
    }
  },
  MobileDatePicker: (message) => {
    if (
      navigator != null &&
      navigator.userAgent != null &&
      navigator.userAgent != ""
    ) {
      var objData = '5006||~||' + message;
      console.log("message", message);
      if (navigator.userAgent.indexOf("VagaroAndroidPhone") > -1 || navigator.userAgent.toLowerCase().indexOf("android") > -1) {
        if (!(window.JSInterface === undefined))
          window.JSInterface.CallWSMethod(objData);
      }
      else if ((navigator.userAgent.indexOf("com.vagaro.iospro") > -1 && navigator.userAgent.indexOf("iPhone") > -1) || navigator.userAgent.toLowerCase().indexOf("ipad") > -1) {
        window.location = "js-call:myObjectiveCFunction:" + objData;
      }
    }

  },
  GetQueryString: (key) => {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] == key) {
        return pair[1];
      }
    }
  },
  handleClickclose: (id, showHideFooter, IsFullLength, isFromBackIcon, isiphonepro) => {
    $(id).removeClass("showFilterMenu");
    $("body").removeClass("preventSrotBodyScroll");
    $(".mainContent").removeClass('iphonescrollPrevent');
    if (isFromBackIcon === true) {
      if (isiphonepro) {
        setTimeout(() => {
          var jsonData = JSON.stringify({ NativeAction: CallBackAction.ShowPrintSortByMenu, Footer: showHideFooter, IsFullLength: IsFullLength }).replace(/\\\"/g, '"').replace(/"/g, "\\\"");
          var message = '{"message": "","messageType": 0,"screenTitle": "","screenType": 0,"navType": 0,"action": "53|~|' + jsonData + '" }';
          utils.MobileDatePicker(message);
        }, sortTimeout);
      } else {
        var jsonData = JSON.stringify({ NativeAction: CallBackAction.ShowPrintSortByMenu, Footer: showHideFooter, IsFullLength: IsFullLength }).replace(/\\\"/g, '"').replace(/"/g, "\\\"");
        var message = '{"message": "","messageType": 0,"screenTitle": "","screenType": 0,"navType": 0,"action": "53|~|' + jsonData + '" }';
        utils.MobileDatePicker(message);
      }
    } else {
      var jsonData = JSON.stringify({ NativeAction: CallBackAction.ShowIconDetails, Footer: showHideFooter, IsFullLength: IsFullLength }).replace(/\\\"/g, '"').replace(/"/g, "\\\"");
      var message = '{"message": "","messageType": 0,"screenTitle": "","screenType": 0,"navType": 0,"action": "53|~|' + jsonData + '" }';
      utils.MobileDatePicker(message);
    }
  },
  GetTimePeriodForMbl: (retViewType) => {
    if (retViewType === "Y") {
      return 15;
    }
    else if (retViewType === "M") {
      return 16;
    }
    else if (retViewType === "W") {
      return 17;
    }
    else if (retViewType === "D") {
      return 18;
    }
    else if (retViewType === "H") {
      return 19;
    }
    else {
      return 20;
    }
  },
  isNullEmptyOrWhiteSpace: function (val) {
    if (typeof (val) == "number") {
      val = val.toString();
    }
    return (val === null || val === undefined || val === 'undefined' || val === "" || val.trim() === "");
  },
  replaceNullOrUndefined: function (val) {
    return (val == null || val == undefined) ? "" : val;
  },

  GetTimePeriodCallback: (ViewType) => {
    if (((navigator.userAgent.indexOf("com.vagaro.iospro") > -1 && navigator.userAgent.indexOf("iPhone") > -1) && getVersion > iosVersion) || (navigator.userAgent.indexOf("VagaroAndroidPhone") > -1 && getVersion > androidVersion)) {
      var jsonData = JSON.stringify({ NativeAction: CallBackAction.ChartTimeperiod, Timeperiod: utils.GetTimePeriodForMbl(ViewType), Footer: CallBackAction.HideFooter, IsFullLength: false }).replace(/\\\"/g, '"').replace(/"/g, "\\\"");
      var message = '{"message": "","messageType": 0,"screenTitle": "","screenType": 0,"navType": 0,"action": "53|~|' + jsonData + '" }';
      utils.MobileDatePicker(message);
    }
  },
  handleSorting: (id, outer, callfrom, isiphonepro, PageTitle, sortid, insidepopup) => {
    $("body").removeClass("preventSrotBodyScroll");
    $(".mainContent").removeClass('iphonescrollPrevent');
    if (outer === true) {
      if (callfrom === 2) {
        $(id).removeClass("showFilterMenu");
        if (sortid === undefined) {
          $("#divSortFunction").addClass("showFilterMenu");
        } else {
          $(sortid).addClass("showFilterMenu");
        }
        if (insidepopup) {
          utils.CallBackGivenToMobileApp(CallBackAction.ShowPrintSortByMenu, PageTitle, CallBackAction.HideFooter, secondCallTimer, true);
        }
      }
      else if (callfrom === 1) {
        $(id).removeClass("showFilterMenu");
      }
    }
    else {
      if (callfrom === 2) {
        $(id).removeClass("showFilterMenu");
        if (insidepopup) {
          utils.CallBackGivenToMobileApp(CallBackAction.ShowPrintSortByMenu, PageTitle, CallBackAction.HideFooter, secondCallTimer, true);
        }
        else {
          var jsonData = JSON.stringify({ NativeAction: CallBackAction.ShowResetMenu, Footer: CallBackAction.HideFooter, IsFullLength: false }).replace(/\\\"/g, '"').replace(/"/g, "\\\"");
          var message = '{"message": "","messageType": 0,"screenTitle": "","screenType": 0,"navType": 0,"action": "53|~|' + jsonData + '" }';
          utils.MobileDatePicker(message);
        }
      }
      else if (callfrom === 1) {
        $(id).removeClass("showFilterMenu");
      }
    }
  },
  creditCardIcon: (cardType) => {
    if (cardType !== null && cardType !== "") {
      if (cardType.toLowerCase() === "visa") {
        return <div className="cccard-icon visa">&#xf1f0;</div>
      } else if (cardType.toLowerCase() === "mastercard") {
        return <div className="cccard-icon master">&#xf1f1;</div>
      } else if (cardType.toLowerCase() === "americanexpress") {
        return <div className="cccard-icon amexcard">&#xf1f3;</div>
      } else if (cardType.toLowerCase() === "discover") {
        return <div className="cccard-icon discover">&#xf1f2;</div>
      } else {
        return <div className="cccard-icon">&nbsp;</div>
      }
    } else {
      return <div className="cccard-icon">&nbsp;</div>
    }
  },
  EmployeeRoleWiseBinding: (allServiceProvider, multiLoactionOption, loginBusinessId, isMultiLoaction, dropdownId, defaultSelection = [], isShowDefaultSelection = true) => {
    if (allServiceProvider !== undefined && multiLoactionOption !== undefined && loginBusinessId !== undefined && isMultiLoaction !== undefined && dropdownId !== undefined) {
      $("#" + dropdownId).empty();
      const EmployeeRoles = [];
      const RoleName = [];
      let businessIds = multiLoactionOption === "" ? loginBusinessId.toString() : multiLoactionOption;
      let Defualt = []
      if (isMultiLoaction === true) {
        allServiceProvider.map((column) => {
          for (let i = 0; i < businessIds.toString().split(",").length; i++) {
            let matchBusinessIds = businessIds.toString().split(",")[i]
            if (matchBusinessIds === column.bID.toString()) {
              let rolesIds = EmployeeRoles.filter(x => x.RoleID === column.RoleID);
              let RoleValue = RoleName.filter(x => (x.RoleName === column.RoleName));
              if (rolesIds.length === 0) {
                let header = {};
                header.value = column.RoleID;
                header.label = column.RoleName;
                header.RoleID = column.RoleID;
                header.RoleName = column.RoleName;
                header.IsParent = true;
                header.EncUserId = column.EncUserId;
                header.profile = column.profile;
                header.Avatarinital = column.Avatarinital;
                EmployeeRoles.push(header);
              }
              if (RoleValue.length === 0) {
                RoleName.push({ RoleName: column.RoleName })
              }
              let child = {};
              child.value = column.value;
              child.label = column.label;
              child.RoleID = column.RoleID;
              child.RoleName = column.RoleName;
              child.IsParent = false;
              child.EncUserId = column.EncUserId;
              child.profile = column.profile;
              child.Avatarinital = column.Avatarinital;
              child.defultSelected = false;

              var val = EmployeeRoles.filter((x) => (x.RoleID === column.RoleID && x.value === column.value));
              if (val.length <= 0) {
                EmployeeRoles.push(child);
              }
              if (defaultSelection.length > 0 && defaultSelection.includes(child.value)) {
                Defualt.push(child.RoleID);
                defaultSelection.push(child.RoleID);
              }
            }
          }
        })
        EmployeeRoles.sort((a, b) => a.RoleID - b.RoleID);
        let parent = EmployeeRoles.filter(x => (x.IsParent === true));
        for (let index = 0; index < parent.length; index++) {
          const ParentElement = parent[index];
          const selected = Defualt.includes(ParentElement.RoleID);
          if (selected && isShowDefaultSelection) {
            $("#" + dropdownId).append(`<option selected  id="option_${ParentElement.RoleID}" data-isheader="1" class="parent_p" data-is-parent="true" data-is-childs="false" data-childId="${ParentElement.RoleID}"  data-childIds="${ParentElement.RoleID}" data-groupid="${ParentElement.RoleID}" value="${ParentElement.RoleID}" data-profile="${ParentElement.profile}" data-Avatarinital="${ParentElement.Avatarinital}" data-defultselected="${true}">${(ParentElement.label)}</option>`);
          } else {
            $("#" + dropdownId).append(`<option id="option_${ParentElement.RoleID}" data-isheader="1" class="parent_p" data-is-parent="true" data-is-childs="false" data-childId="${ParentElement.RoleID}"  data-childIds="${ParentElement.RoleID}" data-groupid="${ParentElement.RoleID}" value="${ParentElement.RoleID}" data-profile="${ParentElement.profile}" data-Avatarinital="${ParentElement.Avatarinital}" data-defultselected="${false}">${(ParentElement.label)}</option>`);
          }

          let child = EmployeeRoles.filter(x => (x.IsParent === false && (x.RoleID.toString() === ParentElement.RoleID.toString() && x.value.toString() !== ParentElement.value.toString())));
          for (let innerIndex = 0; innerIndex < child.length; innerIndex++) {
            const chiledElement = child[innerIndex];
            const selected = defaultSelection.includes(chiledElement.value);
            if (selected && isShowDefaultSelection) {
              $("#" + dropdownId).append(`<option selected id="option_${chiledElement.RoleID + chiledElement.value}" data-childId=${chiledElement.value} data-is-childs="true" data-isheader="0" class="child_p" data-is-parent="false" data-groupid="${chiledElement.RoleID}" data-childIds="${chiledElement.value}" value="${chiledElement.value}" data-profile="${chiledElement.profile}" data-Avatarinital="${chiledElement.Avatarinital}" data-defultselected="${true}">${(chiledElement.label)}</option>`);
            } else {
              $("#" + dropdownId).append(`<option id="option_${chiledElement.RoleID + chiledElement.value}" data-childId=${chiledElement.value} data-is-childs="true" data-isheader="0" class="child_p" data-is-parent="false" data-groupid="${chiledElement.RoleID}" data-childIds="${chiledElement.value}" value="${chiledElement.value}" data-profile="${chiledElement.profile}" data-Avatarinital="${chiledElement.Avatarinital}" data-defultselected="${false}">${(chiledElement.label)}</option>`);
            }
          }
        }
        $("#" + dropdownId).val(defaultSelection).trigger('change');
      } else {
        allServiceProvider.map((column) => {
          for (let i = 0; i < column.bID.toString().split(",").length; i++) {
            let businessId = column.bID.toString().split(",")[i];
            if (loginBusinessId.toString() === businessId) {
              let rolesIds = EmployeeRoles.filter(x => x.RoleID === column.RoleID);
              let RoleValue = RoleName.filter(x => (x.RoleName === column.RoleName));
              if (rolesIds.length === 0) {
                let header = {};
                header.value = column.RoleID;
                header.label = column.RoleName;
                header.RoleID = column.RoleID;
                header.RoleName = column.RoleName;
                header.IsParent = true;
                header.EncUserId = column.EncUserId;
                header.profile = column.profile;
                header.Avatarinital = column.Avatarinital;
                EmployeeRoles.push(header);
              }
              if (RoleValue.length === 0) {
                RoleName.push({ RoleName: column.RoleName })
              }
              let child = {};
              child.value = column.value;
              child.label = column.label;
              child.RoleID = column.RoleID;
              child.RoleName = column.RoleName;
              child.IsParent = false;
              child.EncUserId = column.EncUserId;
              child.profile = column.profile;
              child.Avatarinital = column.Avatarinital;
              EmployeeRoles.push(child);
            }
          }
        })
        EmployeeRoles.sort((a, b) => a.RoleID - b.RoleID);
        let parent = EmployeeRoles.filter(x => (x.IsParent === true));
        for (let index = 0; index < parent.length; index++) {
          const ParentElement = parent[index];
          $("#" + dropdownId).append(`<option id="option_${ParentElement.RoleID}" data-isheader="1" class="parent_p" data-is-parent="true" data-is-childs="false" data-childId="${ParentElement.RoleID}"  data-childIds="${ParentElement.RoleID}" data-groupid="${ParentElement.RoleID}" value="${ParentElement.RoleID}" data-profile="${ParentElement.profile}" data-Avatarinital="${ParentElement.Avatarinital}">${(ParentElement.label)}</option>`);
          let child = EmployeeRoles.filter(x => (x.IsParent === false && (x.RoleID.toString() === ParentElement.RoleID.toString() && x.value.toString() !== ParentElement.value.toString())));
          for (let innerIndex = 0; innerIndex < child.length; innerIndex++) {
            const chiledElement = child[innerIndex];
            $("#" + dropdownId).append(`<option id="option_${chiledElement.RoleID + chiledElement.value}" data-childId=${chiledElement.value} data-is-childs="true" data-isheader="0" class="child_p" data-is-parent="false" data-groupid="${chiledElement.RoleID}" data-childIds="${chiledElement.value}" value="${chiledElement.value}" data-profile="${chiledElement.profile} data-Avatarinital="${chiledElement.Avatarinital}" >${(chiledElement.label)}</option>`);
          }
        }
      }
      return EmployeeRoles;
    }
  },
  FormatAppointments: (value, total) => {
    if (value != undefined && value != null && value != "" && value != 0) {
      if (total != undefined && total != null && total != "" && total != 0) {
        return (value + " (" + ((value * 100) / total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2, }) + "%" + ")")
      } else {
        return "0 (0.00%)"
      }
    } else {
      return "0 (0.00%)"
    }
  },
  AppointmentDateFilter: (apptToDate, apptFromDate) => {
    let dateList = {};
    if ($("#appointmentdaterange").val() !== '') {
      dateList.startDate = apptFromDate;
      dateList.endDate = apptToDate;
      localStorage.setItem("ApptDatePicker", JSON.stringify(dateList));
    }
    else {
      dateList.startDate = null;
      dateList.endDate = null;
      localStorage.setItem("ApptDatePicker", JSON.stringify(dateList));
    }
  },
  maskSensitiveData: (data, type) => {
    let maskedSymbol;

    if (type === 1) {
      maskedSymbol = ""
    } else if (type === 2) {
      maskedSymbol = "X"
    }

    const lastFourDigits = data.slice(-4);
    const maskedPart = maskedSymbol.repeat(data.length - 4);
    return maskedPart + lastFourDigits;
  },
  formatRelativeDate: (inputDate) => {
    const inputDateTime = new Date(inputDate);
    const currentDateTime = new Date();

    const timeDifference = currentDateTime - inputDateTime;
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (seconds < 60) {
      return "just now";
    } else if (minutes < 60) {
      return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    } else if (hours < 24) {
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else if (days < 7) {
      return `${days} day${days > 1 ? 's' : ''} ago`;
    } else if (weeks < 4) {
      return `${weeks} week${weeks > 1 ? 's' : ''} ago`;
    } else if (months < 12) {
      return `${months} month${months > 1 ? 's' : ''} ago`;
    } else {
      return `${years} year${years > 1 ? 's' : ''} ago`;
    }
  },
  formatDate: (inputDate) => {
    // Convert input string to Date object
    const dateObject = new Date(inputDate);

    // Define options for formatting
    const options = {
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };

    // Format the date using toLocaleString
    const formattedDate = dateObject.toLocaleString('en-US', options);

    return formattedDate;
  },
  setLetter: function (firstName, lastName) {
    let firstNameHasNumber = /^[0-9]+$/.test(firstName.charAt(0))
    let lastNameHasNumber = /^[0-9]+$/.test(lastName.charAt(0))
    let firstNameSpecialCharacter = /^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/.test(firstName.charAt(0))
    let lastNameSpecialCharacter = /^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/.test(lastName.charAt(0))
    if ((firstName.charAt(0) === firstName.charAt(0).toUpperCase() && lastName.charAt(0) === lastName.charAt(0).toUpperCase()) && (firstNameHasNumber !== true && lastNameHasNumber !== true) && (firstNameSpecialCharacter !== true && lastNameSpecialCharacter !== true)) {
      return (
        <Fragment>
          <div className="review-profile-inner-text vg-avatar-inital-inner-text">
            {firstName.charAt(0).toUpperCase()}
            {lastName.charAt(0).toUpperCase()}
          </div>
        </Fragment>
      );
    } else if (firstName.charAt(0) === firstName.charAt(0).toLowerCase() && lastName.charAt(0) === lastName.charAt(0).toLowerCase()) {
      return (
        <Fragment>
          <div className="review-profile-inner-text vg-avatar-inital-inner-text">
            {firstName.charAt(0).toUpperCase()}
          </div>
        </Fragment>
      );
    } else if (firstName.charAt(0) === firstName.charAt(0).toUpperCase() && lastName.charAt(0) === lastName.charAt(0).toLowerCase()) {
      return (
        <Fragment>
          <div className="review-profile-inner-text vg-avatar-inital-inner-text">
            {firstName.charAt(0).toUpperCase()}
          </div>
        </Fragment>
      );
    } else if (firstName.charAt(0) === firstName.charAt(0).toLowerCase() && lastName.charAt(0) === lastName.charAt(0).toUpperCase()) {
      return (
        <Fragment>
          <div className="review-profile-inner-text vg-avatar-inital-inner-text">
            {lastName.charAt(0).toUpperCase()}
          </div>
        </Fragment>
      );
    }
    else if ((firstName.charAt(0) === firstName.charAt(0).toUpperCase() || firstName.charAt(0) === firstName.charAt(0).toLowerCase()) && lastNameHasNumber === true) {
      <Fragment>
        <div className="review-profile-inner-text vg-avatar-inital-inner-text">
          {firstName.charAt(0).toUpperCase()}
        </div>
      </Fragment>
    } else if ((lastName.charAt(0) === lastName.charAt(0).toUpperCase() || lastName.charAt(0) === lastName.charAt(0).toLowerCase()) && firstNameHasNumber === true) {
      <Fragment>
        <div className="review-profile-inner-text vg-avatar-inital-inner-text">
          {lastName.charAt(0).toUpperCase()}
        </div>
      </Fragment>
    }
    else if ((firstName.charAt(0) === firstName.charAt(0).toUpperCase() || firstName.charAt(0) === firstName.charAt(0).toLowerCase()) && lastNameSpecialCharacter === true) {
      <Fragment>
        <div className="review-profile-inner-text vg-avatar-inital-inner-text">
          {firstName.charAt(0).toUpperCase()}
        </div>
      </Fragment>
    } else if ((lastName.charAt(0) === lastName.charAt(0).toUpperCase() || lastName.charAt(0) === lastName.charAt(0).toLowerCase()) && firstNameSpecialCharacter === true) {
      <Fragment>
        <div className="review-profile-inner-text vg-avatar-inital-inner-text">
          {lastName.charAt(0).toUpperCase()}
        </div>
      </Fragment>
    }
    else if (firstNameHasNumber === true && lastNameHasNumber === true) {
      return (
        <Fragment>
          <div className="review-profile-inner-text vg-avatar-inital-inner-text">
            {firstName.charAt(0)}
          </div>
        </Fragment>
      );
    } else if (firstNameSpecialCharacter === true && lastNameSpecialCharacter === true) {
      return (
        <Fragment>
          <div className="review-profile-inner-text vg-avatar-inital-inner-text">
            {firstName.charAt(0)}
          </div>
        </Fragment>
      );
    }
  },
  setLetter1: function (firstName, lastName) {
    let firstNameHasNumber = /^[0-9]+$/.test(firstName.charAt(0))
    let lastNameHasNumber = /^[0-9]+$/.test(lastName.charAt(0))
    let firstNameSpecialCharacter = /^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/.test(firstName.charAt(0))
    let lastNameSpecialCharacter = /^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/.test(lastName.charAt(0))
    if ((firstName.charAt(0) === firstName.charAt(0).toUpperCase() && lastName.charAt(0) === lastName.charAt(0).toUpperCase()) && (firstNameHasNumber !== true && lastNameHasNumber !== true) && (firstNameSpecialCharacter !== true && lastNameSpecialCharacter !== true)) {
      return (firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase())

    } else if (firstName.charAt(0) === firstName.charAt(0).toLowerCase() && lastName.charAt(0) === lastName.charAt(0).toLowerCase()) {
      return (firstName.charAt(0).toUpperCase());
    } else if (firstName.charAt(0) === firstName.charAt(0).toUpperCase() && lastName.charAt(0) === lastName.charAt(0).toLowerCase()) {
      return (firstName.charAt(0).toUpperCase());
    } else if (firstName.charAt(0) === firstName.charAt(0).toLowerCase() && lastName.charAt(0) === lastName.charAt(0).toUpperCase()) {
      return (lastName.charAt(0).toUpperCase());
    }
    else if ((firstName.charAt(0) === firstName.charAt(0).toUpperCase() || firstName.charAt(0) === firstName.charAt(0).toLowerCase()) && lastNameHasNumber === true) {
      return (firstName.charAt(0).toUpperCase());
    } else if ((lastName.charAt(0) === lastName.charAt(0).toUpperCase() || lastName.charAt(0) === lastName.charAt(0).toLowerCase()) && firstNameHasNumber === true) {
      return (lastName.charAt(0).toUpperCase());
    }
    else if ((firstName.charAt(0) === firstName.charAt(0).toUpperCase() || firstName.charAt(0) === firstName.charAt(0).toLowerCase()) && lastNameSpecialCharacter === true) {
      return (firstName.charAt(0).toUpperCase());
    } else if ((lastName.charAt(0) === lastName.charAt(0).toUpperCase() || lastName.charAt(0) === lastName.charAt(0).toLowerCase()) && firstNameSpecialCharacter === true) {
      return (firstName.charAt(0).toUpperCase());
    }
    else if (firstNameHasNumber === true && lastNameHasNumber === true) {
      return (firstName.charAt(0));
    } else if (firstNameSpecialCharacter === true && lastNameSpecialCharacter === true) {
      return (
        firstName.charAt(0));
    }
  },
  ShowAlert: function (title, body, footer, Isclose, onChange, isNewchat) {    
    const AlertBody = (body) => {
      return (
        <div>
          {body}
        </div>
      )
    }
    const AlertFooter = () => {
      return (
        <Fragment>
          <button className="btn btn-secondary vcgradientbtn vcsecondary-btn" onClick={() => AlertClose(Isclose)}>
            <div className="vcsecondaryInner">
              {footer}
            </div>
          </button>
        </Fragment >
      )
    }
    const AlertClose = () => {      
      if (Isclose) {
        if (onChange !== undefined && onChange !== null && onChange !== "") {
          if (!window.location.pathname.includes('setting')) {
            onChange(LeftControlNames.Employee);
          }
        }
        if (isNewchat !== undefined && isNewchat !== null && isNewchat !== "") {
          CommonMethods.OpenSelect2();
        }
      }
      unmountComponentAtNode(document.getElementById("OpenDialog"));
    };
    return ReactDom.render(
      <VGPopUp
        id={"Alert"}
        show={true}
        onHide={AlertClose}
        title={title}
        dialogClassName="vgn-modal-alert vgn-modal-small modal-mb-half"
        body={AlertBody(body)}
        footer={AlertFooter()}
        isRemoveTabModalIndex={false}
        isShowFooter={true}
        backdrop={true}
      />,
      document.getElementById("OpenDialog")
    );
  },
  SetHeight: function (fromPage) {
    setTimeout(() => {
      var divElement = $(".personal-info");
      var elemHeight = divElement.outerHeight(true) + 95;

      $(".bottom-content-wrap").css("height", window.innerHeight - elemHeight + 35);
      if (fromPage && fromPage === "fromEmployee") {
        $(".bottom-content-wrap").css("height", window.innerHeight - elemHeight - 35);
      } else {
        $(".bottom-content-wrap").css("height", window.innerHeight - elemHeight - 22);
      }
    }, 50);
  },
  SetMassageAreaWidth: function (fromPage) {
    var totalWidth = window.innerWidth;
    var leftsidedivElement = $(".leftside").outerWidth(true);
    var chatListBlock = $(".chatListBlock").outerWidth(true);
    var rightsidedivElement = $(".rightSlide").outerWidth(true);

    if (fromPage && leftsidedivElement && chatListBlock && rightsidedivElement) {
      //setTimeout(() => {
      leftsidedivElement = $(".leftside").outerWidth(true);
      chatListBlock = $(".chatListBlock").outerWidth(true);
      rightsidedivElement = $(".rightSlide").outerWidth(true);
      totalWidth = window.innerWidth - leftsidedivElement - chatListBlock - rightsidedivElement;
      $(".messagesArea").css("width", totalWidth);
      //}, 500);
    } else {
      $(".messagesArea").css("width", "auto");
    }
  },

  // SetGroupNameWidth: function () {
  //   var messagesArea = $(".messagesArea");
  //   var messagesAreaWidth = messagesArea.outerWidth(true);

  //   var messagesAreaHeaderAwatar = $(".messagesArea-header_avGroup .avatar");
  //   var messagesAreaHeaderAwatarWidth = messagesAreaHeaderAwatar.outerWidth(true);

  //   var messagesAreaHeaderBtnArea = $(".messagesArea-header_btnArea");
  //   var messagesAreaHeaderBtnAreaWidth = messagesAreaHeaderBtnArea.outerWidth(true);


  //   $(".messagesArea-header_name").css("width", messagesAreaWidth - (messagesAreaHeaderAwatarWidth - messagesAreaHeaderBtnAreaWidth));

  //   console.log('messagesAreaWidth ' + messagesAreaWidth);
  //   console.log('messagesAreaHeaderAwatarWidth ' + messagesAreaHeaderAwatarWidth);
  //   console.log('messagesAreaHeaderBtnAreaWidth ' + messagesAreaHeaderBtnAreaWidth);

  // },
  generateCustomMessageID: function () {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0;
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  },
  UnderDevelopment: () => {
    return ReactDOM.render(
      <Fragment>
        <SuccessAlert
          alertType={"warning"}
          strMessage={"Under development."}
          displaytime={2000}
        />
      </Fragment>,
      document.getElementById("OpenDialog1")
    );
  },
  WarningBanner: (massage = "") => {
    return ReactDOM.render(
      <Fragment>
        <SuccessAlert
          alertType={"warning"}
          strMessage={massage}
          displaytime={2000}
        />
      </Fragment>,
      document.getElementById("OpenDialog1")
    );
  },
  SuccessBanner: (massage = "") => {
    return ReactDOM.render(
      <Fragment>
        <SuccessAlert
          alertType={"success"}
          strMessage={massage}
          displaytime={2000}
        />
      </Fragment>,
      document.getElementById("OpenDialog1")
    );
  },
  FailBanner: (massage = "") => {
    return ReactDOM.render(
      <Fragment>
        <SuccessAlert
          alertType={"fail"}
          strMessage={massage}
          displaytime={2000}
        />
      </Fragment>,
      document.getElementById("OpenDialog1")
    );
  },
  setCountryDateFormat(data) {
    if (typeof data != "undefined" && data != undefined && data != null && data != "") {
      CountryDateFormatList = data;
    }
  },
  countryDateFormat(strDate, dateFormattype, countryID = 1) {
    try {
      if (strDate == 'undefined' || strDate == null || strDate == '' || strDate == '---' || strDate == '--') {
        return strDate;
      }

      if (countryID != 2) {
        countryID = 1;
      }

      if (CountryDateFormatList != null) {
        var format = CountryDateFormatList.find(function (item) {
          return item.countryId === parseInt(countryID) && item.dateFormatId === dateFormattype;
        });

        if (format != null && format != '' && format && format.dateFormat.trim() !== '') {
          if (moment(strDate).format(format.jsDateFormat) == "Invalid date") {
            return strDate;
          }
          else {
            return moment(strDate).format(format.jsDateFormat);
          }
        } else {
          return strDate.format('MMM D YYYY');
        }
      }
      else {
        return strDate.format('MMM D YYYY');
      }

    } catch (error) {
      return moment(strDate).format('MMM D YYYY');
    }
  },
  updateEvent: (eventName, newCallback, removePrevious = true) => {
    const listenerCount = Emitter.listenerCount(eventName);
    if (removePrevious && listenerCount > 0) {
      Emitter.removeAllListeners(eventName);
      //console.log(`Removed ${listenerCount} listener(s) from event "${eventName}".`);
    }
    Emitter.on(eventName, newCallback);
    //console.log(`Event "${eventName}" registered with new callback.`);
  },
  EventCall: (type, Data) => {
    const listenerCount = Emitter.listenerCount(type);
    if (listenerCount > 0) {
      Emitter.emit(type, Data);
    }
  },
  extractConnectVersion: () => {
    const regex = /\(VagaroPro (\d+\.\d+\.\d+)\)/;
    const match = navigator.userAgent.match(regex);
    return match ? match[1] : null;
  },
  compareVersions: (version1, version2) => {
    const v1 = version1.split('.').map(Number);
    const v2 = version2.split('.').map(Number);

    for (let i = 0; i < Math.max(v1.length, v2.length); i++) {
      const num1 = v1[i] || 0;
      const num2 = v2[i] || 0;

      if (num1 > num2) return 1;
      if (num1 < num2) return -1;
    }
    return 0;
  },
  getFirstName: (Name) => {
    const FristName = Name.trim();
    if (FristName.split(" ").length > 2) {
      return Name;
    } else {
      return FristName.split(" ")[0]
    }
  },
  getLastName: (Name) => {
    const LastName = Name.trim();
    if (LastName.split(" ").length == 2) {
      return LastName.split(" ")[1];
    } else {
      return "";
    }
  },

  GetDateFormatByCountry: (countryID, dateFormattype) => {
    let _dateFormat = 'MMM D YYYY'
    try {
      if (typeof countryID == 'undefined' || countryID == null || countryID == '') {
        countryID = GetBusinessCountryId();
      }

      if (countryID != 2) {
        countryID = 1;
      }

      // Get the date format based on country and format type
      var format = null;
      const _countryDateFormat = GetCountryDateFormat();

      if (_countryDateFormat && Array.isArray(_countryDateFormat)) {
        format = _countryDateFormat.find(function (item) {
          return item.countryId === parseInt(countryID) && item.dateFormatId === dateFormattype;
        });
      }
      // If format exists, format the date accordingly, else use default format
      if (format != null && format != '' && format && format.dateFormat.trim() !== '') {
        _dateFormat = format.jsDateFormat;
      }
      return _dateFormat
    } catch (error) {
      return _dateFormat; // Default format
    }
  },
  isValidTimeFormat: (time) => {
    const regex = /^(0?[1-9]|1[0-2]):[0-5][0-9] (AM|PM)$/i;
    return regex.test(time);
  },
  parseTimeForValidation: (time) => {
    if (!utils.isValidTimeFormat(time)) {
      return { hours: 0, minutes: 0 };
    }
    const [hours, minutes] = time.split(/[:\s]/).slice(0, 2);
    const period = time.split(' ')[1];
    let parsedHours = parseInt(hours, 10);
    let parsedMinutes = parseInt(minutes, 10);

    if (period === 'PM' && parsedHours !== 12) {
      parsedHours += 12;
    } else if (period === 'AM' && parsedHours === 12) {
      parsedHours = 0;
    }

    return {
      hours: parsedHours,
      minutes: parsedMinutes
    };
  },
  convertTo24HourFormatForValidation: (time12h) => {
    // Ensure the time string is correctly formatted
    const timePattern = /^(0?[1-9]|1[0-2]):[0-5][0-9]\s?(AM|PM)$/i;
    if (!timePattern.test(time12h)) {
      throw new Error("Invalid time format");
    }

    const [time, period] = time12h.split(' ');
    let [hours, minutes] = time.split(':');

    hours = parseInt(hours, 10);
    minutes = parseInt(minutes, 10);

    if (period.toUpperCase() === 'PM' && hours < 12) {
      hours += 12;
    } else if (period.toUpperCase() === 'AM' && hours === 12) {
      hours = 0;
    }

    return hours * 100 + minutes; // Convert to 24-hour time as a single number for easier comparison
  },
  checkIsAfterEndOfDay: (fromTime, toTime) => {
    if (!utils.isValidTimeFormat(fromTime) || !utils.isValidTimeFormat(toTime)) {
      return true; // If time format is invalid, consider it as exceeding the end of the day
    }

    const { hours: fromHours, minutes: fromMinutes } = utils.parseTimeForValidation(fromTime);
    const { hours: toHours, minutes: toMinutes } = utils.parseTimeForValidation(toTime);

    const fromTotalMinutes = fromHours * 60 + fromMinutes;
    const toTotalMinutes = toHours * 60 + toMinutes;

    // Consider the end of the day as 11:59 PM (1439 minutes)
    // Adjust logic to handle cases where the start time is 12:00 AM (0 minutes)
    if (fromTotalMinutes === 0) {
      return toTotalMinutes > 1439;
    }

    return toTotalMinutes <= fromTotalMinutes || toTotalMinutes > 1439;
  },
  GetCurrentUTCDay: (LowerCase = false) => {
    const currentTime = new Date();
    const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const dayName = dayNames[currentTime.getUTCDay()];
    if (LowerCase) {
      return dayName.toLocaleLowerCase()
    } else {
      return dayName
    }
  }


};

function ReactcheckMaxLength(actualstring, maxlength) {
  if (maxlength > 0 && maxlength < actualstring.length) {
    var actualstringarr = actualstring.split("");
    var returnstring = "";
    var k = 0;
    for (k = 0; k < maxlength; k++) {
      returnstring = returnstring + actualstringarr[k];
    }
    return returnstring;
  } else {
    return actualstring;
  }
}
function ReactIsNumeric(sText, entryMode) {
  var ValidChars;
  if (entryMode == 0) {
    ValidChars = "0123456789";
  } else if (entryMode == 1) {
    ValidChars = "0123456789.";
  } else if (entryMode == 2) {
    ValidChars = "0123456789 ";
  }
  var IsNumber = true;
  var Char;
  for (var i = 0; i < sText.length && IsNumber == true; i++) {
    Char = sText.charAt(i);
    if (ValidChars.indexOf(Char) == -1) {
      IsNumber = false;
    }
  }
  return IsNumber;
}
function ReactstripChars(string, entryMode) {
  var onlyNumbers = "";
  var len = string.length;
  var strarr = string.split("");
  var localDotCount = 0;
  var j = 0;
  for (j = 0; j < len; j++) {
    if (ReactIsNumeric(strarr[j], entryMode) == true) {
      if (entryMode == 1) {
        if (strarr[j] == "." && localDotCount == 0 && nDotCount != 1) {
          onlyNumbers = onlyNumbers + strarr[j];
          localDotCount = localDotCount + 1;
          nDotCount = 1;
        } else if (strarr[j] != ".") {
          onlyNumbers = onlyNumbers + strarr[j];
        }
      } else {
        onlyNumbers = onlyNumbers + strarr[j];
      }
    }
  }
  return onlyNumbers;
}
function ReactidentifyDot(stringToCheck) {
  var d = 0;
  var dotPresent = false;
  for (d = 0; d < stringToCheck.length; d++) {
    if (stringToCheck.charAt(d) == ".") {
      dotPresent = true;
    }
  }
  return dotPresent;
}
function ReactidentifyDotPosition(stringToCheck) {
  var d = 0;
  var dotPosition = 0;
  for (d = 0; d < stringToCheck.length; d++) {
    if (stringToCheck.charAt(d) == ".") {
      dotPosition = d;
    }
  }
  return dotPosition;
}
function reportWindowSize() {
  var pathName = document.location.pathname;
  if (pathName == null || pathName == undefined) {
    pathName = '';
  }
  if (pathName != '') {
    if (pathName.toLowerCase().indexOf('report/sales/combinedreport') == 1 || pathName.toLowerCase().indexOf('report/customers/mergeduplicatecustomers') == 1) {
      utils.setTableHeightCombineReport();
    }
    else {
      utils.setTableHeightReport();
      utils.setCompanyPaysHeight();
      utils.setSinglePayroll();
      utils.setTableHeightReportpayrollHistory();
      utils.setTexedAndDebited();

    }
  }
  utils.setTableHeightReportTra();
}

function reportWindowOrientationChange() {
  var pathName = document.location.pathname;
  if (pathName == null || pathName == undefined) {
    pathName = '';
  }
  if (pathName.toLowerCase().indexOf('report/sales/salessummary') == 1) {
    if (typeof $('.infopop') != "undefined" && $('.infopop') != null && $('.infopop').length > 0) {
      $('.infopop').removeClass().hide();
    }
  }
}

// const displayErrorMessage = (type, message) => {
//   console.log(message);
//   //alert(message);
//   return createPortal(
//     <Fragment>
//       <SuccessAlert
//         alertType={
//           type == ErrorType.Success
//             ? "success"
//             : type == ErrorType.Fail
//             ? "fail"
//             : "warning"
//         }
//         strMessage={message || "Some error Occured. Please try again."}
//         displaytime={2000}
//       />
//     </Fragment>,
//     document.getElementById("OpenDialog1")
//   );
// };
if (window.setMobileData === undefined) {
  window.setMobileData = function (sJSON) {
    var ipjsondata = {};
    ipjsondata = JSON.parse(sJSON);
    if (ipjsondata.hasOwnProperty('token_type')) {
      window.bankvui.frame.zenswipe.plaidResponse(sJSON);
    }
  };
}

function DateFormate(currentDate2, isFromConvertion = false, countryID = 1) {
  // const currentDate = moment(currentDate2).local(); // Convert UTC date to local time zone
  // const today = moment().startOf('day');
  // const startOfWeek = moment().startOf('week');
  // const startOfYear = moment().startOf('year');
  // if (currentDate.isSame(today, 'day')) {
  //   return moment(currentDate2).local().format("hh:mm A");
  // } else if (currentDate.isSameOrAfter(startOfWeek, 'day') && !currentDate.isSameOrAfter(startOfYear, 'year')) {
  //   moment(currentDate2).local().format('M/YY');
  // } else {
  //   return moment(currentDate2).local().format('M/D');
  // }
  const currentDate = moment(currentDate2).local(); // Convert UTC date to local time zone
  const today = moment().startOf('day');
  const yesterday = moment().subtract(1, 'day').startOf('day');
  const startOfWeek = moment().startOf('week');

  const formatDate = (date) => {
    return moment(date).format('dddd');
  }

  if (currentDate.isSame(today, 'day')) {
    return moment(currentDate2).local().format("h:mm A");
  } else if (currentDate.isSame(yesterday, 'day')) {
    return 'Yesterday';
  } else if (currentDate.isSameOrAfter(startOfWeek, 'day')) {
    return formatDate(currentDate2);
  } else {
    //return moment(currentDate2).format('MMM D, YYYY');
    return utils.countryDateFormat(currentDate2, DateFormatType.SmallDate, countryID)
  }
}


function DateFormateFomChat(getcurrentDate, isFromConvertion = false, CountryID = 1) {
  const givenDate = moment(getcurrentDate).local();
  const currentDate = moment();
  const currentWeekStart = moment().startOf('week');
  const currentWeekEnd = moment().endOf('week');

  const isToday = givenDate.isSame(currentDate, 'day');
  const isYesterday = givenDate.isSame(moment().subtract(1, 'day'), 'day');
  const isCurrentWeek = givenDate.isBetween(currentWeekStart, currentWeekEnd, 'day', '[]');

  const formatDate = (date) => {
    return moment(date).format('dddd');
  }

  const formatDate1 = (date) => {
    //this is check current year code
    // const currentYear = moment().year();
    // const givenYear = moment(date).year();
    // if (currentYear === givenYear) {
    //   return moment(date).format('MMMM D, YYYY');
    // } else {
    //   return moment(date).format('MMMM D, YYYY');
    // }
    //return moment(date).format('MMMM D, YYYY');
    return utils.countryDateFormat(date, DateFormatType.SmallDate, CountryID)
  }

  if (isToday) {
    if (!isFromConvertion) {
      return "Today";
    } else {
      return moment(getcurrentDate).format("hh:mm A");
    }
  }

  if (isYesterday) {
    return 'Yesterday';
  }

  if (isCurrentWeek) {
    return formatDate(getcurrentDate);
  }

  if (!isToday && !isYesterday && !isCurrentWeek) {
    return formatDate1(getcurrentDate, isFromConvertion);
  }
}

function containsHtml(inputStr) {
  const htmlPattern = /<[^>]+>/;
  return htmlPattern.test(inputStr);
}
// Function to extract text from HTML with <br> and &nbsp; as spaces
function extractText(htmlString) {
  if (containsHtml(htmlString)) {

    // Create a temporary div element
    var tempDiv = document.createElement('div');

    // Set the innerHTML of the div with the provided HTML string
    tempDiv.innerHTML = htmlString;

    // Replace <br> tags with newline characters
    tempDiv.innerHTML = tempDiv.innerHTML.replace(/<br\s*\/?>/g, ' ');

    // Replace &nbsp; with space
    tempDiv.innerHTML = tempDiv.innerHTML.replace(/&nbsp;/g, ' ');

    if (tempDiv.innerHTML && tempDiv.innerHTML.includes('vcCard-title') && tempDiv.innerHTML.includes('vcCard-title_name')) {
      return tempDiv.innerHTML;
    }
    var textContent = "";
    if (tempDiv.querySelector('img')) {
      const altContainsGif = /alt="(.*?Gif.*?)"/i;
      const containsGif = altContainsGif.test(tempDiv.innerHTML);
      if (containsGif) {
        textContent = "Sent a GIF";
      }
      else {
        textContent = "Sent an image";
      }
    }
    else {
      textContent = tempDiv.textContent || tempDiv.innerText;
    }

    // Remove leading and trailing whitespaces
    textContent = textContent.trim();

    // Return the extracted text
    return textContent;
  }
  else {
    return htmlString;
  }
}
function ConvertUtcToLocal(getDate) {
  return moment(getDate).local().format("h:mm A");
}

function isAndroidIOSTabIpad() {
  let isIpad = /Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 0;
  let isIpadold = /iPad/i.test(navigator.userAgent);
  let isTablet = /VagaroAndroidTablet/i.test(navigator.userAgent);
  if (isIpad || isIpadold || isTablet) {
    return true;
  }
  else {
    return false;
  }
}
function isIOSTabIpad() {
  let isIpad = /Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 0;
  let isIpadold = /iPad/i.test(navigator.userAgent);
  if (isIpad || isIpadold) {
    return true;
  }
  else {
    return false;
  }
}

function StoreLocalConversation(type, conversationId, employeeIDs, customerId, message, recent, loginUserIdHash, isForCustomer, selectedItem = [], attechments = []) {
  var existingData = JSON.parse(localStorage.getItem("CurrentConversation")) || [];
  var existingIndex;

  if (existingData.length > 0) {
    existingData.forEach(function (conversation) {
      conversation.Recent = false;
    });
  }

  switch (type) {
    case 1:
      existingIndex = existingData.findIndex(function (conversation) {
        return conversation.ConversationId === conversationId && conversation.LoginUserIdHash === loginUserIdHash;
      });
      break;
    case 2:
      existingIndex = existingData.findIndex(function (conversation) {
        return conversation.CustomerId === customerId && conversation.LoginUserIdHash === loginUserIdHash;
      });
      break;
    case 3:
      // existingIndex = existingData.findIndex(function (conversation) {
      //   if (conversation.Type === 3 && conversation.EmployeeIDs !== "") {
      //     return conversation.EmployeeIDs.some(function (employeeId) {
      //       return employeeIDs.includes(employeeId);
      //     });
      //   }
      // });
      existingIndex = existingData.map((val, index) => (val.Type === 3 && val.EmployeeIDs.length === employeeIDs.length) ? { val, index } : {}).filter(arr => arr.val && arr.val?.EmployeeIDs.find(inner => employeeIDs.includes(inner)))[0]?.index
      if (existingIndex === undefined) {
        existingIndex = -1;
      }
      break;
    default:
      existingIndex = -1;
      break;
  }

  if (type !== 0) {
    if (existingIndex !== -1) {

      existingData[existingIndex] = {
        "Type": type,
        "ConversationId": conversationId,
        "EmployeeIDs": employeeIDs,
        "CustomerId": customerId,
        "Message": message,
        "Recent": recent,
        "LoginUserIdHash": loginUserIdHash,
        "isForCustomer": isForCustomer,
        "selectedItem": selectedItem,
        "attechments": attechments
      };
    } else {

      existingData.push({
        "Type": type,
        "ConversationId": conversationId,
        "EmployeeIDs": employeeIDs,
        "CustomerId": customerId,
        "Message": message,
        "Recent": recent,
        "LoginUserIdHash": loginUserIdHash,
        "isForCustomer": isForCustomer,
        "selectedItem": selectedItem,
        "attechments": attechments
      });
    }
  }
  try {
    localStorage.setItem("CurrentConversation", JSON.stringify(existingData));
  } catch (e) {

  }

}

function getConversationData(type, id, loginUserIdHash) {
  var existingData = JSON.parse(localStorage.getItem("CurrentConversation")) || [];
  var conversation;


  switch (type) {
    case 1:
      conversation = existingData.find(function (conversation) {
        return conversation.ConversationId === id && conversation.LoginUserIdHash === loginUserIdHash && conversation.Type === 1;
      });
      break;
    case 2:
      conversation = existingData.find(function (conversation) {
        return conversation.CustomerId === id && conversation.LoginUserIdHash === loginUserIdHash && conversation.Type === 2;
      });
      break;
    case 3:
      conversation = existingData.filter(val => (val.Type === 3 && val.EmployeeIDs.length === id.length)).filter(val => val.EmployeeIDs.find(inner => id.includes(inner))).filter(val => val !== undefined)
      if (conversation.length > 0) {
        conversation = conversation[0];
      } else {
        conversation = null;
      }
      break;
    default:
      conversation = null;
      break;
  }

  return conversation || null;
}

function getRecentConversationData(loginUserIdHash) {
  var existingData = JSON.parse(localStorage.getItem("CurrentConversation")) || [];
  var conversation;

  conversation = existingData.find(function (conversation) {
    return conversation.Recent === true && conversation.LoginUserIdHash === loginUserIdHash;
  });

  return conversation || null;
}

function removeConversationItem(type, id, loginUserIdHash) {
  var existingData = JSON.parse(localStorage.getItem("CurrentConversation")) || [];
  var index;

  switch (type) {
    case 1:
      index = existingData.findIndex(function (conversation) {
        return conversation.ConversationId === id && conversation.LoginUserIdHash === loginUserIdHash;
      });
      break;
    case 2:
      index = existingData.findIndex(function (conversation) {
        return conversation.CustomerId === id && conversation.LoginUserIdHash === loginUserIdHash;
      });
      break;
    case 3:
      index = existingData.findIndex(function (conversation) {
        return conversation.EmployeeIDs.includes(id);
      });
      break;
    default:
      index = -1;
      break;
  }

  if (index !== -1) {
    existingData.splice(index, 1);
    localStorage.setItem("CurrentConversation", JSON.stringify(existingData));
  }
}

function removeConversationItemOnSend(loginUserIdHash) {
  var existingData = JSON.parse(localStorage.getItem("CurrentConversation")) || [];
  var index;

  index = existingData.findIndex(function (conversation) {
    return conversation.Recent == true && conversation.LoginUserIdHash === loginUserIdHash;
  });

  if (index !== -1) {
    existingData.splice(index, 1);
    localStorage.setItem("CurrentConversation", JSON.stringify(existingData));
  }
}

function GetBusinessCountryId() {
  let countryID = typeof window.GetBusinessCountry === "undefined" ||
    window.GetBusinessCountry() == null ||
    window.GetBusinessCountry() === ""
    ? 1
    : window.GetBusinessCountry();
  return countryID;
}
function GetCountryDateFormat() {
  const _countryDateFormat = typeof window._countryDateFormat === "undefined" ||
    window._countryDateFormat == null ||
    window._countryDateFormat === ""
    ? JSON.parse(process.env.REACT_APP_CountryDateFormat)
    : window._countryDateFormat;

  return _countryDateFormat;
}


window.openlinkinPro = function (event) {
  event.preventDefault();
  var url = event.currentTarget.attributes.href.value || '';
  var messageDateCall = '{"navType": -1,"screenType": 0 ,"screenTitle" : "" , "message": "" ,"messageType": 0,"action" : "6|~|' + url + '"}';
  utils.MobileDatePicker(messageDateCall);
}
window.onresize = reportWindowSize;
window.onorientationchange = reportWindowOrientationChange;

var popstateEvent = false;
const HandleHistory = (Action, path) => {
  const historyLength = window.history.length;
  if (Action === "push") {
    if (window.location.pathname !== `${window.location.pathname === "/" ? "" : window.location.pathname}/${path}` && window.location.pathname !== `/${path}` && !window.location.pathname.includes(path)) {
      if (window.location.pathname.includes("NewChat")) {
        window.history.pushState({ PrevPath: path, backFromButton: false, Index: historyLength }, "", `/${path}`)
      } else {
        window.history.pushState({ PrevPath: path, backFromButton: false, Index: historyLength }, "", `${window.location.pathname === "/" ? "" : window.location.pathname}/${path}`)
      }
      window.history.backFromButton = false;
      window.history.backFromHistory = false;
    }
  }
  if (Action === "back") {
    if ((window.history.backFromHistory === undefined || window.history.backFromHistory === false) && (window.history.state?.idx === undefined)) {
      window.history.backFromButton = true;
      setTimeout(() => {
        window.history.go(-1);
      }, 200);
    }
    window.history.backFromHistory = false;
  }
  if (!popstateEvent) {
    window.addEventListener('popstate', function (event) {
      console.log('History state changed', event);
      if (window.setMobileData) {
        if (window.history.backFromButton === undefined || window.history.backFromButton === false) {
          console.log("backToWen Click");
          window.history.backFromHistory = true;
          window.setMobileData("{\"backToWeb\":\"9\"}");
        }
        window.history.backFromButton = false;
        if (event.state?.idx !== undefined && !window.history?.pareventUpdate) {
          window.UpdateConversationFromNative();
        }

      }
    });
    popstateEvent = true;
  }

};

const compressImage = (ImageObject, src, Data) => {
  return new Promise((resolve, reject) => {
    const base64Size = (base64) => {
      let padding = 0;
      if (base64.endsWith('==')) padding = 2;
      else if (base64.endsWith('=')) padding = 1;
      return (base64.length * (3 / 4)) - padding;
    };

    const base64String = ImageObject.result.split(',')[1];
    const size = base64Size(base64String);
    if (size <= 2 * 1024 * 1024) {
      Data({ url: src, size: size });
      return resolve(src);
    }

    const img = new Image();
    img.src = src;
    img.onload = () => {
      const maxWidth = 1200;
      const maxHeight = 800;
      let width = img.width;
      let height = img.height;

      if (img.width > img.height) {
        width = maxWidth;
        height = maxHeight;
      } else {
        width = img.width;
        height = img.height;
      }

      if (width < img.width) {
        width = img.width;
      }
      if (height > img.height) {
        height = img.height;
      }

      const elem = document.createElement('canvas');
      let ratio = 0.2;

      if (img.width > width) {
        ratio = width / img.width;
      } else if (img.height > height) {
        ratio = height / img.height;
      }

      elem.height = img.height * ratio;
      elem.width = img.width * ratio;

      const ctx = elem.getContext('2d');
      ctx.imageSmoothingEnabled = true;
      ctx.drawImage(img, 0, 0, elem.width, elem.height);

      ctx.canvas.toBlob((blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64Size = (reader.result.length - 'data:image/jpeg;base64,'.length) * (3 / 4);
          const Base64Url = reader.result;
          const fileDetails = { url: Base64Url, size: base64Size };
          Data(fileDetails);
          resolve(reader.result);
        };
      }, 'image/jpeg', 0.8);
    };

    img.onerror = (error) => {
      console.log(error);
    };
  });
};

function LinkWithTooltip({ children, tooltip, placement = "top", isFromConnectApp }) {
  if (!isFromConnectApp && isFromConnectApp !== undefined && isFromConnectApp !== null) {

    return (
      <OverlayTrigger
        overlay={<Tooltip>{tooltip}</Tooltip>}
        placement={placement}
        delayShow={300}
        delayHide={150}
      >
        <a href={false}>{children}</a>
      </OverlayTrigger>
    );
  }
  else {
    return (<a href={false}>{children}</a>)
  }
}

export { utils, ResponseCode, CallBackAction, ReportOption, firstCallTimer, secondCallTimer, sortTimeout, isandroidiospro, iosVersion, androidVersion, iosVersionForRman, androidVersionForRman, ErrorType, DateFormate, extractText, ConvertUtcToLocal, DateFormateFomChat, isAndroidIOSTabIpad, isIOSTabIpad, upsertChannelTimer, StoreLocalConversation, getConversationData, removeConversationItem, getRecentConversationData, removeConversationItemOnSend, HandleHistory, compressImage, DateFormatType, LinkWithTooltip }

