import React from 'react'
import SliderComponent from '../../../component/Common/SliderComponent';
import { useSelector } from 'react-redux';
import TextMarketing from '../../CommonComponent/TextMarketing';
import { StatusEnum } from '../../Binding/ChatBoatEnums';
import { LeftComponentWrap } from './Components';
import { useVagaroAIContext } from '../../Store/VagaroAIContext';

const LeftComponent = () => {
    const { TextMarketingEnable, Status } = useSelector((state) => state.TextMarketing);
    const { isFromConnectApp, isAndroidIOSMobile, ReactBusinessId } = useVagaroAIContext();
    if (isFromConnectApp === false) {
        return (
            <LeftComponentWrap>
                <SliderComponent UpgradeTextMarketing ReactBusinessId={ReactBusinessId} isStenciledForSMS={Status === StatusEnum.Idle} isAndroidIOSMobile={isAndroidIOSMobile} />
                <TextMarketing />
            </LeftComponentWrap>
        )
    } else {
        return <></>;
    }
}

export default LeftComponent